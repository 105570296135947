import React from "react";
import { Redirect } from 'react-router-dom';

// reactstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  CardBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  CardHeader
} from "reactstrap";

// core components
import MainNavbar from "components/Navbars/MainNavbar.js";

import CVCookieConsent from "../../components/Footers/Cookie-Consent";

//Auth
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux'
import { initSession } from '../../actions/session'

import Spinner from 'react-bootstrap/Spinner';

import PasswordStrengthBar from 'react-password-strength-bar';
import { getBaseUrl } from "functions/util";

const mapStateToProps = state => {
  return { session: state.session }
}

function mapDispatchToProps(dispatch) {
  return {
    initSession: session => dispatch(initSession(session))
  }
}


class SignIn extends React.Component {
  state = {
    username: '',
    password: '',
    isFormValidated: false,
    isChecked: false,
    isForgotPassword: false,
    isForgotPasswordCodeStage: false,
    isPasswordResetSuccess: false,
    processing: false,
    isAuthenticated: false
  }

  async componentDidMount() {
    document.title = "SignIn";
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    if (this.props.location.state && this.props.location.state.isAuthenticated === false) { //Force logout
      this.setState({ isAuthenticated: false }, () => {
        this.setState({ loading: false });
      });
    } else {
      if (localStorage.checkbox && localStorage.username !== "") {
        this.setState({
          isChecked: true,
          username: localStorage.username,
          password: localStorage.password,
          isFormValidated: true
        })
      }

      await Auth.currentAuthenticatedUser()
        .then(user => {
          document.tidioIdentify = {
						email: user.signInUserSession.idToken.payload.email
					};
          this.setState({ isAuthenticated: true })
        })
        .catch(ex => {
          this.setState({ isAuthenticated: false }, () => {
            this.setState({ loading: false });
          });
        });
    }
  }

  handleUsernameChange = event => {
    this.setState({
      username: event.target.value,
      isFormValidated: this.state.password.length > 0 && event.target.value.length > 0
    })
  }

  handlePasswordChange = event => {
    this.setState({
      password: event.target.value,
      isFormValidated: this.state.username.length > 0 && event.target.value.length > 0
    })
  }

  onChangeCheckbox = event => {
    this.setState({
      isChecked: event.target.checked
    })
  }

  forgotPassword = () => {
    this.setState({ isForgotPassword: true });
  }

  signInWithGoogle = () => {
    this.setState({ processing: true });

    Auth.federatedSignIn({ provider: "Google" }, async () => {
      await Auth.currentAuthenticatedUser()
        .then(user => {
          document.tidioIdentify = {
						email: user.signInUserSession.idToken.payload.email
          };
          
          const signInSession = user.signInUserSession;

          const session = {
            credentials: {
              accessToken: signInSession.accessToken.jwtToken,
              idToken: signInSession.idToken.jwtToken,
              refreshToken: signInSession.refreshToken.token
            },
            user: {
              userName: signInSession.idToken.payload['cognito:username'],
              email: signInSession.idToken.payload.email
            }
          }

          this.props.initSession(session);

          this.setState({ isAuthenticated: true });

          let authHeader = {
            'Authorization': 'Bearer ' + signInSession.accessToken.jwtToken
          };
          let baseUrl = getBaseUrl();

          fetch(baseUrl + '/signup', {
            headers: authHeader
          })
            .then(response => response.json())
            .then(data => {
              if (data.stage > 2) { //already signedup
                this.props.initSession(session);
                this.setState({ isAuthenticated: true })
              } else {
                this.props.history.replace("/register");
              }

            })
            .catch(error => console.error(error))

          return user;
        })
        .catch(ex => {
          this.setState({ isAuthenticated: false }, () => {
            this.setState({ loading: false });
          });
        });
    });
  }

  signIn = async () => {
    this.setState({ processing: true });
    const { username, password, isChecked } = this.state

    try {
      const user = await Auth.signIn(username, password);
      const signInSession = user.signInUserSession;

      const session = {
        credentials: {
          accessToken: signInSession.accessToken.jwtToken,
          idToken: signInSession.idToken.jwtToken,
          refreshToken: signInSession.refreshToken.token
        },
        user: {
          userName: signInSession.idToken.payload['cognito:username'],
          email: signInSession.idToken.payload.email
        }
      }

      let authHeader = {
        'Authorization': 'Bearer ' + signInSession.accessToken.jwtToken
      };
      let baseUrl = getBaseUrl();

      fetch(baseUrl + '/signup', {
        headers: authHeader
      })
        .then(response => response.json())
        .then(data => {
          if (data.stage > 2) { //already signedup
            this.props.initSession(session);
            this.setState({ isAuthenticated: true })

            if (isChecked) {
              localStorage.username = username
              localStorage.password = password
              localStorage.checkbox = isChecked
            }
          } else {
            this.props.history.replace("/register");
          }

        })
        .catch(error => console.error(error))


    } catch (error) {
      if (error.code === "UserNotConfirmedException") {

        this.props.history.push("/signup", { username: this.state.username });
      } else {
        this.setState({
          isLoginError: true,
          loginErrorMessage: error.message,
          processing: false
        })
      }

    }
  }

  handleNewEmailChange = event => {
    this.setState({
      username: event.target.value,
      isFormValidated: event.target.value.length > 0
    });
  }

  handleNewCodeChange = event => {
    this.setState({ code: event.target.value });
  }

  handleNewPasswordChange = event => {
    this.setState({ newPassword: event.target.value });
  }

  submitForgotPassword = async () => {
    Auth.forgotPassword(this.state.username)
      .then(data => console.log(data))
      .catch(err => console.log(err));

    this.setState({ isForgotPasswordCodeStage: true });
  }

  submitForgotPasswordConfirmation = async () => {
    Auth.forgotPasswordSubmit(this.state.username, this.state.code, this.state.newPassword)
      .then(data => {
        this.setState({
          isForgotPassword: false,
          isForgotPasswordCodeStage: false,
          isPasswordResetSuccess: true
        })
      })
      .catch(err => {
        //console.log(err)
      });
  }

  render() {
    const { username, password, isChecked } = this.state

    //console.log(this.props.session);

    if (this.state.isAuthenticated) {
      return (
        <Redirect
          to="/home"
        />
      )
    } else {
      return (
        <>
          <MainNavbar />
          <main ref="main">
            <section className="section section-hero section-shaped">
              <div className="shape shape-style-1 shape-default">
                <span className="span-150" />
                <span className="span-50" />
                <span className="span-50" />
                <span className="span-75" />
                <span className="span-100" />
                <span className="span-75" />
                <span className="span-50" />
                <span className="span-100" />
                <span className="span-50" />
                <span className="span-100" />
              </div>
              {this.state.processing &&
                <div>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spinner animation="grow" variant="primary" />
                    <Spinner animation="grow" variant="primary" />
                    <Spinner animation="grow" variant="primary" />
                  </div>
                  <span style={{ textAlign: "center", display: "grid", color: "white" }}>Logging you in...</span>
                </div>

              }
              {this.state.isForgotPassword && !this.state.processing &&
                <Container className="pt-lg-9">
                  <Row className="justify-content-center" style={{ marginTop: 25 }}>
                    <Col lg="5">
                      <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-5 py-lg-5">


                          <div className="text-center text-muted mb-4">
                            <small>Password reset</small>
                          </div>
                          {!this.state.isForgotPasswordCodeStage &&
                            <Form role="form">
                              {this.state.isLoginError &&
                                <div className="text-center text-red mb-2" id="forgot-errormsg">
                                  <small>{this.state.loginErrorMessage}</small>
                                </div>
                              }

                              <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="ni ni-email-83" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input placeholder="Email" type="email"
                                    value={username}
                                    onChange={this.handleNewEmailChange} />
                                </InputGroup>
                              </FormGroup>


                              <div className="text-center">
                                <Button
                                  className="my-4"
                                  color="primary"
                                  type="button"
                                  onClick={this.submitForgotPassword}
                                  disabled={!this.state.isFormValidated && !this.state.processing}
                                >
                                  Submit
                            </Button>
                              </div>
                            </Form>
                          }

                          {this.state.isForgotPasswordCodeStage &&
                            <Form role="form">
                              {this.state.isLoginError &&
                                <div className="text-center text-red mb-2" id="forgot-pc-errormsg">
                                  <small>{this.state.loginErrorMessage}</small>
                                </div>
                              }
                              <FormGroup>
                                <InputGroup className="input-group-alternative mb-3">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="ni ni-key-25" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    placeholder="XXXXXX"
                                    type="text"
                                    value={this.state.code}
                                    onChange={this.handleNewCodeChange}
                                  />
                                </InputGroup>
                              </FormGroup>

                              <FormGroup>
                                <InputGroup className="input-group-alternative">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="ni ni-lock-circle-open" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    placeholder="New Password"
                                    type="password"
                                    autoComplete="off"
                                    value={this.state.newPassword}
                                    onChange={this.handleNewPasswordChange}
                                  />
                                </InputGroup>
                              </FormGroup>
                              <PasswordStrengthBar password={this.state.newPassword} />


                              <div className="text-center">
                                <Button
                                  className="my-4"
                                  color="primary"
                                  type="button"
                                  onClick={this.submitForgotPasswordConfirmation}
                                >
                                  Submit
                            </Button>
                              </div>
                            </Form>
                          }
                        </CardBody>
                      </Card>

                    </Col>
                  </Row>
                </Container>
              }
              {!this.state.isForgotPassword && !this.state.processing &&
                <Container className="pt-lg-6">
                  <Row className="justify-content-center" style={{ marginTop: 25 }}>
                    <Col lg="5">
                      <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-5 py-lg-5">
                          <CardHeader className="pb-5">
                            <div className="text-muted text-center mb-3">
                              <small>Sign in with</small>
                            </div>
                            <div className="btn-wrapper text-center">

                              <Button
                                className="btn-neutral btn-icon"
                                color="default"
                                onClick={this.signInWithGoogle}
                              >
                                <span className="btn-inner--icon mr-1">
                                  <img
                                    alt="..."
                                    src={require("assets/img/icons/common/google.svg")}
                                  />
                                </span>
                                <span className="btn-inner--text">Google</span>
                              </Button>
                            </div>
                          </CardHeader>

                          <div className="text-center text-muted mb-4">
                            <small>OR with credentials</small>
                          </div>
                          <Form role="form">
                            {this.state.isLoginError &&
                              <div className="text-center text-red mb-2" id="errormsg">
                                <small>{this.state.loginErrorMessage}</small>
                              </div>
                            }
                            {this.state.isPasswordResetSuccess &&
                              <div className="text-center mb-2">
                                <small>Password reset succesful</small>
                              </div>
                            }
                            <FormGroup className="mb-3">
                              <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-email-83" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="Email" type="email" id="email"
                                  value={username}
                                  onChange={this.handleUsernameChange} />
                              </InputGroup>
                            </FormGroup>
                            <FormGroup>
                              <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-lock-circle-open" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  id="password"
                                  placeholder="Password"
                                  type="password"
                                  autoComplete="off"
                                  value={password}
                                  onChange={this.handlePasswordChange}
                                />
                              </InputGroup>
                            </FormGroup>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <input
                                className="custom-control-input"
                                id=" customCheckLogin"
                                type="checkbox"
                                checked={isChecked}
                                onChange={this.onChangeCheckbox}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor=" customCheckLogin"
                              >
                                <span>Remember me</span>
                              </label>
                            </div>
                            <div className="text-center">
                              <Button
                                id="signin"
                                className="my-4"
                                color="primary"
                                type="button"
                                onClick={this.signIn}
                                disabled={!this.state.isFormValidated && !this.state.processing}
                              >
                                Sign In
                              </Button>
                            </div>
                          </Form>
                        </CardBody>
                      </Card>
                      <Row className="mt-3">
                        <Col xs="6">
                          <a
                            id="forgot-passwd"
                            className="text-light"
                            href="#forgot"
                            onClick={this.forgotPassword}
                          >
                            <small>Forgot password?</small>
                          </a>
                        </Col>
                        <Col className="text-right" xs="6">
                          <a
                            id="new-account"
                            className="text-light"
                            href="/signup"
                          >
                            <small>Create new account</small>
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              }
            </section>
          </main>
          {/* <SimpleFooter /> */}
          <CVCookieConsent />
        </>
      );
    }

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
