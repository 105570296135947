import React from "react";
import { connect } from 'react-redux';
import MainNavbar from "components/Navbars/MainNavbar.js";
import CardsFooter from "../../components/Footers/CardsFooter";
import ImgWithFallback from "../../components/Others/ImgWithFallback";

import {
    Row,
    Col
} from "reactstrap";

import { initSession } from '../../actions/session';

const mapStateToProps = state => {
    return { session: state.session }
}

function mapDispatchToProps(dispatch) {
    return {
        initSession: session => dispatch(initSession(session))
    }
}

class TemplateList extends React.Component {

    render() {
        return (
            <>
                <MainNavbar />
                {/* <main className="profile" ref="main"> */}
                <section className="section section-hero-profile section-shaped">
                    {/* Circles background */}
                    <div className="shape shape-style-1 shape-default alpha-4">
                        <span className="span-150" />
                        <span className="span-50" />
                        <span className="span-50" />
                        <span className="span-75" />
                        <span className="span-100" />
                        <span className="span-75" />
                        <span className="span-50" />
                        <span className="span-100" />
                        <span className="span-50" />
                        <span className="span-100" />
                    </div>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="fill-white"
                                points="2560 0 2560 100 0 100"
                            />
                        </svg>
                    </div>
                    <div className="text-center mt-5">
                        <h3>
                            All Templates
                    </h3>

                    </div>
                </section>


                <section className="section">
                    {/* <label className="h4 text-success font-weight-bold mt-1 mb-4 pl-3" style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginTop: -50 }}>
                        <h3>All CV Templates</h3>
                    </label> */}



                    <Row className="justify-content-start align-items-center">
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <div className="templatediv">
                                <a className="templatelink" target="_blank" rel="noopener noreferrer" href="https://mycv.onlinecv.app/mycv/template7">
                                    <div className="templateimg" >
                                        <ImgWithFallback
                                            type="image/webp"
                                            src={require("assets/img/templates/template7.webp")}
                                            fallback={require("assets/img/templates/template7.png")}
                                            style={{ width: 250, height: 353 }}
                                            alt="CV Template"
                                        >
                                        </ImgWithFallback>
                                    </div>
                                    <div className="templateshadow">
                                        <div className="viewTemplateButton">View Template</div>
                                    </div>

                                </a>
                                <div className="justify-content-center align-items-center flex-column template-title-div">
                                    <span className="template-title m-1">Mercury</span>
                                    {/* <span className="template-desc m-1">This curriculum vitae (CV) template features a large image in the header that can be personalized to suit your field. It is a great medium for an online CV, becoming your first point of contact with an employer who looks your name up online.</span> */}
                                </div>

                            </div>

                        </Col>

                        <Col xs={12} sm={6} md={4} lg={3}>
                            <div className="templatediv">
                                <a className="templatelink" target="_blank" rel="noopener noreferrer" href="https://mycv.onlinecv.app/mycv/template2">
                                    <div className="templateimg" >
                                        <ImgWithFallback
                                            type="image/webp"
                                            src={require("assets/img/templates/template2.webp")}
                                            fallback={require("assets/img/templates/template2.png")}
                                            style={{ width: 250, height: 353 }}
                                            alt="CV Template"
                                        >
                                        </ImgWithFallback>
                                    </div>
                                    <div className="templateshadow">
                                        <div className="viewTemplateButton">View Template</div>
                                    </div>

                                </a>
                                <div className="justify-content-center align-items-center flex-column template-title-div">
                                    <span className="template-title m-1">Venus</span>
                                    {/* <span className="template-desc m-1">This curriculum vitae (CV) template features a large image in the header that can be personalized to suit your field. It is a great medium for an online CV, becoming your first point of contact with an employer who looks your name up online.</span> */}
                                </div>

                            </div>

                        </Col>

                        <Col xs={12} sm={6} md={4} lg={3}>
                            <div className="templatediv">
                                <a className="templatelink" target="_blank" rel="noopener noreferrer" href="https://mycv.onlinecv.app/mycv/template6">
                                    <div className="templateimg" >
                                        <ImgWithFallback
                                            type="image/webp"
                                            src={require("assets/img/templates/template6.webp")}
                                            fallback={require("assets/img/templates/template6.png")}
                                            style={{ width: 250, height: 353 }}
                                            alt="CV Template"
                                        >
                                        </ImgWithFallback>
                                    </div>
                                    <div className="templateshadow">
                                        <div className="viewTemplateButton">View Template</div>
                                    </div>

                                </a>
                                <div className="justify-content-center align-items-center flex-column template-title-div">
                                    <span className="template-title m-1">Earth</span>
                                    {/* <span className="template-desc m-1">This curriculum vitae (CV) template features a large image in the header that can be personalized to suit your field. It is a great medium for an online CV, becoming your first point of contact with an employer who looks your name up online.</span> */}
                                </div>

                            </div>

                        </Col>

                        <Col xs={12} sm={6} md={4} lg={3}>
                            <div className="templatediv">
                                <a className="templatelink" target="_blank" rel="noopener noreferrer" href="https://mycv.onlinecv.app/mycv/template4">
                                    <div className="templateimg" >
                                        <ImgWithFallback
                                            type="image/webp"
                                            src={require("assets/img/templates/template4.webp")}
                                            fallback={require("assets/img/templates/template4.png")}
                                            style={{ width: 250, height: 353 }}
                                            alt="CV Template"
                                        >
                                        </ImgWithFallback>
                                    </div>
                                    <div className="templateshadow">
                                        <div className="viewTemplateButton">View Template</div>
                                    </div>

                                </a>
                                <div className="justify-content-center align-items-center flex-column template-title-div">
                                    <span className="template-title m-1">Mars</span>
                                    {/* <span className="template-desc m-1">This curriculum vitae (CV) template features a large image in the header that can be personalized to suit your field. It is a great medium for an online CV, becoming your first point of contact with an employer who looks your name up online.</span> */}
                                </div>

                            </div>

                        </Col>

                        <Col xs={12} sm={6} md={4} lg={3}>
                            <div className="templatediv">
                                <a className="templatelink" target="_blank" rel="noopener noreferrer" href="https://mycv.onlinecv.app/mycv/template1">
                                    <div className="templateimg" >
                                        <ImgWithFallback
                                            type="image/webp"
                                            src={require("assets/img/templates/template1.webp")}
                                            fallback={require("assets/img/templates/template1.png")}
                                            style={{ width: 250, height: 353 }}
                                            alt="CV Template"
                                        >
                                        </ImgWithFallback>
                                    </div>
                                    <div className="templateshadow">
                                        <div className="viewTemplateButton">View Template</div>
                                    </div>

                                </a>
                                <div className="justify-content-center align-items-center flex-column template-title-div">
                                    <span className="template-title m-1">Jupiter</span>
                                    {/* <span className="template-desc m-1">This curriculum vitae (CV) template features a large image in the header that can be personalized to suit your field. It is a great medium for an online CV, becoming your first point of contact with an employer who looks your name up online.</span> */}
                                </div>

                            </div>

                        </Col>


                        <Col xs={12} sm={6} md={4} lg={3}>
                            <div className="templatediv">
                                <a className="templatelink" target="_blank" rel="noopener noreferrer" href="https://mycv.onlinecv.app/mycv/template5">
                                    <div className="templateimg" >
                                        <ImgWithFallback
                                            type="image/webp"
                                            src={require("assets/img/templates/template5.webp")}
                                            fallback={require("assets/img/templates/template5.png")}
                                            style={{ width: 250, height: 353 }}
                                            alt="CV Template"
                                        >
                                        </ImgWithFallback>
                                    </div>
                                    <div className="templateshadow">
                                        <div className="viewTemplateButton">View Template</div>
                                    </div>

                                </a>
                                <div className="justify-content-center align-items-center flex-column template-title-div">
                                    <span className="template-title m-1">Saturn</span>
                                    {/* <span className="template-desc m-1">This curriculum vitae (CV) template features a large image in the header that can be personalized to suit your field. It is a great medium for an online CV, becoming your first point of contact with an employer who looks your name up online.</span> */}
                                </div>

                            </div>

                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <div className="templatediv">
                                <a className="templatelink" target="_blank" rel="noopener noreferrer" href="https://mycv.onlinecv.app/mycv/template3">
                                    <div className="templateimg" >
                                        <ImgWithFallback
                                            type="image/webp"
                                            src={require("assets/img/templates/template3.webp")}
                                            fallback={require("assets/img/templates/template3.png")}
                                            style={{ width: 250, height: 353 }}
                                            alt="CV Template"
                                        >
                                        </ImgWithFallback>
                                    </div>
                                    <div className="templateshadow">
                                        <div className="viewTemplateButton">View Template</div>
                                    </div>

                                </a>
                                <div className="justify-content-center align-items-center flex-column template-title-div">
                                    <span className="template-title m-1">Uranus</span>
                                    {/* <span className="template-desc m-1">This curriculum vitae (CV) template features a large image in the header that can be personalized to suit your field. It is a great medium for an online CV, becoming your first point of contact with an employer who looks your name up online.</span> */}
                                </div>

                            </div>

                        </Col>




                    </Row>




                </section>

                <section>

                </section>

                <CardsFooter />





                {/* </main> */}
            </>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TemplateList);