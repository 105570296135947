import React from 'react'
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactQuill from 'react-quill';
import MultiLineText from 'components/Others/MultiLineText';
import {
    Row,
    Col,
    UncontrolledTooltip
} from "reactstrap";

class ProjectDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: '',
            body: '',
            posts: {},
            forms1: [],
            startDate: new Date(),
            endDate: new Date(),
            experiences: props.workExpList
        };
    }

    render() {
        ProjectDetails.modules = {
            toolbar: [
                [],
                [{ size: [] }],
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                [{ 'align': [] }]
            ]
        };

        ProjectDetails.formats = [
            'header', 'font', 'size',
            'bold', 'italic', 'underline',
            'list', 'bullet', 'indent',
            'align'
        ]

        let projectList = this.props.projectList;

        return (<div>
            {projectList.map((val, idx) => (

                <div key={val.indexW}>
                    <form className="border-check shadow">
                        {projectList[idx].isFieldErrors &&
                            <div className="alert alert-warning text-center" role="alert">
                                <MultiLineText text={projectList[idx].errorMessage}></MultiLineText>
                            </div>
                        }
                        {/* <p>{this.props.forms1} </p> */}
                        <Box justifyContent="center">
                            <Box borderColor="grey.500"   >
                                <Row lg="12">
                                    <Col>
                                        <div style={{ display: 'flex' }}>
                                            <label htmlFor='name' className="h6">Title*</label>
                                            <Button
                                                className="btn-tooltip"
                                                color="info1"
                                                id={("tooltip1" + projectList[idx].indexE).replace(".", "")}
                                                size="sm"
                                            >
                                                <div className="icon-shape rounded-circle" style={{ padding: 0 }}>
                                                    <i className="fa fa-info-circle" />
                                                </div>
                                            </Button>
                                            <UncontrolledTooltip
                                                delay={0}
                                                placement="top"
                                                target={("tooltip1" + projectList[idx].indexE).replace(".", "")}
                                                trigger="hover focus"
                                            >
                                                The Title/Summary of your internship or project
                                        </UncontrolledTooltip>
                                        </div>
                                        <input type="text"
                                            name="projectTitle"
                                            placeholder='Ex.: Shock absorber based power generation'
                                            className="form-control"
                                            onChange={(e) => { this.props.onProjectDetailsChange(e.target.name, e.target.value, idx) }}
                                            value={projectList[idx].projectTitle}
                                        />
                                    </Col>
                                </Row>

                                <div className="form-control1" style={{ marginBottom: 0 }}>
                                    <div style={{ display: 'flex' }}>
                                            <label htmlFor='name' className="h6">Description</label>
                                            <Button
                                                className="btn-tooltip"
                                                color="info1"
                                                id={("tooltip2" + projectList[idx].indexE).replace(".", "")}
                                                size="sm"
                                            >
                                                <div className="icon-shape rounded-circle" style={{ padding: 0 }}>
                                                    <i className="fa fa-info-circle" />
                                                </div>
                                            </Button>
                                            <UncontrolledTooltip
                                                delay={0}
                                                placement="top"
                                                target={("tooltip2" + projectList[idx].indexE).replace(".", "")}
                                                trigger="hover focus"
                                            >
                                                The detailed description of all the activities you were performing at this project or internship.
                                        </UncontrolledTooltip>
                                        </div>
                                    <div>
                                        <ReactQuill style={{ background: 'white' }}
                                            id='projectSummary'
                                            className='ql-toolbar ql-container ql-editorql-blank'
                                            modules={ProjectDetails.modules}
                                            formats={ProjectDetails.formats}
                                            placeholder="Write here..."
                                            onChange={(newValue) => { this.props.onProjectDescriptionChange(newValue, idx) }}
                                            initialValue={''}
                                            value={projectList[idx].projectDescription || ''}
                                            theme={"snow"}
                                        />
                                    </div>
                                </div>

                                <div style={{ height: '25px' }}>
                                </div>

                                <div className="actionbuttons" style={{ textAlign: "right" }}>
                                    {this.props.projectList.length !== 0 && <Button className="mr20" color="secondary" onClick={(() => this.props.delete(val))}
                                    ><DeleteIcon /></Button>}
                                </div>
                            </Box>
                        </Box>
                    </form>
                    <div className="padding-gap">
                    </div>
                </div>

            ))}
        </div>
        )
    }
}

export default ProjectDetails;
