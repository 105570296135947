
import React from "react";

// reactstrap components
import {
    Container
} from "reactstrap";

// core components
import MainNavbar from "../../../components/Navbars/MainNavbar";

// Cookie consent
import CVCookieConsent from "../../../components/Footers/Cookie-Consent";

class Cookie extends React.Component {
    componentDidMount() {
        document.title = "Cookie";
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }
    render() {
        return (
            <>
                <MainNavbar />
                <main className="profile" ref="main">
                    <section className="section section-hero-profile section-shaped" style={{overflow: "hidden"}}>
                        {/* Circles background */}
                        <div className="shape shape-style-1 shape-default alpha-4">
                            <span className="span-150" />
                            <span className="span-50" />
                            <span className="span-50" />
                            <span className="span-75" />
                            <span className="span-100" />
                            <span className="span-75" />
                            <span className="span-50" />
                            <span className="span-100" />
                            <span className="span-50" />
                            <span className="span-100" />
                        </div>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="fill-white"
                                    points="2560 0 2560 100 0 100"
                                />
                            </svg>
                        </div>
                        <div className="text-center mt-5">
                            <h1 style={{ textAlign: 'center' }}>Cookie Policy</h1>

                        </div>
                    </section>
                    <section className="section">
                        <Container>
                            <div className="mt-0">
                                <span style={{ display: 'block', overflow: 'auto', marginTop: 0 }}>


                                    <b>Last updated October 31, 2020</b><br /><br />


                                    <h3>1. Introduction</h3>
                                    <ul style={{ listStyle: 'none' }}>
                                        <li>
                                            A cookie is a small internet file containing an identifier (a mix of letters and numbers)
                                            that is processed by a web server to a web browser, where it is stored. The identifier is
                                            then returned to the server each time the browser requests or refreshes a page from the server.
                                        They serve as a memory tool and are able to recognise your online behaviour and remember your actions.<br /><br />
                                        1.1 Cookies may be either "persistent" cookies or "session" cookies:
                                    </li>
                                        <li>
                                            <ul>
                                                <li>
                                                    Persistent cookies will be stored by a web browser and will remain valid until its set expiry date,
                                                  unless deleted by the user before the expiry date. <br />
                                                  Persistent Cookies (also known as first-party cookies) work by tracking your online preferences.
                                                  When you visit a website for the first time, it is at its default setting.
                                                  But if you personalise the site to fit your preferences, persistent cookies will
                                                  remember and implement those preferences the next time you visit the site.
                                                  This is how computers remember and store your login information, language selections,
                                                  menu preferences, internal bookmarks.
                                            </li>
                                                <li>
                                                    A session cookie, on the other hand, will expire at the end of the user session,
                                                    when the web browser is closed. Session Cookies are temporary cookies that memorise your
                                                    online activities.  Since websites have no sense of memory, without these cookies,
                                                    your site browsing history would always be blank and with every click you would make,
                                                    the website would treat you as a completely new visitor.
                                            </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <br />
                                    <h3>2. Cookies that we use</h3>
                                    <ul style={{ listStyle: 'none' }}>
                                        <li>
                                            2.1 We use cookies for the following purposes:
                                    <ul style={{ listStyle: 'none' }}>
                                                <li>
                                                    (a) Analytics - we use cookies to help us to analyse the use and performance of our website
                                                    and services; and
                                            </li>
                                                <li>
                                                    (b) Security - we use cookies to ensure that our website has a Secure attribute so as to
                                                    prevent our website from active network attackers.
                                            </li>
                                                <li>
                                                    (c) Functionality – we use Strictly Necessary cookies to provide the basic functions of our website.
                                            </li>
                                            </ul>
                                        </li>
                                    </ul>


                                    <br />
                                    <h3>3. Cookies used by our service providers</h3>
                                    <ul style={{ listStyle: 'none' }}>
                                        <li>
                                            3.1 Our service providers use cookies and those cookies may be stored on your computer when you visit our website.
                                            </li>
                                        <li>
                                            3.2 We use Google Analytics to analyse the use of our website. Google Analytics gathers information
                                            about website use by means of cookies. The information gathered relating to our website is used
                                            to create reports about the use of our website.
                                            Google's privacy policy is available at: <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy</a>.
                                            </li>
                                    </ul>
                                    <br />
                                    <h3>4. Managing cookies</h3>
                                    <ul style={{ listStyle: 'none' }}>
                                        <li>
                                            4.1 At present, our site cannot facilitate the personalisation of Cookies due to the necessary
                                            and functional nature. If you use our website, you are assumed to have accepted the Cookies noted above.
                                    </li>
                                        <li>
                                            4.2 Blocking all cookies will have a negative impact upon the usability of many websites.
                                    </li>
                                        <li>
                                            4.3 If you block cookies, you will not be able to use all the features on our website.
                                    </li>
                                    </ul>
                                    <br />
                                    <h3>5. Our details</h3>
                                    <ul style={{ listStyle: 'none' }}>
                                        <li>
                                            5.1 This website is owned and operated by <b>Rocket Science Software LTD</b>
                                        </li>
                                        <li>
                                            5.2 We are registered in England and Wales under registration number <b>08861124</b>,
                                        and our registered office is at <b>5 Stephen Close, Orpington, England, BR6 9TZ</b>
                                        </li>
                                        <li>
                                            5.3 You can contact us by email - <a href="mailto:admin@onlinecv.app">admin@onlinecv.app</a>
                                        </li>
                                    </ul>
                                </span>
                            </div>
                        </Container>
                    </section>

                </main>
                <CVCookieConsent/>
            </>
        );
    }
}

export default Cookie;
