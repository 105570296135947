import React from 'react';
import { Container } from '@material-ui/core';
import { Field, Formik } from 'formik';
import Box from '@material-ui/core/Box';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import MultiLineText from 'components/Others/MultiLineText';
import * as Yup from 'yup';

import {
    Row,
    Col,
    Input,
    Button,
    UncontrolledTooltip
} from "reactstrap";

const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('First name is required'),
    lastName: Yup.string()
        .required('Last name is required'),
    phoneNumber: Yup.string()
        .required('Phone number is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
});

const defaultProps = {
    m: 0,
    borderBottom: 0,
    style: { width: '1200rem', height: '3rem', borderColor: 'silver' },
};

const PersonalDetails = (props) => {
    const { user } = props;

    return (
        <Formik
            validationSchema={SignupSchema}
            enableReinitialize={true}
            initialValues={{
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.emailAddress,
                phoneNumber: user.phoneNumber,
                //address: '',
                city: user.city,
                postcode: user.postcode,
                linkedin: user.linkedin,
                touched: {
                    firstName: false,
                    lastName: false,
                    email: true,
                    phoneNumber: false,
                    //address: false,
                    city: false,
                    postcode: false,
                    linkedin: false
                }
            }}
            onSubmit={() => { }}
            validate={values => {
                const errors = {};
                if (!values.email) {
                    errors.email = 'Email is required';
                } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                    errors.email = 'Invalid email address';
                }
                return errors;
            }}
        >

            {({ values, handleSubmit, handleChange, errors, touched }) => (
                <Container className="form-control-select">

                    <Row className="justify-content-center">
                        <div className="form-control-border shadow" style={{ width: '70%', backgroundColor: 'white' }}>
                            <Box display="flex" justifyContent="center">
                                <Box {...defaultProps} >
                                    <h5><PersonOutlineIcon /> Personal Details</h5>
                                </Box>
                            </Box>

                            <form className="formclass border-check shadow" onSubmit={handleSubmit}>
                                {props.isFieldErrors &&
                                    <div className="alert alert-warning text-center" role="alert">
                                        <MultiLineText text={props.errorMessage}></MultiLineText>
                                    </div>
                                }

                                <Row lg="12" style={{ marginBottom: 10 }}>
                                    <Col lg="6">
                                        <div style={{display: 'flex'}}>
                                        <label htmlFor='name' className="h6">Firstname*</label>
                                        <Button
                                            className="btn-tooltip"
                                            color="info1"
                                            id="tooltipp1"
                                            size="sm"
                                        >
                                            <div className="icon-shape rounded-circle" style={{ padding: 0 }}>
                                                <i className="fa fa-info-circle" />
                                            </div>
                                        </Button>
                                        <UncontrolledTooltip
                                            delay={0}
                                            placement="top"
                                            target="tooltipp1"
                                            trigger="hover focus"
                                        >
                                            Your official First name. Make sure it is same as in your official documents.
                                        </UncontrolledTooltip>
                                        </div>
                                        
                                        <Field type="text" name="firstName" id='fname' Component={Input} placeholder='Enter name' onChange={e => { handleChange(e); props.onChange(e); }} className={`form-control ${(touched.firstName) && errors.firstName ? 'is-invalid' : ''}`} />
                                        {
                                            (touched.firstName) && (errors.firstName) && <div className='invalid-feedback'>{errors.name}</div>
                                        }
                                    </Col>
                                    <Col lg="6">
                                        <div style={{display: 'flex'}}>
                                        <label htmlFor='name' className="h6">Lastname*</label>
                                        <Button
                                            className="btn-tooltip"
                                            color="info1"
                                            id="tooltipp2"
                                            size="sm"
                                        >
                                            <div className="icon-shape rounded-circle" style={{ padding: 0 }}>
                                                <i className="fa fa-info-circle" />
                                            </div>
                                        </Button>
                                        <UncontrolledTooltip
                                            delay={0}
                                            placement="top"
                                            target="tooltipp2"
                                            trigger="hover focus"
                                        >
                                            Your official Last name. Make sure it is same as in your official documents.
                                        </UncontrolledTooltip>
                                        </div>
                                        <Field type="text" name="lastName" id='lname' placeholder='Enter Last name' Component={Input} onChange={e => { handleChange(e); props.onChange(e); }} className={`form-control ${(touched.lastName) && errors.lastName ? 'is-invalid' : ''}`} />
                                        {
                                            (touched.lastName) && (errors.lastName) && <div><span className="errors">{errors.lastName}</span></div>
                                        }
                                    </Col>
                                </Row>

                                <Row lg="12" style={{ marginBottom: 10 }}>
                                    <Col lg="6">
                                        
                                        <div style={{display: 'flex'}}>
                                        <label htmlFor='name' className="h6">Email address*</label>
                                        <Button
                                            className="btn-tooltip"
                                            color="info1"
                                            id="tooltipp3"
                                            size="sm"
                                        >
                                            <div className="icon-shape rounded-circle" style={{ padding: 0 }}>
                                                <i className="fa fa-info-circle" />
                                            </div>
                                        </Button>
                                        <UncontrolledTooltip
                                            delay={0}
                                            placement="top"
                                            target="tooltipp3"
                                            trigger="hover focus"
                                        >
                                            Your email address which we collected during your signup.
                                        </UncontrolledTooltip>
                                        </div>
                                        <Field type="text" name="email" id="email" disabled={true} placeholder='Enter email address' onChange={e => { handleChange(e); props.onChange(e); }} Component={Input} className={`form-control ${(touched.email) && errors.email ? 'is-invalid' : ''}`} />
                                        {
                                            (touched.email) && (errors.email) && <div><span className="errors">{errors.email}</span></div>
                                        }

                                    </Col>
                                    <Col lg="6">
                                        <div style={{display: 'flex'}}>
                                        <label htmlFor='name' className="h6">Phone number*</label>
                                        <Button
                                            className="btn-tooltip"
                                            color="info1"
                                            id="tooltipp4"
                                            size="sm"
                                        >
                                            <div className="icon-shape rounded-circle" style={{ padding: 0 }}>
                                                <i className="fa fa-info-circle" />
                                            </div>
                                        </Button>
                                        <UncontrolledTooltip
                                            delay={0}
                                            placement="top"
                                            target="tooltipp4"
                                            trigger="hover focus"
                                        >
                                            Your best contact number. This number will be shared with the recruiter or employer.
                                        </UncontrolledTooltip>
                                        </div>
                                        <Field type="number" name="phoneNumber" id="phonenumber" placeholder='Enter phonenumber' onChange={e => { handleChange(e); props.onChange(e); }} Component={Input} className={`form-control ${(touched.phoneNumber) && errors.phoneNumber ? 'is-invalid' : ''}`} />
                                        {
                                            (touched.phoneNumber) && (errors.phoneNumber) && <div><span className="errors">{errors.phoneNumber}</span></div>
                                        }
                                    </Col>
                                </Row>

                                <Row lg="12" style={{ marginBottom: 10 }}>
                                    <Col lg="12">
                                        <div style={{display: 'flex'}}>
                                        <label htmlFor='name' className="h6">City/Town</label>
                                        <Button
                                            className="btn-tooltip"
                                            color="info1"
                                            id="tooltipp5"
                                            size="sm"
                                        >
                                            <div className="icon-shape rounded-circle" style={{ padding: 0 }}>
                                                <i className="fa fa-info-circle" />
                                            </div>
                                        </Button>
                                        <UncontrolledTooltip
                                            delay={0}
                                            placement="top"
                                            target="tooltipp5"
                                            trigger="hover focus"
                                        >
                                            The city where you live. Even though it is not mandatory field, we highly recommend you to provide this information.
                                        </UncontrolledTooltip>
                                        </div>
                                        <Field type="text" name="city" id="city" placeholder='Enter city' Component={Input} onChange={e => { handleChange(e); props.onChange(e); }} />
                                    </Col>
                                </Row>

                                <Row lg="12" style={{ marginBottom: 10 }}>
                                    <Col lg="12">
                                        <div style={{display: 'flex'}}>
                                        <label htmlFor='name' className="h6">Linkedin profile</label>
                                        <Button
                                            className="btn-tooltip"
                                            color="info1"
                                            id="tooltipp6"
                                            size="sm"
                                        >
                                            <div className="icon-shape rounded-circle" style={{ padding: 0 }}>
                                                <i className="fa fa-info-circle" />
                                            </div>
                                        </Button>
                                        <UncontrolledTooltip
                                            delay={0}
                                            placement="top"
                                            target="tooltipp6"
                                            trigger="hover focus"
                                        >
                                            Linkedin.com is a professional network service. We highly recommend creating a profile in linkedin and share your profile url in your CV.
                                        </UncontrolledTooltip>
                                        </div>
                                        <Field type="text" name="linkedin" id="linkedin" placeholder='Linkedin profile url' Component={Input} onChange={e => { handleChange(e); props.onChange(e); }} />
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </Row>
                </Container>
            )
            }
        </Formik>
    )
    //}
};

export default PersonalDetails;