import React from "react";
import { Container, Row, Col } from "reactstrap";
import ImgWithFallback from "../components/Others/ImgWithFallback";

class HowItWorks extends React.Component {
    render() {
        return (
            <>
                <Container className="mt-5">
                    <h3 className="text-center d-block font-weight-bold mb-4">How it works?</h3>

                    <Row className="justify-content-center" >
                        <Col className="mt-5 mt-sm-0 text-center" sm="4" xs="12">
                            <p className="d-block font-weight-bold mb-4">
                                Signup
                            </p>
                            <ImgWithFallback
                                type="image/webp" 
                                src={require("assets/img/how/signup.webp")}
                                fallback={require("assets/img/how/signup.png")}
                                style={{ width: "30%", objectFit: "contain" }}
                                alt="hiw-step1"
                            >
                            </ImgWithFallback>
                            <div className="mt-2" style={{ marginLeft: "15%", marginRight: "15%" }}>
                                <span id="hiw-step1" style={{ color: "grey", fontSize: 14 }}>
                                    Signup &amp; submit your details using <b>Google</b> or <b>email</b>
                                </span>
                            </div>

                        </Col>
                        <Col className="mt-5 mt-sm-0 text-center" sm="4" xs="12">
                            <p className="d-block font-weight-bold mb-4">
                                Your CV
                            </p>
                            <ImgWithFallback
                                type="image/webp" 
                                src={require("assets/img/how/cv.webp")}
                                fallback={require("assets/img/how/cv.png")}
                                style={{ width: "30%", objectFit: "contain" }}
                                alt="hiw-step2"
                            >
                            </ImgWithFallback>
                            
                            <div className="mt-2" style={{ marginLeft: "15%", marginRight: "15%" }}>
                                <span id="hiw-step2" style={{ color: "grey", fontSize: 14 }}>
                                    Your CV is ready in minutes to share using QR code or a link or as a PDF file
                            </span>
                            </div>

                        </Col>
                        <Col className="mt-5 mt-sm-0 text-center" sm="4" xs="12">
                            <p className="d-block font-weight-bold mb-4">
                                Accomplish
                            </p>
                            <ImgWithFallback
                                type="image/webp" 
                                src={require("assets/img/how/accomplish.webp")}
                                fallback={require("assets/img/how/accomplish.png")}
                                style={{ width: "30%", objectFit: "contain" }}
                                alt="hiw-step3"
                            >
                            </ImgWithFallback>

                            <div className="mt-2" style={{ marginLeft: "5%", marginRight: "5%" }}>
                                <span id="hiw-step3" style={{ color: "grey", fontSize: 14 }}>
                                    Use the templates and your CV popularity metircs to drive your job search and land in your dream job
                            </span>
                            </div>

                        </Col>

                    </Row>
                </Container>
            </>
        );
    }
}

export default HowItWorks;
