import React from "react";
import { Container, Row, Col } from "reactstrap";
import ImgWithFallback from "../components/Others/ImgWithFallback";

class WhyUs extends React.Component {
    render() {
        return (
            <>
                <Container className="mt-5">
                    <h3 className="text-center d-block font-weight-bold mb-4">Why us?</h3>
                    <Row className="justify-content-center">
                        <Col className="mt-5 mt-sm-0 text-center" sm="4" xs="6">
                            <p className="d-block font-weight-bold mb-4">
                                Templates
                            </p>
                            <ImgWithFallback
                                type="image/webp" 
                                src={require("assets/img/why/templates.webp")}
                                fallback={require("assets/img/why/templates.png")}
                                style={{ width: "45px", objectFit: "contain" }}
                                alt="templates"
                            >
                            </ImgWithFallback>
                            <div className="mt-2" style={{ marginLeft: "15%", marginRight: "15%" }}>
                            <span style={{ color: "grey", fontSize: 15 }}>
                                We have CV templates for all job types and skill levels
                            </span>
                            </div>
                            
                        </Col>
                        <Col className="mt-5 mt-sm-0 text-center" sm="4" xs="6">
                            <p className="d-block font-weight-bold mb-4">
                                Share
                            </p>
                            <ImgWithFallback
                                type="image/webp" 
                                src={require("assets/img/why/share.webp")}
                                fallback={require("assets/img/why/share.png")}
                                style={{ width: "45px", objectFit: "contain" }}
                                alt="share"
                            >
                            </ImgWithFallback>
                            
                            <div className="mt-2" style={{ marginLeft: "15%", marginRight: "15%" }}>
                            <span style={{ color: "grey", fontSize: 15 }}>
                                Share your CV using a QR code or a link or a PDF file
                            </span>
                            </div>
                            
                        </Col>
                    </Row>
                    
                    <Row className="justify-content-center" style={{ marginTop: 75}}>
                        <Col className="mt-5 mt-sm-0 text-center" sm="4" xs="6">
                            <p className="d-block font-weight-bold mb-4">
                                Track
                            </p>
                            <ImgWithFallback
                                type="image/webp" 
                                src={require("assets/img/why/track.webp")}
                                fallback={require("assets/img/why/track.png")}
                                style={{ width: "45px", objectFit: "contain" }}
                                alt="track"
                            >
                            </ImgWithFallback>
                            
                            <div className="mt-2" style={{ marginLeft: "15%", marginRight: "15%" }}>
                            <span style={{ color: "grey", fontSize: 15 }}>
                                Dashboards are available to track your CV popularity
                            </span>
                            </div>
                            
                        </Col>
                        <Col className="mt-5 mt-sm-0 text-center" sm="4" xs="6">
                            <p className="d-block font-weight-bold mb-4">
                                Privacy
                            </p>
                            <ImgWithFallback
                                type="image/webp" 
                                src={require("assets/img/why/privacy.webp")}
                                fallback={require("assets/img/why/privacy.png")}
                                style={{ width: "45px", objectFit: "contain" }}
                                alt="privacy"
                            >
                            </ImgWithFallback>
                            
                            <div className="mt-2" style={{ marginLeft: "10%", marginRight: "10%" }}>
                            <span style={{ color: "grey", fontSize: 15 }}>
                                You are in control of your data and we do not share your information without your prior consent
                            </span>
                            </div>
                            
                        </Col>
                        <Col className="mt-5 mt-sm-0 text-center" sm="4" xs="6">
                            <p className="d-block font-weight-bold mb-4">
                                24/7
                            </p>
                            <ImgWithFallback
                                type="image/webp" 
                                src={require("assets/img/why/support247.webp")}
                                fallback={require("assets/img/why/support247.png")}
                                style={{ width: "45px", objectFit: "contain" }}
                                alt="support 247"
                            >
                            </ImgWithFallback>
                            
                            <div className="mt-2" style={{ marginLeft: "15%", marginRight: "15%" }}>
                            <span style={{ color: "grey", fontSize: 15 }}>
                                We are here to help you 24x7 either through chat or an email
                            </span>
                            </div>
                            
                        </Col>
                        
                    </Row>
                </Container>
            </>
        );
    }
}

export default WhyUs;
