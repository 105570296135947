import { CLEAR_SESSION, SET_SESSION } from '../constants/actionTypes'

export const clearSession = () => ({
  type: CLEAR_SESSION
})

// Initialise the Cognito sesson
export function initSession (session) {
  return function (dispatch) {
    dispatch({ type: SET_SESSION, session })
  }
}

export const setSession = session => ({
  type: SET_SESSION,
  session
})