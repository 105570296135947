
import React from "react";

// reactstrap components
import {
    Container
} from "reactstrap";

// core components
import MainNavbar from "../../../components/Navbars/MainNavbar";

// Cookie consent
import CVCookieConsent from "../../../components/Footers/Cookie-Consent";


class Privacy extends React.Component {
    componentDidMount() {
        document.title = "Privacy";
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }

    render() {
        return (
            <>
                <MainNavbar />
                <main className="profile" ref="main">
                    <section className="section section-hero-profile section-shaped" style={{overflow: "hidden"}}>
                        {/* Circles background */}
                        <div className="shape shape-style-1 shape-default alpha-4">
                            <span className="span-150" />
                            <span className="span-50" />
                            <span className="span-50" />
                            <span className="span-75" />
                            <span className="span-100" />
                            <span className="span-75" />
                            <span className="span-50" />
                            <span className="span-100" />
                            <span className="span-50" />
                            <span className="span-100" />
                        </div>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="fill-white"
                                    points="2560 0 2560 100 0 100"
                                />
                            </svg>
                        </div>
                        <div className="text-center mt-5">
                            <h1 style={{ textAlign: 'center' }}>Privacy Policy</h1>

                        </div>
                    </section>
                    <section className="section">
                        <Container>
                            <div className="mt-0">
                                <span style={{ display: 'block', overflow: 'auto' }}>

                                    <b>Last updated October 31, 2020</b><br /><br />


                                    We are committed to maintaining the highest standards of compliance with
                                    regulatory environments such as the General Data Protection Regulation (GDPR),
                                    Privacy and Electronic Communications Regulations (PECR) and the Data Protection
                                    Act 2018. This privacy policy explains how we process your personal data,
                                    so that you can make well-informed decisions regarding your Individual
                                    Rights (see more below).<br /><br />
                                    We may update this privacy policy from time to time to keep up to date with
                                    legislative or regulatory changes, and to ensure that it remains in-line with
                                    our business obligations. Any update or modification of the current version of
                                    this policy will be immediately applicable from the time we publish it.
                                    This policy is not contractual by nature and does not form a contract with us
                                    therein itself. By using our services, you are presumed to have read and
                                    understood this statement.

                                <br />
                                    <br />
                                    <h3>When does this Privacy Policy apply?</h3>
                                This policy applies to the personal information that we collect,
                                use or ask you to provide to help us manage our relationship with you,
                                either as a candidate or an employer/recruiter. When you access our CV service,
                                app or contact our team by email, we act as the Data Controller.
                                <br /><br />
                                    <h3>How do we collect your data?</h3>
                                Depending on your use of our service and your relationship with us, we collect your data from the following sources:
                                <ul>
                                        <li>
                                            When you sign up to use our services or application
                                    </li>
                                        <li>
                                            When you reach out to our team
                                    </li>
                                        <li>
                                            Third party sources, such as Automated Technologies, for cookie and analytical purposes
                                    </li>
                                    </ul>
                                    <br />
                                    <h3>Data that we collect</h3>
                                When you visit our website or use our OnlineCV service,
                                we may ask you to provide certain information so we can fulfil your request.
                                This information may include: <br /><br />
                                If you are a candidate:-
                                <ul>
                                        <li>
                                            <b>Contact Data</b> – full name, email address, phone number, current location of residence
                                    </li>
                                        <li>
                                            <b>Employment Data</b> – Job title, job role, previous employers, location of
                                        previous employment(s), commencement and end dates of employment and job
                                        descriptions, including responsibilities, skills and achievements.
                                    </li>
                                        <li>
                                            <b>Educational Data</b> – Institute attended, Title of course(s), degree of certification earned, location of institution, commencement and end dates of study.
                                    </li>
                                        <li>
                                            <b>CV/Resume Data</b> – skills and attributes, level of skill, membership of professional bodie(s), character summary (free text)
                                    </li>
                                        <li>
                                            Information you provide to us during interactions and to receive technical assistance from us
                                    </li>
                                        <li>
                                            <b>Performance Data</b> – information of the performance of our site and how you use it
                                    </li>
                                        <li>
                                            <b>System data</b> – Aggregated data or other information that does not identify individuals, for example information regarding our page views
                                    </li>
                                        <li>
                                            <b>Analytical Data</b> - Information collected from cookies and other tracking methods – please see our Cookie Policy for more information
                                    </li>
                                    </ul>
                                If you are a recruiter/employer:-<br />
                                    <ul>
                                        <li>
                                            <b>Company data</b> – name of company, company registration number, full business address including postcode, main business area
                                    </li>
                                        <li>
                                            <b>Contact data</b> – Name of company contact, their professional email address and their phone number
                                    </li>
                                    </ul>
                                    <br />
                                    <h3>How we use the data we collect</h3>
                                Depending on your relationship with us, we may use your data in the following ways:
                                <ul>
                                        <li>
                                            To provide you with your unique URL to your new CV
                                    </li>
                                        <li>
                                            To create or produce a CV based on the information you have chosen to share with us
                                    </li>
                                        <li>
                                            Manage the visibility preferences on your CV – private or visible to public
                                    </li>
                                        <li>
                                            Manage your website preferences
                                        </li>
                                        <li>
                                            Provide you with technical support
                                    </li>
                                        <li>
                                            Provide you with service messages about our products and services
                                    </li>
                                        <li>
                                            To fulfil legal and regulatory obligations
                                    </li>
                                    </ul>
                                    <br />
                                    <h3>What grounds do we process your data under?</h3>
                                Data Protection Laws forbid the processing of personal data unless we are collecting and using your data in line with one of 6 lawful bases under GDPR. The lawful bases we rely on are:
                                <ul>
                                        <li>
                                            <b>Consent:</b> When an individual has given clear consent for their personal data to be processed for a specific purpose. This consent must be well-informed and freely given.
                                    </li>
                                        <li>
                                            <b>Performance of a Contract:</b> The processing is necessary for a performance of a contract, or because they have been asked to take specific steps before entering into a contract.
                                    </li>
                                        <li>
                                            <b>Legal Obligation:</b> The processing is necessary for us to comply with the law (not including contractual obligations)
                                    </li>
                                        <li>
                                            <b>Vital Interests:</b> The processing is necessary to protect someone’s life.
                                    </li>
                                        <li>
                                            <b>Public Task:</b> The processing is necessary to perform a task in the public interest or your official functions, and the task or function has a clear basis in law.
                                    </li>
                                        <li>
                                            <b>Legitimate Interests:</b> The processing is necessary for legitimate interests or the legitimate interests of a third party, unless there is a good reason to protect the individual’s personal data which overrides those legitimate interests
                                    </li>
                                    </ul>
                                    <br />
                                    <b>If we are ever relying on the lawful basis of consent for specific processing,
                                    you will always be offered the opportunity to withdraw your consent at any time.
                                    You can do this by e-mailing us directly at <a href="mailto: admin@onlinecv.app">admin@onlinecv.app</a>.
                                    We aim to action your action as soon as reasonably practicable. </b>
                                    <br />
                                    <br />
                                    <h3>How long do we keep your personal data and where?</h3>
                                    <ul>
                                        <li>
                                        We store your personal data for the duration of your account,
                                as long as it is active. If and when you decide to delete your user account,
                                either via our app or by emailing our team, your personal detail as well
                                as any CVs you have created will be securely deleted. We retain backups of the
                                data for a period of up to 7 days after your account is closed.
                                        </li>
                                        <li>
                                        Your data is stored in our AWS (Amazon Web Services) which is an encrypted,
                                cloud-based server. As per required under GDPR, we have implemented appropriate 
                                technological and organisation measures to ensure the security of your data and prevent 
                                it from being accidentally lost, used or accessed in an unauthorised way, 
                                altered or disclosed.
                                        </li>
                                        <li>
                                            We ensure that our colleagues are subject to a duty of confidentiality, 
                                            and only the necessary individuals will be granted access, and only if we have 
                                            instructed them to process your data. We have company policies and procedures in 
                                            place which regulate personal data breach reporting internally and will always 
                                            notify you and the Information Commissioners Office where we are required to do so.
                                        </li>
                                    </ul>
                                 
                                If you wish to exercise your Right of Erasure,
                                please submit a deletion request to us at <a href="mailto: admin@onlinecv.app">admin@onlinecv.app</a>.<br /><br />
                                    <h3>Who do we share your data with?</h3>
                                    We never share your data with any third parties directly.<br /><br />

                                    <h3>Transfers of Data Outside of the European Economic Area (EEA)</h3>
                                    Whenever we transfer your personal data outside the EEA region 
                                    to certain authorised suppliers providing a service to us, we ensure a 
                                    similar degree of protection is afforded to it as it would be intra-EEA 
                                    by ensuring at least one of the following safeguards are present:
                                    <ul>
                                        <li>
                                            We will only transfer your personal data to countries that have been deemed to 
                                            provide an adequate level of protection for personal data by the European Commission
                                        </li>
                                        <li>
                                            Where we use certain service providers, we may use specific contracts approved by 
                                            the European Commission which give personal data the same protection as it has 
                                            in Europe called Standard Contractual Clauses (SCCs)
                                        </li>
                                        <li>
                                            Please note that following the Court of Justice of the European Union (“CJEU”) on 
                                            12 July 2020, Data Transfers between EU and US can no longer rely on the Privacy Shield 
                                            scheme as an appropriate safeguard.
                                        </li>
                                    </ul>
                                    <h3>Links to other sites</h3>
                                This Privacy Policy does not apply to any third party links made available to
                                you by us. Third parties will appoint their own privacy policies and these
                                should be consulted should you have any queries about how they process your data.
                                We have no control over such third party website and have no responsibility
                                for their actions.<br /><br />

                                    <h3>Your Individual Rights</h3>
                                Under GDPR, you are afforded Data Subject Rights which you may exercise at any time,
                                although not all are absolute right:-
                                <ul>
                                        <li>
                                            The right to erasure: you are afforded the right to have your personal data erased. This is also known as ‘the right to be forgotten’.
                                    </li>
                                        <li>
                                            The right to be informed: individuals have the right to be informed about the collection and use of their personal data.
                                    </li>
                                        <li>
                                            The right of access: Individuals have the right to access and receive a copy of their personal data. This is commonly known as a subject access request or ‘SAR’.
                                    </li>
                                        <li>
                                            The right to restrict processing: Individuals have the right to request the restriction or suppression of their personal data
                                    </li>
                                        <li>
                                            The right to data portability: Individuals have the right to data portability which allows individuals to obtain and reuse their personal data for their own purposes across different services.
                                    </li>
                                        <li>
                                            The right to object: GDPR gives individuals the right to object to the processing of their personal data in certain circumstances.
                                    </li>
                                        <li>
                                            Rights in relation to automated decision making and profiling: Individuals have the right to challenge automated individual decision-making (making a decision solely by automated means without any human involvement)
                                    </li>
                                    </ul>
                                    <br />
                                We work to the highest standards when it comes to processing your personal
                                data and helping you create your CV with us. If you have queries or concerns,
                                please contact us at <a href="mailto: admin@onlinecv.app">admin@onlinecv.app</a> and we’ll respond. <br /><br />

                                If you remain dissatisfied, you can make a complaint about the way we process
                                your personal information to the UK supervisory authority for data protection
                                (Information Commissioners Office) by visiting <a href="https://www.ico.org.uk" target="_blank" rel="noopener noreferrer">www.ico.org.uk</a>.

                            </span>
                            </div>
                        </Container>
                    </section>

                </main>
                <CVCookieConsent />

            </>
        );
    }
}

export default Privacy;
