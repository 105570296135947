
import React from "react";

// reactstrap components
import {
    Container
} from "reactstrap";

// core components
import MainNavbar from "../../../components/Navbars/MainNavbar";

// Cookie consent
import CVCookieConsent from "../../../components/Footers/Cookie-Consent";

class Terms extends React.Component {
    componentDidMount() {
        document.title = "Terms";
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }
    render() {
        return (
            <>
                <MainNavbar />
                <main className="profile" ref="main">
                    <section className="section section-hero-profile section-shaped" style={{overflow: "hidden"}}>
                        {/* Circles background */}
                        <div className="shape shape-style-1 shape-default alpha-4">
                            <span className="span-150" />
                            <span className="span-50" />
                            <span className="span-50" />
                            <span className="span-75" />
                            <span className="span-100" />
                            <span className="span-75" />
                            <span className="span-50" />
                            <span className="span-100" />
                            <span className="span-50" />
                            <span className="span-100" />
                        </div>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="fill-white"
                                    points="2560 0 2560 100 0 100"
                                />
                            </svg>
                        </div>
                        <div className="text-center mt-5">
                            <h1 style={{ textAlign: 'center' }}> Terms and Conditions</h1>

                        </div>
                    </section>
                    <section className="section">
                        <Container>
                            <div className="mt-0">
                                <span style={{ display: 'block', overflow: 'auto' }}>
                                    <b>Last updated October 31, 2020</b><br /><br />


                                        Welcome to OnlineCV. Each time you access or use OnlineCV and/or our
                                        mobile device application (collectively the "Website"), you are deemed to
                                        accept these terms and conditions. These terms, together with our 
                                        <a href="privacy"> privacy</a> and <a href="cookie">cookie</a> policies, 
                                        govern our relationship with you in relation to our services.
                                        If you disagree with any part of these terms and conditions,
                                        please do not use our website. <br /><br />
                                        The term ‘Rocket Science Software LTD’ or ‘OnlineCV’, ‘us’ or ‘we’ refers to the owner of 
                                        the website whose registered office is <b>5 Stephen Close, Orpington, England, BR6 9TZ</b>.
                                        Our company registration number is <b>08861124</b> registered in United Kingdom. 
                                        The term ‘you’ refers to the user of our website.<br /><br />

                                        The use of this website is subject to the following terms of use:

                                        <ul>
                                        <li>
                                            The content of the pages of this website is for the purposes of creating a free, accessible CV for your personal use only. It is subject to change without notice.
                                            </li>
                                        <li>
                                            This website uses cookies to monitor browsing preferences. If you do allow cookies to be used, the following personal information may be stored by us for use by third parties.
                                            </li>
                                        <li>
                                            You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
                                            </li>

                                        <li>
                                            Your use of any facilities on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements. You agree that the internet is not secure and OnlineCV cannot be responsible for any breaches as a result of an insecure connection.
                                            </li>
                                        <li>
                                        •	When you access our website or application, you will be required to set up a username and a secure password so we can create your account. You are responsible for keeping your username and password confidential and secure at all times. You should be the only individual who is able access your account at any time and we strongly advise that you do not disclose your username or password to any other persons. 
                                        </li>
                                        <li>
                                            You are responsible for ensuring that all information supplied by you is true, accurate, up-to-date and not misleading or likely to mislead or deceive and that it is not discriminatory, obscene, offensive, defamatory or otherwise illegal, unlawful or in breach of any applicable legislation, regulations, guidelines or codes of practice or the copyright, trademark or other intellectual property rights of any person in any jurisdiction. You are also responsible for ensuring that all information, data and files are free of viruses or other routines or engines that may damage or interfere with any system or data prior to being submitted to the Website. We reserve the right to remove any information supplied by you from the Website at our sole discretion, at any time and for any reason without being required to give any explanation.
                                    </li>

                                        <li>
                                            This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.
                                    </li>
                                        <li>
                                            All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are acknowledged on the website.
                                    </li>
                                        <li>
                                            Unauthorised use of this website may give rise to a claim for damages and/or be a criminal offence.
                                    </li>
                                        <li>
                                            From time to time, this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).
                                    </li>
                                        <li>
                                            We accept no liability for any loss (whether direct or indirect, for loss of business, revenue or profits, corruption or destruction of data or for any other indirect or consequential loss whatsoever) arising from your use of the Website and we hereby exclude any such liability, whether in contract, tort (including for negligence) or otherwise. We hereby exclude all representations, warranties and conditions relating to the Website and your use of it to the maximum extent permitted by law.
                                    </li>
                                        <li>
                                            Your use of this website and any dispute arising out of such use of the website is subject to the laws of England, Northern Ireland, Scotland and Wales.
                                    </li>

                                    </ul>
                                These terms are governed by UK Law and you submit to the non-exclusive jurisdiction of the UK courts.
                            </span>
                            </div>
                        </Container>
                    </section>

                </main>
                <CVCookieConsent/>
            </>
        );
    }
}

export default Terms;
