import React from 'react'
import { Container } from '@material-ui/core';
import { Formik } from 'formik';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/AddCircleRounded';
import WorkExperienceDetails from './WorkExperienceDetails';
import ProjectDetails from './ProjectDetails';
import EducationDetails from './EducationDetails';
import SkillsDetails from './SkillsDetails';
import CertificateDetails from './CertificateDetails';
//import AddBoxIcon from '@material-ui/icons/AddCircleOutline';
import AddWorkExpIcon from '@material-ui/icons/WorkOutline';
import AddStudiesIcon from '@material-ui/icons/CastForEducationTwoTone';
import AddSkillIcon from '@material-ui/icons/MouseOutlined';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcardOutlined';
import ProfileIcon from '@material-ui/icons/PersonOutline';
import Box from '@material-ui/core/Box';
import ReactQuill from 'react-quill';
import MultiLineText from 'components/Others/MultiLineText';

// reactstrap components
import {
    Row,
    UncontrolledTooltip
} from "reactstrap";

class WorkexperienceMain extends React.Component {
    render() {
        WorkexperienceMain.modules = {
            toolbar: [
                [],
                [{ size: [] }],
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [],
                [{ 'align': [] }]
            ]
        };

        WorkexperienceMain.formats = [
            'header', 'size',
            'bold', 'italic', 'underline',
            'list', 'bullet',
            'sub', 'super',
            'align'
        ]


        return (
            <Formik >
                <Container className="form-control-select" tag={'span'}>
                    <Row className="justify-content-center" tag={'span'}>
                        <div ref={this.window}></div>
                        <div className="form-control-border shadow" style={{ width: '70%', backgroundColor: '#f4f5f7' }}>
                            <Box display="flex">
                                <Box >
                                    <h5><ProfileIcon />  Profile</h5>
                                </Box>
                            </Box>

                            <div className="border-check-profile shadow">
                                <div className="form-control1 " >
                                    {this.props.isProfileFieldErrors &&
                                        <div className="alert alert-warning text-center" role="alert">
                                            <MultiLineText text={"Please provide a valid Profile Summary"}></MultiLineText>
                                        </div>
                                    }

                                    <div style={{ display: 'flex' }}>
                                        <label htmlFor='name' className="h6">Description</label>
                                        <Button
                                            className="btn-tooltip"
                                            color="info1"
                                            id="tooltipw1"
                                            size="sm"
                                        >
                                            <div className="icon-shape rounded-circle" style={{ padding: 0 }}>
                                                <i className="fa fa-info-circle" />
                                            </div>
                                        </Button>
                                        <UncontrolledTooltip
                                            delay={0}
                                            placement="top"
                                            target="tooltipw1"
                                            trigger="hover focus"
                                        >
                                            The personal summary is very important in your CV as it highlights who you are, where have you been and what do you want to become as well.
                                            Make sure the summary aligns with your ideal role or position.
                                            <span style={{ fontStyle: 'italic' }}><br /> For example, I am a hard working and motivated Software Engineer who has acquired extensive knowledge in the IT field through ...</span>
                                        </UncontrolledTooltip>
                                    </div>
                                    <div>
                                        <ReactQuill style={{ background: 'white' }}
                                            id='p-summary'
                                            className='ql-toolbar ql-container ql-editorql-blank'
                                            modules={WorkexperienceMain.modules}
                                            formats={WorkexperienceMain.formats}
                                            placeholder="Write here..."
                                            onChange={(newValue, delta, source) => { this.props.handleProfileSummaryChange(newValue, delta, source) }}
                                            value={this.props.summary}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="padding-gap">
                        </div>

                        <div className="form-control-border shadow" style={{ width: '70%', backgroundColor: '#f4f5f7' }}>
                            <Box display="flex">
                                <Box >

                                    <div style={{ display: 'flex' }}>
                                        <h5><AddWorkExpIcon />  Work Experience</h5>
                                        <Button
                                            className="btn-tooltip"
                                            color="info1"
                                            id="tooltipw2"
                                            size="sm"
                                        >
                                            <div className="icon-shape rounded-circle" style={{ padding: 0 }}>
                                                <i className="fa fa-info-circle" />
                                            </div>
                                        </Button>
                                        <UncontrolledTooltip
                                            delay={0}
                                            placement="top"
                                            target="tooltipw2"
                                            trigger="hover focus"
                                        >
                                            Add all your work experiences starting from the most recent first.
                                        </UncontrolledTooltip>
                                    </div>
                                </Box>
                            </Box>
                            <form >
                                <div >
                                    <div>
                                        <WorkExperienceDetails
                                            add={this.props.handleAddExperiences}
                                            delete={this.props.handleDeleteExperiences}
                                            workExpList={this.props.experiences}
                                            onJobDescriptionChange={this.props.handleJobDescriptionChange}
                                            onExpDetailsChange={this.props.handleExpDetailsChange}
                                        />
                                    </div>

                                    <div >


                                        <form className="text-center">
                                            <Button onClick={() => this.props.handleAddExperiences()}
                                                class="btn btn-primary btn-sm">
                                                <Add />
                                                {"  "}
                                                <span className="btn-inner--text">Add more</span>
                                            </Button>

                                        </form>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="padding-gap">
                        </div>

                        <div className="form-control-border shadow" style={{ width: '70%', backgroundColor: '#f4f5f7' }}>
                            <Box display="flex">
                                <Box >

                                    <div style={{ display: 'flex' }}>
                                        <h5><AddWorkExpIcon />  Internships/Projects</h5>
                                        <Button
                                            className="btn-tooltip"
                                            color="info1"
                                            id="tooltipw3"
                                            size="sm"
                                        >
                                            <div className="icon-shape rounded-circle" style={{ padding: 0 }}>
                                                <i className="fa fa-info-circle" />
                                            </div>
                                        </Button>
                                        <UncontrolledTooltip
                                            delay={0}
                                            placement="top"
                                            target="tooltipw3"
                                            trigger="hover focus"
                                        >
                                            Add all your Internships/Projects here. This is an important section for someone who is looking for the first job.
                                        </UncontrolledTooltip>
                                    </div>
                                </Box>
                            </Box>
                            <form >
                                <div >
                                    <div>
                                        <ProjectDetails
                                            add={this.props.handleAddProjects}
                                            delete={this.props.handleDeleteProjects}
                                            projectList={this.props.projects}
                                            onProjectDescriptionChange={this.props.handleProjectDescriptionChange}
                                            onProjectDetailsChange={this.props.handleProjectDetailsChange}
                                        />
                                    </div>

                                    <div >
                                        <form className="text-center">
                                            {/* {this.displayForm()} */}

                                            <Button onClick={() => this.props.handleAddProjects()}
                                                class="btn btn-primary btn-sm">
                                                <Add />
                                                {"  "}
                                                <span className="btn-inner--text">Add more</span>
                                            </Button>
                                        </form>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="padding-gap">
                        </div>

                        <div className="form-control-border shadow" style={{ width: '70%', backgroundColor: '#f4f5f7' }}>
                            {/*<div className="form-control1 lmargin" >*/}
                            <Box display="flex">
                                <Box >

                                    <div style={{ display: 'flex' }}>
                                        <h5><AddStudiesIcon />  Studies</h5>
                                        <Button
                                            className="btn-tooltip"
                                            color="info1"
                                            id="tooltipw4"
                                            size="sm"
                                        >
                                            <div className="icon-shape rounded-circle" style={{ padding: 0 }}>
                                                <i className="fa fa-info-circle" />
                                            </div>
                                        </Button>
                                        <UncontrolledTooltip
                                            delay={0}
                                            placement="top"
                                            target="tooltipw4"
                                            trigger="hover focus"
                                        >
                                            Add all your educations/studies here.
                                        </UncontrolledTooltip>
                                    </div>
                                </Box>
                            </Box>

                            <form >
                                <div >
                                    <div >
                                        <EducationDetails
                                            delete={this.props.handleDeleteEducations}
                                            eduDetailsList={this.props.educations}
                                            onEduDetailsChange={this.props.handleEducationDetailsChange}
                                        />
                                    </div>

                                    <div >
                                        <form className="text-center">
                                            {/* {this.displayForm()} */}

                                            <Button onClick={() => this.props.handleAddEducations()}
                                                class="btn btn-primary btn-sm">
                                                <Add />
                                                {"  "}
                                                <span className="btn-inner--text">Add more</span>
                                            </Button>
                                        </form>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="padding-gap">
                        </div>

                        <div className="form-control-border shadow" style={{ width: '70%', backgroundColor: '#f4f5f7' }} tag={'span'}>
                            <Box display="flex">
                                <Box >

                                    <div style={{ display: 'flex' }}>
                                        <h5><AddSkillIcon />  Skills</h5>
                                        <Button
                                            className="btn-tooltip"
                                            color="info1"
                                            id="tooltipw5"
                                            size="sm"
                                        >
                                            <div className="icon-shape rounded-circle" style={{ padding: 0 }}>
                                                <i className="fa fa-info-circle" />
                                            </div>
                                        </Button>
                                        <UncontrolledTooltip
                                            delay={0}
                                            placement="top"
                                            target="tooltipw5"
                                            trigger="hover focus"
                                        >
                                            Add all your skills(including soft) and languages including the levels of knowledge you acquired.
                                        </UncontrolledTooltip>
                                    </div>
                                </Box>
                            </Box>
                            <form className="border-check-skills shadow">
                                {this.props.skillErr &&
                                    <div className="alert alert-warning text-center" role="alert">
                                        <MultiLineText text={"Please provide a valid Skill Name"}></MultiLineText>
                                    </div>
                                }
                                <Box display="inline-block" justifyContent="center">
                                    <div className="">
                                        <table className="" style={{ width: '120%' }}>
                                            <thead>
                                                <tr>
                                                    <th className="h6"  >Skill</th>
                                                    <th className="h6" ></th>
                                                    <th className="h6" >Level</th>
                                                    <th className="h6" ></th>
                                                </tr>
                                            </thead>
                                            <tbody className="">
                                                <SkillsDetails
                                                    add={this.props.handleAddSkills}
                                                    delete={this.props.handleDeleteSkills}
                                                    skillList={this.props.skills}
                                                    onSkillDetailsChange={this.props.handleSkillDetailsChange}

                                                />
                                            </tbody>
                                            <tfoot>
                                                <tr><td colSpan="4">

                                                </td></tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </Box>
                            </form>
                        </div>

                        <div className="padding-gap">
                        </div>

                        <div className="form-control-border shadow" style={{ width: '70%', backgroundColor: '#f4f5f7' }} tag={'span'}>
                            <Box display="flex">
                                <Box >

                                    <div style={{ display: 'flex' }}>
                                        <h5><CardGiftcardIcon></CardGiftcardIcon> Awards &amp; Certifications</h5>
                                        <Button
                                            className="btn-tooltip"
                                            color="info1"
                                            id="tooltipw6"
                                            size="sm"
                                        >
                                            <div className="icon-shape rounded-circle" style={{ padding: 0 }}>
                                                <i className="fa fa-info-circle" />
                                            </div>
                                        </Button>
                                        <UncontrolledTooltip
                                            delay={0}
                                            placement="top"
                                            target="tooltipw6"
                                            trigger="hover focus"
                                        >
                                            Add all your awards and certifications.
                                        </UncontrolledTooltip>
                                    </div>
                                </Box>
                            </Box>
                            <form className="border-check-skills shadow">
                                {this.props.certErr &&
                                    <div className="alert alert-warning text-center" role="alert">
                                        <MultiLineText text={"Please provide a valid Certificate/Achievement"}></MultiLineText>
                                    </div>
                                }
                                <div>
                                    <table style={{ width: '140%' }}>
                                        <thead>
                                            <tr>
                                                <th className="h6" ></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <CertificateDetails
                                                addCertificate={this.props.handleAddCertificates}
                                                deleteCertificate={this.props.handleDeleteCertificates}
                                                certificateList={this.props.certificates}
                                                onCertificateDetailsChange={this.props.handleCertificatesDetailsChange}

                                            />
                                        </tbody>
                                        <tfoot>
                                            <tr><td colSpan="4">

                                            </td></tr>
                                        </tfoot>
                                    </table>
                                    <div className="padding-gap">
                                    </div>
                                    <div>
                                        <form className="text-center">

                                            <Button onClick={() => this.props.handleAddCertificates()}
                                                class="btn btn-primary btn-sm">
                                                <Add />
                                                {"  "}
                                                <span className="btn-inner--text">Add more</span>
                                            </Button>
                                        </form>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Row>
                </Container>
            </Formik>
        )

    }

    onHandleSubmit(e) {
        e.preventDefault();

        this.setState({
            title: '',
            body: ''
        });
    }
}

export default WorkexperienceMain;
