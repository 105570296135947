
import React from "react";
import { Redirect } from 'react-router-dom'

// reactstrap components
import classnames from "classnames";
import {
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container, Row, Col, Modal, Button
} from "reactstrap";

import Switch from "react-switch";

// core components
import MainNavbar from "components/Navbars/MainNavbar.js";
import CVCookieConsent from "../../../components/Footers/Cookie-Consent";

import { Auth } from 'aws-amplify';
import zxcvbn from 'zxcvbn';

import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';
import { initSession } from '../../../actions/session';
import CardsFooter from "../../../components/Footers/CardsFooter";
import { getBaseUrl } from "functions/util";
import PasswordStrengthBar from 'react-password-strength-bar';

const mapStateToProps = state => {
    return { session: state.session }
}

function mapDispatchToProps(dispatch) {
    return {
        initSession: session => dispatch(initSession(session))
    }
}

class Settings extends React.Component {
    state = {
        loading: true,
        checked: false,
        firstName: '',
        cvUrl: '',
        qrCode: '',
        isUserRegistrationCompleted: false,
        oldPassword: '',
        newPassword: '',
        newConfirmPassword: '',
        isFormValidated: false,
        passwordScore: 0,
        errorMessage: ''
    }

    componentDidMount() {
        document.title = "Settings";
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;

        if (this.props.session.isLoggedIn) {
            this.setState({ isAuthenticated: true });
            this.fetchCVData(this.props.session.credentials.accessToken);
        } else {
            Auth.currentAuthenticatedUser()
                .then(user => {
                    document.tidioIdentify = {
						email: user.signInUserSession.idToken.payload.email
					};
                    const signInSession = user.signInUserSession;

                    const session = {
                        credentials: {
                            accessToken: signInSession.accessToken.jwtToken,
                            idToken: signInSession.idToken.jwtToken,
                            refreshToken: signInSession.refreshToken.token
                        },
                        user: {
                            userName: signInSession.idToken.payload['cognito:username'],
                            email: signInSession.idToken.payload.email
                        }
                    }

                    this.props.initSession(session);

                    this.setState({ isAuthenticated: true, session: session });

                    this.fetchCVData(signInSession.accessToken.jwtToken);

                    return user;
                })
                .catch(ex => {
                    this.setState({ isAuthenticated: false }, () => {
                        this.setState({ loading: false });
                    });
                });
        }

    }

    fetchCVData = (accessToken) => {
        let baseUrl = getBaseUrl();

        Promise.all([
            fetch(baseUrl + '/cv', {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            }),
            fetch(baseUrl + '/signup', {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            })
        ]).then(responses => {
            return Promise.all(responses.map((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    this.setState({ isAuthenticated: false });
                    return response.json();
                }
            }));
        })
            .then(data => {
                //console.log(data);

                var customer = data[0];
                var stage = data[1];

                this.setState({
                    firstName: customer.firstName,
                    cvUrl: customer.url,
                    qrCode: customer.qrCode,
                    isPublic: customer.isPublic,
                    checked: customer.isPublic,
                    pickedTemplate: customer.templateId,
                    isUserRegistrationCompleted: stage.stage > 2,
                    loading: false
                });
            })
            .catch(function (error) {
                this.setState({
                    loading: false
                });
            });
    }

    handleChange = () => {
        let checked = !this.state.checked;
        let accessToken = this.state.session.credentials.accessToken;
        this.setState({ checked });

        if (checked) {
            this.createCV(accessToken);
        }
        let baseUrl = getBaseUrl();
        fetch(baseUrl + '/cv', {
            method: 'PUT',
            body: JSON.stringify({ isPublic: checked }),
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then(response => {
                if (response.status === 200) {
                    return response.text();
                } else {
                    this.setState({ isAuthenticated: false });
                }
            })
            .catch(error => console.error(error))
    }

    deleteMyAccount = () => {
        let baseUrl = getBaseUrl();
        let accessToken = this.state.session.credentials.accessToken;

        this.setState({ loading: true });

        fetch(baseUrl + '/cv', {
            method: 'PUT',
            body: JSON.stringify({ isPublic: false }),
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then(response => {
                if (response.status === 200) {
                    fetch(baseUrl + '/customer', {
                        method: 'DELETE',
                        headers: {
                            'Authorization': 'Bearer ' + accessToken
                        }
                    })
                        .then(async response => {
                            if (response.status === 200) {
                                try {
                                    await Auth.signOut({ global: true });
                                    this.props.history.replace("/");
                                } catch (error) {
                                    console.log('error signing out: ', error);
                                }
                            } else {
                                this.setState({ isAuthenticated: false });
                            }
                        })
                        .catch(error => console.error(error))
                } else {
                    this.setState({ isAuthenticated: false });
                }


            })
            .catch(error => console.error(error))
    }

    createCV = (accessToken) => {
        let baseUrl = getBaseUrl();
        fetch(baseUrl + '/cv', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + accessToken
            },
            body: JSON.stringify({ templateId: this.state.pickedTemplate })
        })
            .then(response => {
                if (response.status === 200) {
                    return response.text();
                } else {
                    this.setState({ isAuthenticated: false });
                }
            })
            .catch(error => console.error(error))
    }

    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };

    handleOldPasswordChange = event => {
        this.setState({
            oldPassword: event.target.value,
            isFormValidated: this.state.newPassword === this.state.newConfirmPassword
                && event.target.value.length > 6
                && this.state.newPassword.length > 0
                && this.state.newConfirmPassword.length > 0
                && this.state.passwordScore > 2
        });
    }

    handleNewPasswordChange = event => {
        var result = zxcvbn(event.target.value);

        this.setState({
            newPassword: event.target.value,
            passwordScore: result.score,
            isFormValidated: event.target.value === this.state.newConfirmPassword
                && this.state.oldPassword.length > 6
                && event.target.value.length > 0
                && this.state.newConfirmPassword.length > 0
                && result.score > 2
        });
    }

    handleNewConfirmPasswordChange = event => {
        this.setState({
            newConfirmPassword: event.target.value,
            isFormValidated: event.target.value === this.state.newPassword
                && event.target.value.length > 0
                && this.state.newPassword.length > 0
                && this.state.oldPassword.length > 6
                && this.state.passwordScore > 2
        });
    }

    handlePasswordChangeSubmit = event => {
        event.preventDefault();

        this.setState({ loading: true });

        Auth.currentAuthenticatedUser()
            .then(user => {
                document.tidioIdentify = {
                    email: user.signInUserSession.idToken.payload.email
                };
                return Auth.changePassword(user, this.state.oldPassword, this.state.newPassword);
            })
            .then(data => {
                this.setState({ loading: false });
                this.toggleModal("formModal");
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    loading: false,
                    errorMessage: err.message
                });
            });
    }

    render() {
        const { loading, isAuthenticated, isUserRegistrationCompleted } = this.state;

        if (loading) {
            return (
                <>
                    <MainNavbar />
                    <main className="profile" ref="main">
                        <section className="section section-hero-profile section-shaped">
                            {/* Circles background */}
                            <div className="shape shape-style-1 shape-default alpha-4">
                                <span className="span-150" />
                                <span className="span-50" />
                                <span className="span-50" />
                                <span className="span-75" />
                                <span className="span-100" />
                                <span className="span-75" />
                                <span className="span-50" />
                                <span className="span-100" />
                                <span className="span-50" />
                                <span className="span-100" />
                            </div>
                            {/* SVG separator */}
                            <div className="separator separator-bottom separator-skew">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    preserveAspectRatio="none"
                                    version="1.1"
                                    viewBox="0 0 2560 100"
                                    x="0"
                                    y="0"
                                >
                                    <polygon
                                        className="fill-white"
                                        points="2560 0 2560 100 0 100"
                                    />
                                </svg>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Spinner animation="grow" variant="primary" />
                                <Spinner animation="grow" variant="primary" />
                                <Spinner animation="grow" variant="primary" />
                            </div>
                            <span style={{ textAlign: "center", display: "grid", color: "white" }}>Checking your session...</span>

                        </section>
                    </main>
                </>
            );
        } else {
            if (isAuthenticated) {
                if (isUserRegistrationCompleted) {
                    return (
                        <>
                            <MainNavbar />
                            <main className="profile" ref="main">
                                <section className="section section-hero-profile section-shaped">
                                    {/* Circles background */}
                                    <div className="shape shape-style-1 shape-default alpha-4">
                                        <span className="span-150" />
                                        <span className="span-50" />
                                        <span className="span-50" />
                                        <span className="span-75" />
                                        <span className="span-100" />
                                        <span className="span-75" />
                                        <span className="span-50" />
                                        <span className="span-100" />
                                        <span className="span-50" />
                                        <span className="span-100" />
                                    </div>
                                    {/* SVG separator */}
                                    <div className="separator separator-bottom separator-skew">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            preserveAspectRatio="none"
                                            version="1.1"
                                            viewBox="0 0 2560 100"
                                            x="0"
                                            y="0"
                                        >
                                            <polygon
                                                className="fill-white"
                                                points="2560 0 2560 100 0 100"
                                            />
                                        </svg>
                                    </div>
                                    <div className="text-center mt-5">
                                        <h3>
                                            Hi, {this.state.firstName}
                                        </h3>

                                    </div>
                                </section>
                                {loading ?
                                    <div>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Spinner animation="grow" variant="primary" />
                                            <Spinner animation="grow" variant="primary" />
                                            <Spinner animation="grow" variant="primary" />
                                        </div>
                                        <span style={{ textAlign: "center", display: "grid", color: "white" }}>Please wait...</span>
                                    </div>
                                    :
                                    <section className="section">
                                        <label style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: -50 }}>
                                            <h5>Here's your settings</h5>

                                        </label>
                                        <Container>
                                            <div className="px-0">
                                                <div className="mt-0 py-5 text-center">
                                                    <Row className="justify-content-center">
                                                        <Col lg="6">

                                                            <label style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <span>Your CV is visible to all&nbsp;&nbsp;&nbsp;</span>
                                                                <Switch height={24} onChange={() => {
                                                                    if (this.state.checked) {
                                                                        this.toggleModal("removeMyPublicCV");
                                                                    } else {
                                                                        this.handleChange(true);
                                                                    }

                                                                }} checked={this.state.checked} />
                                                            </label>
                                                            <Modal
                                                                className="modal-dialog-centered"
                                                                isOpen={this.state.removeMyPublicCV}
                                                                toggle={() => this.toggleModal("removeMyPublicCV")}
                                                            >
                                                                <div className="modal-header">
                                                                    <h6 className="modal-title" id="modal-title-default">
                                                                        Are you sure?
                                                                </h6>
                                                                    <button
                                                                        aria-label="Close"
                                                                        className="close"
                                                                        data-dismiss="modal"
                                                                        type="button"
                                                                        onClick={() => this.toggleModal("removeMyPublicCV")}
                                                                    >
                                                                        <span aria-hidden={true}>×</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <p>
                                                                        This will remove your CV url and makes the QR code invalid.
                                                                </p>
                                                                    <p style={{ fontSize: "small" }}>
                                                                        You can recreate your CV by making it public again.
                                                                </p>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <Button
                                                                        className="ml-auto"
                                                                        color="link"
                                                                        data-dismiss="modal"
                                                                        type="button"
                                                                        onClick={() => this.toggleModal("removeMyPublicCV")}
                                                                    >
                                                                        No, Close
                                                                </Button>
                                                                    <Button color="primary" type="button"
                                                                        onClick={() => {
                                                                            this.toggleModal("removeMyPublicCV");
                                                                            this.handleChange();
                                                                        }}>
                                                                        Yes, I am
                                                                </Button>

                                                                </div>
                                                            </Modal>
                                                        </Col>
                                                    </Row>
                                                    <Row className="justify-content-center ">
                                                        <Col lg="12">
                                                            <div style={{ marginTop: 60 }}>
                                                                <span>Update My Password&nbsp;&nbsp;&nbsp;</span>
                                                                <Button type="button" color="primary" size="sm" onClick={() => {
                                                                    this.toggleModal("formModal");
                                                                }}>
                                                                    Change my password
                                                            </Button>
                                                            </div>

                                                            <Modal
                                                                className="modal-dialog-centered"
                                                                size="sm"
                                                                isOpen={this.state.formModal}
                                                                toggle={() => this.toggleModal("formModal")}
                                                            >
                                                                <div className="modal-body p-0">
                                                                    <Card className="bg-secondary shadow border-0">

                                                                        <CardBody className="px-lg-5 py-lg-5">
                                                                            <div className="text-center text-muted mb-4">
                                                                                <small>Change Password</small>
                                                                            </div>
                                                                            <span style={{ color: "red", fontSize: 10 }}>{this.state.errorMessage}</span>
                                                                            <Form role="form">
                                                                                <FormGroup
                                                                                    className={classnames({
                                                                                        focused: this.state.passwordFocused
                                                                                    })}
                                                                                >
                                                                                    <InputGroup className="input-group-alternative">
                                                                                        <InputGroupAddon addonType="prepend">
                                                                                            <InputGroupText>
                                                                                                <i className="ni ni-lock-circle-open" />
                                                                                            </InputGroupText>
                                                                                        </InputGroupAddon>
                                                                                        <Input
                                                                                            placeholder="Current Password"
                                                                                            type="password"
                                                                                            autoComplete="off"
                                                                                            value={this.state.oldPassword}
                                                                                            onChange={this.handleOldPasswordChange}
                                                                                        />
                                                                                    </InputGroup>
                                                                                </FormGroup>
                                                                                <FormGroup
                                                                                    className={classnames({
                                                                                        focused: this.state.passwordFocused
                                                                                    })}
                                                                                >
                                                                                    <InputGroup className="input-group-alternative">
                                                                                        <InputGroupAddon addonType="prepend">
                                                                                            <InputGroupText>
                                                                                                <i className="ni ni-lock-circle-open" />
                                                                                            </InputGroupText>
                                                                                        </InputGroupAddon>
                                                                                        <Input
                                                                                            placeholder="New Password"
                                                                                            type="password"
                                                                                            autoComplete="off"
                                                                                            value={this.state.newPassword}
                                                                                            onChange={this.handleNewPasswordChange}
                                                                                        />
                                                                                    </InputGroup>
                                                                                </FormGroup>
                                                                                <PasswordStrengthBar password={this.state.newPassword} />
                                                                                <FormGroup
                                                                                    className={classnames({
                                                                                        focused: this.state.passwordFocused
                                                                                    })}
                                                                                >
                                                                                    <InputGroup className="input-group-alternative">
                                                                                        <InputGroupAddon addonType="prepend">
                                                                                            <InputGroupText>
                                                                                                <i className="ni ni-lock-circle-open" />
                                                                                            </InputGroupText>
                                                                                        </InputGroupAddon>
                                                                                        <Input
                                                                                            placeholder="Confirm Password"
                                                                                            type="password"
                                                                                            autoComplete="off"
                                                                                            value={this.state.newConfirmPassword}
                                                                                            onChange={this.handleNewConfirmPasswordChange}
                                                                                        />
                                                                                    </InputGroup>
                                                                                </FormGroup>

                                                                                <div className="text-center">
                                                                                    <Button className="my-4" color="primary"
                                                                                        type="button"
                                                                                        disabled={!this.state.isFormValidated}
                                                                                        onClick={this.handlePasswordChangeSubmit}
                                                                                    >
                                                                                        Update
                                                                                    </Button>
                                                                                </div>
                                                                            </Form>
                                                                        </CardBody>
                                                                    </Card>
                                                                </div>
                                                            </Modal>
                                                        </Col>
                                                    </Row>
                                                    <Row className="justify-content-center ">
                                                        <Col lg="12">
                                                            <div style={{ marginTop: 60 }}>
                                                                <span>Thinking of leaving us?&nbsp;&nbsp;&nbsp;</span>
                                                                <Button type="button" color="danger" size="sm" style={{ marginTop: 0 }} onClick={() => {
                                                                    this.toggleModal("removeMyAccount");
                                                                }}>
                                                                    Delete my account
                                                            </Button>
                                                            </div>

                                                            <Modal
                                                                className="modal-dialog-centered"
                                                                isOpen={this.state.removeMyAccount}
                                                                toggle={() => this.toggleModal("removeMyAccount")}
                                                            >
                                                                <div className="modal-header">
                                                                    <h6 className="modal-title" id="modal-title-default">
                                                                        Are you sure?
                                                                </h6>
                                                                    <button
                                                                        aria-label="Close"
                                                                        className="close"
                                                                        data-dismiss="modal"
                                                                        type="button"
                                                                        onClick={() => this.toggleModal("removeMyAccount")}
                                                                    >
                                                                        <span aria-hidden={true}>×</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <p>
                                                                        This will remove all your data from OnlineCV App and you cannot login any more.
                                                                </p>
                                                                    <p>
                                                                        This also removes your public URL and invalidates the QR Code.
                                                                </p>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <Button
                                                                        className="ml-auto"
                                                                        color="link"
                                                                        data-dismiss="modal"
                                                                        type="button"
                                                                        onClick={() => this.toggleModal("removeMyAccount")}
                                                                    >
                                                                        Give us a chance to fix?
                                                                </Button>
                                                                    <Button color="danger" type="button"
                                                                        onClick={() => {
                                                                            this.toggleModal("removeMyAccount");
                                                                            this.deleteMyAccount();
                                                                        }}>
                                                                        Yes, I am
                                                                </Button>

                                                                </div>
                                                            </Modal>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Container>
                                    </section>
                                }

                            </main>
                            <CVCookieConsent />
                            <CardsFooter />
                        </>
                    );
                } else {
                    return (
                        <Redirect
                            to="/register"
                        />
                    )
                }
            } else {
                return (
                    <Redirect
                        to={{
                            pathname: "/signin",
                            state: { isAuthenticated: false }
                        }}
                    />
                )
            }

        }

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
