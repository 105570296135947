
import React from "react";
import { Redirect } from 'react-router-dom';

// reactstrap components
import {
  Button,
  UncontrolledTooltip,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  CardHeader
} from "reactstrap";

// core components
import MainNavbar from "components/Navbars/MainNavbar.js";
import Spinner from 'react-bootstrap/Spinner';
import CVCookieConsent from "../../components/Footers/Cookie-Consent";

import zxcvbn from 'zxcvbn';
import PasswordStrengthBar from 'react-password-strength-bar';

//Auth
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux'
import { initSession } from '../../actions/session'

const mapStateToProps = state => {
  return { session: state.session }
}

function mapDispatchToProps(dispatch) {
  return {
    initSession: session => dispatch(initSession(session))
  }
}

class SignUp extends React.Component {
  state = {
    email: '',
    password: '',
    confirmpassword: '',
    passwordScore: 0,
    verificationCode: '',
    isFormValidationPassed: false,
    isChecked: false,
    newUser: null,
    isSignUpError: false,
    loading: false,
    isEmailVerificationSuccessful: false
  }

  componentDidMount() {
    document.title = "SignUp";
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    //this.refs.main.scrollTop = 0;

    const qp = new URLSearchParams(window.location.search);
    const code = qp.get('code');
    const username = qp.get('username');

    if (code && username) {
      this.setState({
        email: username,
        verificationCode: code
      }, () => {
        this.handleVerificationCodeSubmit();
      });
    } else {
      if (this.props.history.location.state && this.props.history.location.state.username) {
        this.setState({ newUser: this.props.history.location.state.username, email: this.props.history.location.state.username })
      }

      Auth.currentAuthenticatedUser()
        .then(user => {
          document.tidioIdentify = {
						email: user.signInUserSession.idToken.payload.email
          };
          
          const signInSession = user.signInUserSession;

          const session = {
            credentials: {
              accessToken: signInSession.accessToken.jwtToken,
              idToken: signInSession.idToken.jwtToken,
              refreshToken: signInSession.refreshToken.token
            },
            user: {
              userName: signInSession.idToken.payload['cognito:username'],
              email: signInSession.idToken.payload.email
            }
          }

          this.props.initSession(session);

          return user;
        })
        .catch(ex => {
          this.setState({ isAuthenticated: false }, () => {
            this.setState({ loading: false });
          });
        });
    }

  }

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
      isFormValidationPassed: !this.state.isChecked && this.state.email.length > 0 && this.state.password.length > 0 && this.state.confirmpassword.length > 0 && this.state.confirmpassword === this.state.password && this.state.passwordScore > 2
    })
  }

  handleEmailChange = (event) => {
    this.setState({
      email: event.target.value,
      isFormValidationPassed: this.state.isChecked && event.target.value.length > 0 && this.state.password.length > 0 && this.state.confirmpassword.length > 0 && this.state.confirmpassword === this.state.password && this.state.passwordScore > 2
    });
  }

  handlePasswordChange = (event) => {
    var result = zxcvbn(event.target.value);

    this.setState({
      password: event.target.value,
      passwordScore: result.score,
      isFormValidationPassed: this.state.isChecked && this.state.email.length > 0 && event.target.value.length > 0 && this.state.confirmpassword.length > 0 && this.state.confirmpassword === event.target.value && result.score > 2
    });
  }

  handleConfirmPasswordChange = (event) => {

    this.setState({
      confirmpassword: event.target.value,
      isFormValidationPassed: this.state.isChecked && this.state.email.length > 0 && this.state.password.length > 0 && event.target.value.length > 0 && this.state.password === event.target.value && this.state.passwordScore > 2
    });
  }

  handleVerificationCodeChange = (event) => {
    this.setState({
      verificationCode: event.target.value,
      isVerificationFormValidationPassed: event.target.value.length > 0
    });
  }

  handleVerificationCodeSubmit = async () => {
    //event.preventDefault();

    this.setState({ loading: true });
    try {
      await Auth.confirmSignUp(this.state.email, this.state.verificationCode);
      // const user = await Auth.signIn(this.state.email, this.state.password);
      // const signInSession = user.signInUserSession;

      // const session = {
      //   credentials: {
      //     accessToken: signInSession.accessToken.jwtToken,
      //     idToken: signInSession.idToken.jwtToken,
      //     refreshToken: signInSession.refreshToken.token
      //   },
      //   user: {
      //     userName: signInSession.idToken.payload['cognito:username'],
      //     email: signInSession.idToken.payload.email
      //   }
      // }

      // this.props.initSession(session);
      this.setState({ loading: false, isEmailVerificationSuccessful: true });
    } catch (e) {
      this.setState({
        isSignUpError: true,
        signUpErrorMessage: e.message,
        loading: false
      });
    }
  }

  handleGoogle = async (event) => {
    event.preventDefault();

    console.log("Inside Google");
    this.setState({ loading: true });

    try {
      Auth.federatedSignIn({ provider: "Google" }, async () => {
        const newUser = await Auth.signUp({
          username: this.state.email,
          password: this.state.password,
          attributes: {
            email: this.state.email,
            "custom:groupName": "cv-group"
          }
        });
        this.setState({
          newUser: newUser,
          loading: false
        })
      })
    } catch (e) {
      console.log(e);
      this.setState({
        isSignUpError: true,
        signUpErrorMessage: e.message,
        loading: false
      });
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ loading: true });

    try {
      const newUser = await Auth.signUp({
        username: this.state.email,
        password: this.state.password,
        attributes: {
          email: this.state.email,
          "custom:groupName": "cv-group"
        }
      });
      this.setState({
        newUser: newUser,
        loading: false
      })
    } catch (e) {
      this.setState({
        isSignUpError: true,
        signUpErrorMessage: e.message,
        loading: false
      });
    }
  }

  renderSignUpForm() {
    return (
      <Form role="form">

        {this.state.isSignUpError &&
          <div className="text-center text-red mb-2">
            <small>{this.state.signUpErrorMessage}</small>
          </div>
        }
        <FormGroup>
          <InputGroup className="input-group-alternative mb-3">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-email-83" />
              </InputGroupText>
            </InputGroupAddon>
            <Input placeholder="Email" type="email"
              value={this.state.email}
              onChange={this.handleEmailChange}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-lock-circle-open" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Password"
              type="password"
              autoComplete="off"
              value={this.state.password}
              onChange={this.handlePasswordChange}
            />

          </InputGroup>
          <PasswordStrengthBar password={this.state.password} scoreWordStyle={{ fontSize: 10, display: "inline-block", float: "right" }} />
          {this.state.passwordScore < 3 &&
            <span style={{ fontSize: 10, display: "inline-block", color: "red" }}>Your password must be of length 9 including 1 number, 1 uppercase letter and 1 special character</span>
          }
        </FormGroup>

        <FormGroup >
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-lock-circle-open" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Confirm Password"
              type="password"
              autoComplete="off"
              value={this.state.confirmpassword}
              onChange={this.handleConfirmPasswordChange}
            />
          </InputGroup>
          {this.state.password !== this.state.confirmpassword &&
            <span style={{ fontSize: 10, display: "inline-block", color: "red", float: "right" }}>password mismatch<br /></span>
          }
        </FormGroup>


        <Row className="my-4">
          <Col xs="12">
            <div className="custom-control custom-control-alternative custom-checkbox">
              <input
                className="custom-control-input"
                id="customCheckRegister"
                type="checkbox"
                checked={this.state.isChecked}
                onChange={this.toggleChange}
              />
              <label
                className="custom-control-label"
                htmlFor="customCheckRegister"
              >
                <span>
                  I agree with the{" "}
                  <a
                    href="/privacy"
                    id="privacy"
                  >
                    Privacy Policy
                                  </a>
                                  &nbsp;&amp;&nbsp;
                    <a
                    href="/terms"
                    id="terms"
                  >
                    Terms and conditions
                                  </a>
                </span>
              </label>
            </div>
          </Col>
        </Row>
        <div className="text-center">
          <Button
            className="mt-4"
            color="primary"
            type="button"
            disabled={!this.state.isFormValidationPassed}
            onClick={this.handleSubmit}
          >
            Create account
                          </Button>
        </div>
        <div className="text-center mt-4">
          <a

            href="/signin"
          >
            <small>Already have an account?</small>
          </a>
        </div>
      </Form>
    );
  }

  renderEmailVerificationForm() {
    return (
      <Form role="form">
        <div className="text-center text-blue mb-2">
          <medium>We have sent you an email with a verification link</medium>
          <p></p>
          <small style={{ color: "darkred" }}>check the spam folder as well!</small>
        </div>
        {/* <FormGroup>
          <InputGroup className="input-group-alternative mb-3">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-key-25" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="XXXXXX"
              type="text"
              value={this.state.verificationCode}
              onChange={this.handleVerificationCodeChange}
            />
          </InputGroup>
        </FormGroup> */}
        {/* <div className="text-center">
          <Button
            className="mt-4"
            color="primary"
            type="button"
            disabled={!this.state.isVerificationFormValidationPassed}
            onClick={this.handleVerificationCodeSubmit}
          >
            Verify
                          </Button>
        </div> */}
      </Form>
    );
  }

  renderVerificationSuccessfulForm() {
    return (
      <Form role="form">
        <div className="text-center text-blue mb-2">
          <medium>Your email verified successfully!</medium>
          <p></p>
          <small>Login to create your CV</small>
          <p></p>
          <Button
            href="/signin"
            className="btn-neutral btn-icon"
            color="default"
            id="login"
          >
            <span className="btn-inner--icon">
              <i className="fa fa-sign-in mr-2" />
            </span>
            <span className="nav-link-inner--text ml-1">
              Login
                      </span>
          </Button>
        </div>
      </Form>
    )
  }

  render() {
    if (this.props.session.isLoggedIn) {
      return (
        <Redirect
          to="/register"
        />
      )
    } else {
      return (
        <>
          <MainNavbar />
          <main ref="main">
            <section className="section section-hero section-shaped">
              <div className="shape shape-style-1 shape-default">
                <span className="span-150" />
                <span className="span-50" />
                <span className="span-50" />
                <span className="span-75" />
                <span className="span-100" />
                <span className="span-75" />
                <span className="span-50" />
                <span className="span-100" />
                <span className="span-50" />
                <span className="span-100" />
              </div>
              {this.state.loading &&
                <div>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spinner animation="grow" variant="primary" />
                    <Spinner animation="grow" variant="primary" />
                    <Spinner animation="grow" variant="primary" />
                  </div>
                  <span style={{ textAlign: "center", display: "grid", color: "white" }}>Setting up your account...</span>
                </div>
              }

              {!this.state.loading &&
                <Container className="pt-lg-5">
                  <section className="section pt-lg-0 mt-0" style={{ paddingBottom: 0 }}>
                    <Container>
                      <Row className="justify-content-center">
                        <Col lg="12">
                          <Row className="row-grid justify-content-center">
                            <Col className="text-center">
                              <Button
                                className="btn-tooltip"
                                color="default1"
                                id="tooltip613577679"
                                size="sm"
                              >
                                <div className="icon icon-shape icon-shape-primary rounded-circle">
                                  <i className="ni ni-circle-08" />
                                </div>
                              </Button>
                              <UncontrolledTooltip
                                delay={0}
                                placement="bottom"
                                target="tooltip613577679"
                                trigger="hover focus"
                              >
                                Signup with OnlineCV using your GOOGLE account or email address below
                              </UncontrolledTooltip>
                            </Col>
                            <Col className="text-center">
                              <Button
                                className="btn-tooltip"
                                color="default1"
                                id="tooltip613577671"
                                size="sm"
                              >
                                <div className="icon icon-shape icon-shape-warning rounded-circle">
                                  <i className="fa fa-id-card-o" />
                                </div>
                              </Button>
                              <UncontrolledTooltip
                                delay={0}
                                placement="bottom"
                                target="tooltip613577671"
                                trigger="hover focus"
                              >
                                Submit your experience, education and skill details
                              </UncontrolledTooltip>
                            </Col>
                            <Col className="text-center">
                              <Button
                                className="btn-tooltip"
                                color="default1"
                                id="tooltip613577670"
                                size="sm"
                              >
                                <div className="icon icon-shape icon-shape-warning rounded-circle">
                                  <i className="fa fa-rocket" />
                                </div>
                              </Button>
                              <UncontrolledTooltip
                                delay={0}
                                placement="bottom"
                                target="tooltip613577670"
                                trigger="hover focus"
                              >
                                We create your CV in the template you have choosen and host in a URL which you can share
                              </UncontrolledTooltip>
                            </Col>
                          </Row>

                        </Col>
                      </Row>
                    </Container>
                  </section>
                  <Row className="justify-content-center" style={{ marginTop: 25 }}>
                    <Col lg="5">
                      <Card className="bg-secondary shadow border-0">
                        {this.state.newUser === null && !this.state.isEmailVerificationSuccessful &&
                          <CardHeader className="bg-white pb-5">
                            <div className="text-muted text-center mb-3">
                              <small>Sign up with</small>
                            </div>
                            <div className="text-center">
                              {/* <Button
                          className="btn-neutral btn-icon mr-4"
                          color="default"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <span className="btn-inner--icon mr-1">
                            <img
                              alt="..."
                              src={require("assets/img/icons/common/github.svg")}
                            />
                          </span>
                          <span className="btn-inner--text">Github</span>
                        </Button> */}
                              <Button
                                className="btn-neutral btn-icon ml-1"
                                color="default"

                                onClick={this.handleGoogle}
                              >
                                <span className="btn-inner--icon mr-1">
                                  <img
                                    alt="..."
                                    src={require("assets/img/icons/common/google.svg")}
                                  />
                                </span>
                                <span className="btn-inner--text">Google</span>
                              </Button>
                            </div>
                          </CardHeader>
                        }

                        <CardBody className="px-lg-5 py-lg-5">
                          <div className="text-center text-muted mb-4">
                            <small>Sign up with credentials</small>
                          </div>
                          {this.state.isEmailVerificationSuccessful ? this.renderVerificationSuccessfulForm()
                            : this.state.newUser === null ? this.renderSignUpForm() : this.renderEmailVerificationForm()}

                        </CardBody>

                      </Card>
                    </Col>
                  </Row>
                </Container>
              }
            </section>
          </main>
          <CVCookieConsent />
        </>
      );
    }

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
