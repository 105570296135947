import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({ type = 'checkbox', id, className,value, onChange }) => (
    <input type={type} id={id} className={className} value={value} onChange={onChange} />
);

Checkbox.propTypes = {
    value:PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
}

export default Checkbox;