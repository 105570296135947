
/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardImg,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

class CardsFooter extends React.Component {
  render() {
    return (
      <>
        <footer className="footer-dark mb-5">
          <section className="section section-hero-footer section-footer-shaped">
            <div className="shape shape-style-1 shape-default">
              <span className="span-150" />
              <span className="span-50" />
              <span className="span-50" />
              <span className="span-75" />
              <span className="span-100" />
              <span className="span-75" />
              <span className="span-50" />
              <span className="span-100" />
              <span className="span-50" />
              <span className="span-100" />
            </div>
            <Container>

              <Row className="align-items-center justify-content-md-between text-white">
                <Col className="text-lg-center btn-wrapper" lg="4">
                  {/* <div>Social</div> */}
                  <Button
                    className="btn-icon-only rounded-circle"
                    color="twitter"
                    href="https://www.linkedin.com/company/cvonline-app"
                    id="tooltip475038074"
                    target="_blank"
                  >
                    <span className="btn-inner--icon">
                      <i className="fa fa-linkedin" />
                    </span>
                  </Button>
                  <UncontrolledTooltip delay={0} target="tooltip475038074">
                    Follow us
                </UncontrolledTooltip>
                </Col>
                <Col className="text-lg-center btn-wrapper" lg="4">
                  <div>
                    <a
                      id="faq"
                      className="text-white"
                      href="/faq"
                    >
                      <small>FAQ</small>
                    </a>
                  </div>
                </Col>

                <Col className="text-lg-center btn-wrapper" lg="4">
                  {/* <div>Legal</div> */}
                  <div>
                    <a
                      id="tandc"
                      className="text-white"
                      href="/terms"
                    >
                      <small>Terms &amp; Conditions</small>
                    </a>
                  </div>
                  <div>
                    <a
                      id="privacy"
                      className="text-white"
                      href="/privacy"
                    >
                      <small>Privacy policy</small>
                    </a>
                  </div>

                  <div>
                    <a
                      id="cookie"
                      className="text-white"
                      href="/cookie"
                    >
                      <small>Cookie policy</small>
                    </a>
                  </div>
                </Col>

              </Row>

              <hr />

              <Row className="align-items-center justify-content-md-between text-white">
                {/* <Col className="text-lg-center btn-wrapper" lg=""> */}

                  {/*   <Button
                    className="btn-icon-only rounded-circle ml-1"
                    color="facebook"
                    href="https://www.facebook.com/creativetim"
                    id="tooltip837440414"
                    target="_blank"
                  >
                    <span className="btn-inner--icon">
                      <i className="fa fa-facebook-square" />
                    </span>
                  </Button>
                  <UncontrolledTooltip delay={0} target="tooltip837440414">
                    Like us
                </UncontrolledTooltip>
                  <Button
                    className="btn-icon-only rounded-circle ml-1"
                    color="dribbble"
                    href="https://dribbble.com/creativetim"
                    id="tooltip829810202"
                    target="_blank"
                  >
                    <span className="btn-inner--icon">
                      <i className="fa fa-dribbble" />
                    </span>
                  </Button>
                  <UncontrolledTooltip delay={0} target="tooltip829810202">
                    Follow us
                </UncontrolledTooltip> */}
                {/* </Col> */}
                <Col>
                  <div className="copyright text-center">
                    © {new Date().getFullYear()}{" "}
                  Rocket Science Software LTD

                </div>
                </Col>

              </Row>
            </Container>
          </section>
        </footer>
      </>
    );
  }
}

export default CardsFooter;
