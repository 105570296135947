import React from "react";
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import {
  NavbarBrand,
  Navbar,
  Container,
} from "reactstrap";

const LoginButton = () => {
  const history = useHistory();

  return (
    <Navbar className="navbar-main navbar-light headroom" expand="lg"
      style={{ height: 50, width: "100%", backgroundColor: "transparent" }}
    >
      <Container>
        <NavbarBrand href="/">
          <img
            alt="..."
            src={require("assets/img/brand/cvlogo.png")}
            width="40"
          />
        </NavbarBrand>

        <Button
          onClick={() => history.push("/signin")}
          className="btn-neutral btn-icon"
          color="default"
          id="login"
        >
          <span className="btn-inner--icon">
            <i className="fa fa-sign-in mr-2" />
          </span>
          <span className="nav-link-inner--text ml-1">
            Login
                      </span>
        </Button>


      </Container>
    </Navbar>
  )
};

export default LoginButton;