/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:85bd7ebf-d3df-4342-90cd-3c0d269da689",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_hS6uAujxo",
    "aws_user_pools_web_client_id": "53a0dd7k0q0dfc9l40qed2dgcb",
    "oauth": {
        "domain": "onlinecv5261e8c4-5261e8c4-dev.auth.eu-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.onlinecv.app/home/",
        "redirectSignOut": "https://www.onlinecv.app/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
