import React from 'react'
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/AddCircleRounded';
import Delete from '@material-ui/icons/DeleteRounded';

const SkillsDetails = (props) => {
    return (
        props.skillList.map((val, idx) => {

            return (
                <tr key={val.index} className="">
                    <td className="">
                        <input type="text"
                            name="name"
                            placeholder='Ex.: Microsoft Word'
                            className="form-control box"
                            onChange={(e) => { props.onSkillDetailsChange(e.target.name, e.target.value, idx) }}
                            value={props.skillList[idx].name}
                        />
                    </td>
                    <td className="box">
                    </td>
                    <td className="">
                        <select name="level" value={props.skillList[idx].level} onChange={(e) => { props.onSkillDetailsChange(e.target.name, e.target.value, idx) }}>
                            <option value="4">Expert</option>
                            <option value="3">Advanced</option>
                            <option value="2">User</option>
                            <option value="1">Basic</option>
                            <option value="0">Beginner</option>
                        </select>
                    </td>
                    <td className="mr10">
                        <div className="mr10">
                            {props.skillList.length !== 1 && <Button className="mr20" color="secondary"
                                onClick={(() => props.delete(val))}><Delete /></Button>}
                            {props.skillList.length - 1 === idx && <Button className="mr20" color="primary"
                                onClick={() => props.add()} ><Add /></Button>}
                        </div>
                    </td>
                </tr >
            )

        })
    )
}
export default SkillsDetails
