import React from "react";
import { Link } from "react-router-dom";
import {
    UncontrolledCollapse,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col
} from "reactstrap";
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { clearSession } from 'actions/session';

const LogoutButton = (props) => {
    const history = useHistory();

    async function clearUserSession() {
        await Auth.signOut();
        await clearSession();

        if (window.location.pathname === "/") {
            window.location.reload(false);
        } else {
            history.replace("/");
        }
    }

    return (
        <Navbar className="navbar-main navbar-light headroom" expand="lg" 
        style={{height: 85, width: "100%", backgroundColor: "transparent"}}>
            <Container>
                <NavbarBrand href="/">
                    <img
                        alt="..."
                        src={require("assets/img/brand/cvlogo.png")}
                        width="40"
                    />
                </NavbarBrand>


                <div>
                    <button className="navbar-toggler" id="navbar-default">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <UncontrolledCollapse navbar toggler="#navbar-default" id="loggedin-navbar">
                        <div className="navbar-collapse-header">
                            <Row>
                                <Col className="collapse-brand" xs="6">
                                    <Link to="/">
                                        <img
                                            alt="..."
                                            src={require("assets/img/brand/cvlogo.png")}
                                        />
                          OnlineCV
                        </Link>
                                </Col>
                                <Col className="collapse-close" xs="6">
                                    <button className="navbar-toggler" id="navbar-default">
                                        <span />
                                        <span />
                                    </button>
                                </Col>
                            </Row>
                        </div>
                        <Nav className="ml-lg-auto" navbar>
                            <NavItem>
                                <NavLink
                                    className="nav-link-icon"
                                    href="/home"
                                >
                                    <i className="fa fa-home fa-lg" />
                                    <span className="nav-link-inner--text d-lg-none">
                                        Home
                      </span>
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    className="nav-link-icon"
                                    href="/settings"
                                >
                                    <i className="fa fa-cog fa-lg" />
                                    <span className="nav-link-inner--text d-lg-none">
                                        Settings
                      </span>
                                </NavLink>
                            </NavItem>

                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav className="nav-link-icon">
                                    <i className="fa fa-user-circle-o fa-lg" />
                                    <span className="nav-link-inner--text d-lg-none">
                                        More
                      </span>
                                </DropdownToggle>

                                <DropdownMenu
                                    aria-labelledby="navbar-default_dropdown_1"
                                    right
                                >
                                    <DropdownItem
                                        href="/register?stage=0"
                                    >
                                        Personal details
                                    </DropdownItem>
                                    <DropdownItem
                                        href="/register?stage=1"
                                    >
                                        Experience details
                                    </DropdownItem>
                                    <DropdownItem
                                        href="/register?stage=2"
                                    >
                                        Change CV Template
                                    </DropdownItem>
                                    
                                    <DropdownItem divider />
                                    <DropdownItem
                                        href="#logout"
                                        onClick={e => {
                                            e.preventDefault();
                                            clearUserSession();
                                        }}
                                    >
                                        Logout
                      </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </UncontrolledCollapse>
                </div>

            </Container>
        </Navbar>


        // <Button
        //     onClick={async () => {
        //         try {
        //             props.clearSession();
        //         } catch (error) {
        //         }
        //     }}
        //     className="btn-neutral btn-icon"
        //     color="default"
        //     id="logout"
        // >
        //     <span className="btn-inner--icon">
        //         <i className="fa fa-sign-out mr-2" />
        //     </span>
        //     <span className="nav-link-inner--text ml-1">
        //         Logout
        //               </span>
        // </Button>
    );
};

export default LogoutButton;