import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect, withRouter } from "react-router-dom";
import WebFont from 'webfontloader';

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

import Index from "views/Index";
import SignIn from "views/pages/SignIn";
import Home from "views/pages/customer/Home";
import Settings from "views/pages/customer/Settings";
import SetAvailability from "views/pages/customer/SetAvailability";
import TemplateList from "views/pages/TemplateList";
import SignUp from "views/pages/SignUp";
import Register from "views/pages/Register";
import Faq from "views/pages/faq";


import Cookie from "views/pages/legal/Cookie";
import Privacy from "views/pages/legal/Privacy";
import Terms from "views/pages/legal/Terms";

import Amplify from 'aws-amplify';
import config from './aws-exports.js';

import { Provider } from "react-redux";
import store from "./store/index";
import { Auth } from 'aws-amplify';

Amplify.configure(config);

WebFont.load({
	custom: {
		families: ['FontAwesome', 'NucleoIcons']
	}
});

class PrivateRoute extends React.Component {
	state = {
		loaded: false,
		isAuthenticated: false
	}
	componentDidMount() {
		this.authenticate()
		this.unlisten = this.props.history.listen(() => {
			Auth.currentAuthenticatedUser()
				.then(user => {
					document.tidioIdentify = {
						email: user.signInUserSession.idToken.payload.email
					};
				})
				.catch(() => {
					if (this.state.isAuthenticated) this.setState({ isAuthenticated: false })
				})
		});
	}
	componentWillUnmount() {
		this.unlisten()
	}
	authenticate() {
		Auth.currentAuthenticatedUser()
			.then((user) => {
				document.tidioIdentify = {
					email: user.signInUserSession.idToken.payload.email
				};
				this.setState({ loaded: true, isAuthenticated: true })
			})
			.catch(() => this.props.history.push('/auth'))
	}
	render() {
		const { component: Component, ...rest } = this.props
		const { loaded, isAuthenticated } = this.state
		if (!loaded) return null
		return (
			<Route
				{...rest}
				render={props => {
					return isAuthenticated ? (
						<Component {...props} />
					) : (
							<Redirect
								to={{
									pathname: "/signin",
								}}
							/>
						)
				}}
			/>
		)
	}
}

PrivateRoute = withRouter(PrivateRoute)

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<Switch>
				{/* <Route path="/" exact render={props => <Index {...props} />} />
        <Route path="/signin" exact render={props => <SignIn {...props} />} />
        <Route
          path="/home"
          exact
          render={props => <Home {...props} />}
        />
        <Route
          path="/signup"
          exact
          render={props => <SignUp {...props} />}
        />
        <Route
          path="/register"
          exact
          render={props => <Register {...props} />}
        />

        <Route
          path="/cookie"
          exact
          render={props => <Cookie {...props} />}
        />

        <Route
          path="/terms"
          exact
          render={props => <Terms {...props} />}
        />

        <Route
          path="/privacy"
          exact
          render={props => <Privacy {...props} />}
        />

        import React from 'react';
import ReactDOM from 'react-dom';
import { Chart, Line, Point, Tooltip } from 'bizcharts';

// 数据源
const data = [
	{
		month: "Jan",
		city: "Tokyo",
		temperature: 7
	},
	{
		month: "Jan",
		city: "London",
		temperature: 3.9
	},
	{
		month: "Feb",
		city: "Tokyo",
		temperature: 6.9
	},
	{
		month: "Feb",
		city: "London",
		temperature: 4.2
	},
	{
		month: "Mar",
		city: "Tokyo",
		temperature: 9.5
	},
	{
		month: "Mar",
		city: "London",
		temperature: 5.7
	},
	{
		month: "Apr",
		city: "Tokyo",
		temperature: 14.5
	},
	{
		month: "Apr",
		city: "London",
		temperature: 8.5
	},
	{
		month: "May",
		city: "Tokyo",
		temperature: 18.4
	},
	{
		month: "May",
		city: "London",
		temperature: 11.9
	},
	{
		month: "Jun",
		city: "Tokyo",
		temperature: 21.5
	},
	{
		month: "Jun",
		city: "London",
		temperature: 15.2
	},
	{
		month: "Jul",
		city: "Tokyo",
		temperature: 25.2
	},
	{
		month: "Jul",
		city: "London",
		temperature: 17
	},
	{
		month: "Aug",
		city: "Tokyo",
		temperature: 26.5
	},
	{
		month: "Aug",
		city: "London",
		temperature: 16.6
	},
	{
		month: "Sep",
		city: "Tokyo",
		temperature: 23.3
	},
	{
		month: "Sep",
		city: "London",
		temperature: 14.2
	},
	{
		month: "Oct",
		city: "Tokyo",
		temperature: 18.3
	},
	{
		month: "Oct",
		city: "London",
		temperature: 10.3
	},
	{
		month: "Nov",
		city: "Tokyo",
		temperature: 13.9
	},
	{
		month: "Nov",
		city: "London",
		temperature: 6.6
	},
	{
		month: "Dec",
		city: "Tokyo",
		temperature: 9.6
	},
	{
		month: "Dec",
		city: "London",
		temperature: 4.8
	}
];

const scale = {
	temperature: { min: 0 },
	city: {
		formatter: v => {
			return {
				London: '伦敦',
				Tokyo: '东京'
			}[v]
		}
	}
}

function Demo() {
	return <Chart scale={scale} padding={[30, 20, 50, 40]} autoFit height={320} data={data} interactions={['element-active']}>
		<Point position="month*temperature" color="city" shape='circle' />
		<Line shape="smooth" position="month*temperature" color="city" label="temperature" />
		<Tooltip shared showCrosshairs />
	</Chart>
}



ReactDOM.render(<Demo />, mountNode);


 */}

				<Route path="/" component={Index} exact render={props => <Index {...props} />} />
				<Route path="/signin" component={SignIn} />
				<Route path="/signup" component={SignUp} />
				<Route path="/faq" component={Faq} />

				<Route path="/cookie" component={Cookie} />
				<Route path="/terms" component={Terms} />
				<Route path="/privacy" component={Privacy} />
				<Route path="/templates" component={TemplateList} />

				<PrivateRoute path="/home" component={Home} />

				<PrivateRoute path="/settings/availability" component={SetAvailability} />
				<PrivateRoute path="/settings" component={Settings} />

				<PrivateRoute path="/register" component={Register} />

				<Redirect to="/" />

			</Switch>
		</BrowserRouter>
	</Provider>,


	document.getElementById("root")
);