import React from "react";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Navbar
} from "reactstrap";
import { Auth } from "aws-amplify";
import LogoutButton from "../Others/LogoutButton";
import LoginButton from "components/Others/LoginButton";

class MainNavbar extends React.Component {
  state = {
    collapseClasses: "",
    collapseOpen: false,
    user: null,
    loading: true
  };

  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();

    Auth.currentSession()
      .then(data => {
        this.setState({ user: data, loading: false });
      })
      .catch(err => console.log(err));
  }

  onExiting = () => {
    // this.setState({
    //   collapseClasses: "collapsing-out"
    // });
  };

  onExited = () => {
    // this.setState({
    //   collapseClasses: ""
    // });
  };

  render() {
    if (this.state.loading) {
      return (
        <>
          <header className="header-global">
            <Navbar
              className="navbar-main navbar-transparent navbar-light"
              expand="lg"
              id="navbar-main"
              style={{height: 85, width: "100%"}}
            >
              <LoginButton></LoginButton>
            </Navbar>
          </header>
        </>
      );
    } else {
      return (
        <>
          <header className="header-global">
            <Navbar
              className="navbar-main navbar-transparent navbar-light"
              expand="lg"
              id="navbar-main"
              style={{height: 85, width: "100%"}}
            >
              {this.state.user &&
                <LogoutButton></LogoutButton>
              }
              {!this.state.user &&
                <LoginButton></LoginButton>
              }
  
            </Navbar>
          </header>
        </>
      );
    }
  }
}

export default MainNavbar;

