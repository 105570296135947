
import React from "react";

// core components
import MainNavbar from "components/Navbars/MainNavbar";
import { Button, StepLabel, Step, Stepper, Typography } from '@material-ui/core';

// Cookie consent
import CVCookieConsent from "../../components/Footers/Cookie-Consent";

import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PesronIcon from '@material-ui/icons/AssignmentInd';
import SkillIcon from '@material-ui/icons/Assignment';
import TemplateIcon from '@material-ui/icons/AssignmentTurnedIn';
import StepConnector from '@material-ui/core/StepConnector';
import PersonalDetails from "components/registration/PersonalDetails";
import SecondStep from "components/registration/WorkexperienceMain";
import ThirdStep from "components/registration/Template";
import Spinner from 'react-bootstrap/Spinner';
import {
  Row,
  Col
} from "reactstrap";

import { Auth } from 'aws-amplify';
import { getBaseUrl } from "functions/util";

import queryString from 'query-string';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 24,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 136deg, rgb(104,245,79) 0%, rgb(71,195,167) 50%, rgb(38,125,77) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 136deg, rgb(104,245,79) 0%, rgb(71,195,167) 50%, rgb(38,125,77) 100%)',
    },
  },
  line: {
    height: 3,
    border: 5,
    backgroundColor: '#eaeaf0',
    borderRadius: 5,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(150,113,33) 0%, rgb(70,124,137) 50%, rgb(50,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(104,245,79) 0%, rgb(71,195,167) 50%, rgb(38,125,77) 100%)',

  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <PesronIcon />,
    2: <SkillIcon />,
    3: <TemplateIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

class Register extends React.Component {
  state = {
    activeStep: 0,
    steps: ['Personal', 'Experiences', 'T&C'],
    loading: true,
    user: {},
    summary: "",
    experiences: [{ indexE: Math.random() }],
    projects: [{ indexE: Math.random() }],
    educations: [{ indexE: Math.random() }],
    certificates: [{ indexW: Math.random(), name: "" }],
    skills: [{ indexW: Math.random(), name: "", level: 4 }],
    skillErr: false,
    session: {},
    isFieldErrors: false,
    isProfileFieldErrors: false,
    isEditMode: false,
    pickedTemplate: 7,
    givenStage: 0
  }

  getStringLength = (str) => {
    let remText = str.replace(/ /g, "");
    return remText.length;
  }

  handleNext = () => {
    if (this.state.activeStep === 0) {

      let user = this.state.user;

      var isError = false;
      var errMsg = "These fields are mandatory\n"
      if (!user.lastName || this.getStringLength(user.lastName) === 0) {
        isError = true;
        errMsg += "Last name\n";
      }
      if (!user.firstName || this.getStringLength(user.firstName) === 0) {
        isError = true;
        errMsg += "First name\n";
      }
      if (!user.phoneNumber || this.getStringLength(user.phoneNumber) === 0) {
        isError = true;
        errMsg += "Phone number\n";
      }

      if (isError) {
        this.setState({ isFieldErrors: true, errorMessage: errMsg });
      } else {
        this.updateLatestCustomerData(this.state.session.credentials.accessToken);

        if (!this.state.isEditMode) {
          this.getLatestCustomerOtherData(this.state.session.credentials.accessToken);
        }
        this.setState({ loading: true, isFieldErrors: false, errorMessage: "" });
      }
    } else if (this.state.activeStep === 1) {
      isError = false;
      if (!this.state.summary || this.getStringLength(this.state.summary) === 0) {
        isError = true;
        this.setState({ isProfileFieldErrors: true });
      }

      //Experience
      var exps = [];
      var expErr = false;
      for (let index = 0; index < this.state.experiences.length; index++) {
        isError = false;
        errMsg = "These fields are mandatory\n";
        const element = this.state.experiences[index];

        if (!element.jobTitle || this.getStringLength(element.jobTitle) === 0) {
          element.isFieldErrors = true;
          isError = true;
          errMsg += "Title/Position\n";
        }
        if (!element.employer || this.getStringLength(element.employer) === 0) {
          element.isFieldErrors = true;
          isError = true;
          errMsg += "Company\n";
        }
        if (!element.city || this.getStringLength(element.city) === 0) {
          element.isFieldErrors = true;
          isError = true;
          errMsg += "City/Town\n";
        }
        if (!element.startDate || this.getStringLength(element.startDate) === 0) {
          element.isFieldErrors = true;
          isError = true;
          errMsg += "Start Date\n";
        }
        if (isError) {
          element.errorMessage = errMsg;
          expErr = true;
        }
        exps.push(element);
      }
      // if (exps.length === 0) {
      //   exps.push({})
      // }

      //Experience
      var prjs = [];
      var prjErr = false;
      for (let index = 0; index < this.state.projects.length; index++) {
        isError = false;
        errMsg = "These fields are mandatory\n";
        const element = this.state.projects[index];

        if (!element.projectTitle || this.getStringLength(element.projectTitle) === 0) {
          element.isFieldErrors = true;
          isError = true;
          errMsg += "Title\n";
        }
        if (isError) {
          element.errorMessage = errMsg;
          prjErr = true;
        }
        prjs.push(element);
      }

      //Experience
      var edus = [];
      var eduErr = false;
      for (let index = 0; index < this.state.educations.length; index++) {
        isError = false;
        errMsg = "These fields are mandatory\n";
        const element = this.state.educations[index];

        if (!element.course || this.getStringLength(element.course) === 0) {
          element.isFieldErrors = true;
          isError = true;
          errMsg += "Studies\n";
        }
        if (!element.university || this.getStringLength(element.university) === 0) {
          element.isFieldErrors = true;
          isError = true;
          errMsg += "Institution/Centre\n";
        }
        if (!element.city || this.getStringLength(element.city) === 0) {
          element.isFieldErrors = true;
          isError = true;
          errMsg += "Location\n";
        }
        if (!element.startDate || this.getStringLength(element.startDate) === 0) {
          element.isFieldErrors = true;
          isError = true;
          errMsg += "Start Date\n";
        }
        if (isError) {
          element.errorMessage = errMsg;
          eduErr = true;
        }
        edus.push(element);
      }

      //Experience
      var certs = [];
      var certErr = false;
      for (let index = 0; index < this.state.certificates.length; index++) {
        isError = false;
        errMsg = "These fields are mandatory\n";
        const element = this.state.certificates[index];

        if (!element.name || this.getStringLength(element.name) === 0) {
          element.isFieldErrors = true;
          isError = true;
          errMsg += "Name\n";
        }
        if (isError) {
          element.errorMessage = errMsg;
          certErr = true;
        }
        certs.push(element);
      }

      //Skills
      var skillErr = false;
      for (let index = 0; index < this.state.skills.length; index++) {
        const element = this.state.skills[index];

        if (!element.name || this.getStringLength(element.name) === 0) {
          skillErr = true;
        }
      }

      if (isError || expErr || eduErr || skillErr || prjErr || certErr) {
        this.setState({
          experiences: exps,
          projects: prjs,
          educations: edus,
          certificates: certs,
          skillErr: skillErr,
          certErr: certErr
        })
      } else {
        this.updateExpEduDetails(this.state.session.credentials.accessToken);
        this.setState({ loading: true, isProfileFieldErrors: false });
      }

    } else if (this.state.activeStep === 2) {
      this.createCV(this.state.session.credentials.accessToken);
      this.setState({ loading: true });
    }

    //setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  handleReset = () => {
    this.setState({ activeStep: 0 })
  };

  /* Handle child changes */
  handleUserDetailsChange = (e) => {
    let target = e.target.name;
    let value = e.target.value

    var user = this.state.user;
    user[target] = value;

    this.setState({ user: user });
  }

  handleProfileSummaryChange = (contents, delta, source) => {
    if (source === 'user') {
      this.setState({
        summary: contents
      })
    }
  }

  /* Experience */
  handleAddExperiences = () => {
    this.setState((prevState) => ({
      experiences: [...prevState.experiences, { indexW: Math.random(), jobTitle: "", city: "" }],
    }));
  }

  handleDeleteExperiences = (record) => {
    this.setState({ experiences: this.state.experiences.filter(w => w !== record) })
  }

  onExpDetailsChange = (key, value, idx) => {
    var exps = this.state.experiences;
    exps[idx][key] = value;

    this.setState({
      experiences: exps
    });
  }

  onExpJobDescriptionChange = (contents, idx) => {
    //if (source === 'user') {
    var exps = this.state.experiences;
    exps[idx]['jobDescription'] = contents;

    this.setState({
      experiences: exps
    });
    //}
  }

  /* Projects */
  handleAddProjects = () => {
    this.setState((prevState) => ({
      projects: [...prevState.projects, { indexW: Math.random() }],
    }));
  }

  handleDeleteProjects = (record) => {
    this.setState({ projects: this.state.projects.filter(w => w !== record) })
  }

  onProjectDetailsChange = (key, value, idx) => {
    var exps = this.state.projects;
    exps[idx][key] = value;

    this.setState({
      projects: exps
    });
  }

  onProjectDescriptionChange = (contents, idx) => {
    var exps = this.state.projects;
    exps[idx]['projectDescription'] = contents;

    this.setState({
      projects: exps
    });
  }

  /* Education */
  handleAddEducations = () => {
    this.setState((prevState) => ({
      educations: [...prevState.educations, { indexE: Math.random() }],
    }));
  }

  handleDeleteEducations = (record) => {
    this.setState({
      educations: this.state.educations.filter(ed => ed !== record),
    });
  }

  handleEducationDetailsChange = (key, value, idx) => {
    var edus = this.state.educations;
    edus[idx][key] = value

    this.setState({
      educations: edus
    });
  }

  /* Certificates */
  handleAddCertificates = () => {
    this.setState((prevState) => ({
      certificates: [...prevState.certificates, { index: Math.random(), name: "" }],
    }));
  }

  handleDeleteCertificates = (record) => {
    this.setState({
      certificates: this.state.certificates.filter(r => r !== record),
    });
  }

  handleCertificatesDetailsChange = (key, value, idx) => {
    var certificates = this.state.certificates;
    certificates[idx][key] = value

    this.setState({
      certificates: certificates
    });
  }

  /* Skills */
  handleAddSkills = () => {
    this.setState((prevState) => ({
      skills: [...prevState.skills, { index: Math.random(), name: "", level: "4" }],
    }));
  }

  handleDeleteSkills = (record) => {
    this.setState({
      skills: this.state.skills.filter(r => r !== record),
    });
  }

  handleSkillDetailsChange = (key, value, idx) => {
    var skills = this.state.skills;
    skills[idx][key] = value

    this.setState({
      skills: skills
    });
  }

  /* Handle child changes */

  componentDidMount() {
    document.title = "Register";
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    //this.refs.main.scrollTop = 0;

    let params = queryString.parse(this.props.location.search);

    if (params && params.stage) {
      this.setState({ givenStage: parseInt(params.stage) });
    }

    if (this.props.session && this.props.session.isLoggedIn) {
      this.setState({ isAuthenticated: true });
      this.getLatestCustomerData(this.props.session.credentials.accessToken);
    } else {
      Auth.currentAuthenticatedUser()
        .then(user => {
          document.tidioIdentify = {
						email: user.signInUserSession.idToken.payload.email
          };
          
          const signInSession = user.signInUserSession;

          const session = {
            credentials: {
              accessToken: signInSession.accessToken.jwtToken
            },
            user: {
              userName: signInSession.idToken.payload['cognito:username'],
              email: signInSession.idToken.payload.email
            }
          }

          //this.props.initSession(session);

          this.setState({ isAuthenticated: true, session: session });

          this.getLatestCustomerData(signInSession.accessToken.jwtToken);

          return user;
        })
        .catch(ex => {
          this.setState({ isAuthenticated: false }, () => {
            this.setState({ loading: false });
          });

          this.props.history.replace("/signin");
        });
    }

    this.getLatestCustomerData = this.getLatestCustomerData.bind(this);
  }

  showFormHeading = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return 'Personal Information';
      case 1:
        return 'Experience';
      case 2:
        return 'Template';
      default:
        return 'foo';
    }
  }

  onTemplateChangeValue = (event) => {
    //event.preventDefault();

    if (event.target.id === "t1") {
      this.setState({ pickedTemplate: 1 });
    } else if (event.target.id === "t2") {
      this.setState({ pickedTemplate: 2 });
    } else if (event.target.id === "t3") {
      this.setState({ pickedTemplate: 3 });
    } else if (event.target.id === "t4") {
      this.setState({ pickedTemplate: 4 });
    } else if (event.target.id === "t5") {
      this.setState({ pickedTemplate: 5 });
    } else if (event.target.id === "t6") {
      this.setState({ pickedTemplate: 6 });
    } else if (event.target.id === "t7") {
      this.setState({ pickedTemplate: 7 });
    }
  }

  getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <PersonalDetails
          user={this.state.user}
          onChange={this.handleUserDetailsChange}
          isFieldErrors={this.state.isFieldErrors}
          errorMessage={this.state.errorMessage}
        />;
      case 1:
        return <SecondStep
          summary={this.state.summary} handleProfileSummaryChange={this.handleProfileSummaryChange} isProfileFieldErrors={this.state.isProfileFieldErrors}
          experiences={this.state.experiences} handleAddExperiences={this.handleAddExperiences} handleDeleteExperiences={this.handleDeleteExperiences} handleExpDetailsChange={this.onExpDetailsChange} handleJobDescriptionChange={this.onExpJobDescriptionChange}
          projects={this.state.projects || []} handleAddProjects={this.handleAddProjects} handleDeleteProjects={this.handleDeleteProjects} handleProjectDetailsChange={this.onProjectDetailsChange} handleProjectDescriptionChange={this.onProjectDescriptionChange}
          educations={this.state.educations} handleAddEducations={this.handleAddEducations} handleDeleteEducations={this.handleDeleteEducations} handleEducationDetailsChange={this.handleEducationDetailsChange}
          certificates={this.state.certificates} handleAddCertificates={this.handleAddCertificates} handleDeleteCertificates={this.handleDeleteCertificates} handleCertificatesDetailsChange={this.handleCertificatesDetailsChange} certErr={this.state.certErr}
          skills={this.state.skills} handleAddSkills={this.handleAddSkills} handleDeleteSkills={this.handleDeleteSkills} handleSkillDetailsChange={this.handleSkillDetailsChange} skillErr={this.state.skillErr}
        />;
      case 2:
        return <ThirdStep isEditMode={this.state.isEditMode} pickedTemplate={this.state.pickedTemplate} onTemplateChangeValue={this.onTemplateChangeValue} />;
      default:
        return 'Unknown stepIndex';
    }
  }

  getLatestCustomerData = (accessToken) => {
    let baseUrl = getBaseUrl();

    Promise.all([
      fetch(baseUrl + '/customer', {
        headers: {
          'Authorization': 'Bearer ' + accessToken
        }
      }),
      fetch(baseUrl + '/signup', {
        headers: {
          'Authorization': 'Bearer ' + accessToken
        }
      })
    ]).then(responses => {
      return Promise.all(responses.map((response) => {
        if (response === 200) {
          return response.json();
        } else {
          this.setState({ isAuthenticated: false });
          return response.json();
        }
      }));
    })
      .then(data => {
        var customer = data[0];
        var stage = data[1];
        var activeStep = stage.stage;

        if (stage.stage > 2) { //Edit mode
          if (this.state.givenStage > 0 && this.state.givenStage < 3) {
            if (this.state.givenStage === 1 || this.state.givenStage === 2) {
              this.getLatestCustomerOtherData(this.state.session.credentials.accessToken);
              
              this.setState({
                loading: true
              });
            }
            activeStep = this.state.givenStage;

            this.setState({
              user: customer,
              activeStep: activeStep,
              isEditMode: stage.stage > 2
            });
          } else {
            this.getCvData(this.state.session.credentials.accessToken);
            activeStep = 0;

            this.setState({
              user: customer,
              activeStep: activeStep,
              isEditMode: stage.stage > 2,
              loading: false
            });
          }

        } else {
          this.setState({
            user: customer,
            activeStep: activeStep,
            isEditMode: stage.stage > 2,
            loading: false
          });
        }


      })
      .catch(function (error) {
        //console.log(error);

        this.setState({
          loading: false
        });
      });
  }

  getCvData = (accessToken) => {
    let baseUrl = getBaseUrl();
    let authHeader = {
      'Authorization': 'Bearer ' + accessToken
    };

    fetch(baseUrl + '/cv', {
      headers: authHeader
    })
      .then(response => response.json())
      .then(data => {
        var templateId = 7;
        if (data && data.isPublic && data.templateId) {
          templateId = data.templateId;
        }

        this.setState({pickedTemplate: templateId});

      })
      .catch(error => console.error(error))
  }

  getLatestCustomerOtherData = (accessToken) => {
    let baseUrl = getBaseUrl();
    let authHeader = {
      'Authorization': 'Bearer ' + accessToken
    };

    Promise.all([
      fetch(baseUrl + '/profile', {
        headers: authHeader
      }),
      fetch(baseUrl + '/experience', {
        headers: authHeader
      }),
      fetch(baseUrl + '/project', {
        headers: authHeader
      }),
      fetch(baseUrl + '/education', {
        headers: authHeader
      }),
      fetch(baseUrl + '/skill', {
        headers: authHeader
      }),
      fetch(baseUrl + '/cv', {
        headers: authHeader
      })
    ]).then(responses => {
      return Promise.all(responses.map((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          this.setState({ isAuthenticated: false });
          return response.json();
        }

      }));
    })
      .then(data => {
        var profile = data[0];
        var exps = data[1];
        var prjs = data[2];
        var edus = data[3];
        var skills = data[4];
        var cv = data[5];

        if (typeof profile.summary === 'undefined') {
          this.setState({
            summary: ""
          });
        } else {
          this.setState({
            summary: decodeURI(profile.summary)
          });
        }

        //decode
        var expss = [];
        if (exps.experiences && exps.experiences.length > 0) {
          for (let index = 0; index < exps.experiences.length; index++) {
            const element = exps.experiences[index];
            var jd = decodeURI(element.jobDescription);

            if (jd === "undefined") {
              jd = "";
            }
            expss.push({
              indexW: Math.random(), jobTitle: element.jobTitle, employer: element.employer,
              city: element.city, startDate: element.startDate, endDate: element.endDate,
              jobDescription: jd, isFieldErrors: false,
              errorMessage: ""
            });
          }
        }
        // else {
        //   expss.push({ indexW: Math.random(), jobTitle: "", jobDescription: '', employer: "", city: "", startDate: "", endDate: "", errorMessage: "" });
        // }

        var prjss = [];
        if (prjs.projects && prjs.projects.length > 0) {
          for (let index = 0; index < prjs.projects.length; index++) {
            const element = prjs.projects[index];
            var pd = decodeURI(element.projectDescription);

            if (pd === "undefined") {
              pd = "";
            }
            prjss.push({
              indexW: Math.random(), projectTitle: element.projectTitle,
              projectDescription: pd, isFieldErrors: false,
              errorMessage: ""
            });
          }
        }

        var eduss = [];
        if (edus.educations) {
          for (let index = 0; index < edus.educations.length; index++) {
            const element = edus.educations[index];

            eduss.push({
              indexE: Math.random(), course: element.course, university: element.university,
              city: element.city, startDate: element.startDate, endDate: element.endDate
            })
          }
        } else {
          eduss.push({ indexE: Math.random() });
        }

        var certss = [];
        if (edus.certificates) {
          for (let index = 0; index < edus.certificates.length; index++) {
            const element = edus.certificates[index];

            certss.push({ indexE: Math.random(), name: element.name })
          }
        } else {
          certss.push({ indexE: Math.random() });
        }

        var skillss = [];
        if (skills.skills) {
          for (let index = 0; index < skills.skills.length; index++) {
            const element = skills.skills[index];

            skillss.push({
              indexW: Math.random(), name: element.name, level: element.level
            })
          }
        } else {
          skillss.push({ indexW: Math.random(), name: "", level: 4 });
        }

        var templateId = 7;
        if (cv && cv.isPublic && cv.templateId) {
          templateId = cv.templateId;
        }

        this.setState({
          experiences: expss,
          projects: prjss,
          educations: eduss,
          skills: skillss,
          pickedTemplate: templateId,
          certificates: certss,
          loading: false
        });
      })
      .catch((err) => {
        //console.log(err);
      });
  }

  updateLatestCustomerData = (accessToken) => {
    let baseUrl = getBaseUrl();
    let user = this.state.user;
    let payLoad = {
      lastName: user.lastName,
      firstName: user.firstName,
      city: user.city,
      phoneNumber: user.phoneNumber,
      linkedin: user.linkedin || ''
    }

    if (this.state.isEditMode) {
      Promise.all([
        fetch(baseUrl + '/customer', {
          method: 'PUT',
          body: JSON.stringify(payLoad),
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }
        }),
        fetch(baseUrl + '/cv', {
          method: 'POST',
          body: JSON.stringify({ templateId: this.state.pickedTemplate }),
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }
        })
      ]).then(responses => {
        return Promise.all(responses.map((response) => {
          if (response === 200) {
            return response.json();
          } else {
            this.setState({ isAuthenticated: false });
            return response.json();
          }
        }));
      })
        .then(data => {
          this.props.history.replace("/home");
        })
        .catch(function (error) {
          console.log(error);
  
          this.setState({
            loading: false
          });
        });
    } else {
      Promise.all([
        fetch(baseUrl + '/customer', {
          method: 'PUT',
          body: JSON.stringify(payLoad),
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }
        }),
        fetch(baseUrl + '/signup', {
          method: 'PUT',
          body: JSON.stringify({ stage: this.state.isEditMode ? 3 : 1 }),
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }
        })
      ]).then(responses => {
        return Promise.all(responses.map((response) => {
          if (response === 200) {
            return response.json();
          } else {
            this.setState({ isAuthenticated: false });
            return response.json();
          }
        }));
      })
        .then(data => {
          if (this.state.isEditMode) {
            this.props.history.replace("/home");
          } else {
            this.setState({
              loading: false,
              activeStep: this.state.activeStep + 1
            });
          }
        })
        .catch(function (error) {
          console.log(error);
  
          this.setState({
            loading: false
          });
        });
    }

    
  }

  updateExpEduDetails = (accessToken) => {
    let profileSummary = { summary: encodeURI(this.state.summary) };
    let baseUrl = getBaseUrl();

    var expDetails = [];
    for (let index = 0; index < this.state.experiences.length; index++) {
      const element = this.state.experiences[index];
      expDetails.push({
        jobTitle: element.jobTitle, employer: element.employer, city: element.city,
        startDate: element.startDate, endDate: element.endDate, jobDescription: encodeURI(element.jobDescription)
      });
    }
    let experiences = { experiences: expDetails };

    var prjDetails = [];
    for (let index = 0; index < this.state.projects.length; index++) {
      const element = this.state.projects[index];
      prjDetails.push({
        projectTitle: element.projectTitle, projectDescription: encodeURI(element.projectDescription)
      });
    }
    let projects = { projects: prjDetails };

    var eduDetails = [];
    for (let index = 0; index < this.state.educations.length; index++) {
      const element = this.state.educations[index];
      eduDetails.push({
        university: element.university, course: element.course, city: element.city,
        startDate: element.startDate, endDate: element.endDate
      });
    }
    var certDetails = [];
    for (let index = 0; index < this.state.certificates.length; index++) {
      const element = this.state.certificates[index];
      certDetails.push({
        name: element.name
      });
    }

    let educations = { educations: eduDetails, certificates: certDetails };

    var skillDetails = [];
    for (let index = 0; index < this.state.skills.length; index++) {
      const element = this.state.skills[index];
      skillDetails.push({ name: element.name, level: element.level });
    }
    let skills = { skills: skillDetails };
    let authHeader = {
      'Authorization': 'Bearer ' + accessToken
    };

    Promise.all([
      fetch(baseUrl + '/profile', {
        headers: authHeader,
        method: 'POST',
        body: JSON.stringify(profileSummary),
      }),
      fetch(baseUrl + '/experience', {
        headers: authHeader,
        method: 'POST',
        body: JSON.stringify(experiences),
      }),
      fetch(baseUrl + '/project', {
        headers: authHeader,
        method: 'POST',
        body: JSON.stringify(projects),
      }),
      fetch(baseUrl + '/education', {
        headers: authHeader,
        method: 'POST',
        body: JSON.stringify(educations),
      }),
      fetch(baseUrl + '/skill', {
        headers: authHeader,
        method: 'POST',
        body: JSON.stringify(skills),
      }),
      fetch(baseUrl + '/signup', {
        method: 'PUT',
        body: JSON.stringify({ stage: this.state.isEditMode ? 3 : 2 }),
        headers: authHeader,
      })
    ]).then(responses => {
      return Promise.all(responses.map((response) => {
        if (response === 200) {
          return response.json();
        } else {
          this.setState({ isAuthenticated: false });
          return response.json();
        }
      }));
    })
      .then(data => {
        if (this.state.isEditMode) {
          fetch(baseUrl + '/cv', {
            method: 'POST',
            body: JSON.stringify({ templateId: this.state.pickedTemplate }),
            headers: authHeader,
          })
          .then(data => {
            this.props.history.replace("/home");
          })
          .catch(function (error) {
            this.setState({
              loading: false
            });
          });
        } else {
          this.setState({
            loading: false,
            activeStep: this.state.activeStep + 1
          });
        }

      })
      .catch(function (error) {
        this.setState({
          loading: false
        });
      });
  }

  createCV = (accessToken) => {
    let baseUrl = getBaseUrl();
    let cvPromise = fetch(baseUrl + '/cv', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + accessToken
      },
      body: JSON.stringify({ templateId: this.state.pickedTemplate }) //Change this with user choosen template id
    });

    let stagePromise = fetch(baseUrl + '/signup', {
      method: 'PUT',
      body: JSON.stringify({ stage: this.state.isEditMode ? 3 : 3 }),
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    });

    Promise.all([cvPromise, stagePromise])
      .then((values) => {
        //console.log(values);

        this.setState({
          loading: false
        });
        this.props.history.replace("/home");
      })
      .catch((err) => {
        //console.log(err);

        this.setState({
          loading: false
        });
      })
  }

  render() {

    return (
      <>

        <MainNavbar />

        <div className="position-relative">
          {/* Hero for FREE version */}
          <section className="section section-hero section-shaped">
            {/* Background circles */}
            <div className="shape shape-style-1 shape-default">
              <span className="span-150" />
              <span className="span-50" />
              <span className="span-50" />
              <span className="span-75" />
              <span className="span-100" />
              <span className="span-75" />
              <span className="span-50" />
              <span className="span-100" />
              <span className="span-50" />
              <span className="span-100" />
            </div>

            {this.state.loading &&
              <div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Spinner animation="grow" variant="primary" />
                  <Spinner animation="grow" variant="primary" />
                  <Spinner animation="grow" variant="primary" />
                </div>
                <span style={{ textAlign: "center", display: "grid", color: "white" }}>Fetching latest data...</span>
              </div>

            }

            {!this.state.loading &&
              <main ref="main">
                <form className="form-control-select">
                  <div className="px-lg-5 py-lg-5">
                    <div style={{ textAlign: 'center' }}>
                      <h4>{this.showFormHeading(this.state.activeStep)}</h4>
                    </div>
                    <div>
                      <div>
                        <div style={{ margin: "auto" }}>
                          {!this.state.isEditMode &&
                            <Stepper className="" style={{
                              backgroundColor: '#11ffee00'
                            }} alternativeLabel activeStep={this.state.activeStep} connector={<ColorlibConnector />}>
                              {this.state.steps.map((label) => (
                                <Step key={label}>
                                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                </Step>
                              ))}
                            </Stepper>
                          }
                        </div>
                        <div className="form-control1">
                          {/* {this.state.isFieldErrors &&
                                  <div class="alert alert-warning text-center" role="alert">
                                    <NewlineText text={this.state.errorMessage} />
                                  </div>
                                } */}
                          <Typography >{this.getStepContent(this.state.activeStep)}</Typography>

                          <Row lg="12" style={{ margin: "auto", width: "75%", marginTop: 20 }}>
                            <Col lg="6" style={{ alignSelf: "center" }}>
                              {!this.state.isEditMode &&
                                <Button
                                  // className="btn-white btn-icon mb-0 mb-sm-0 "
                                  color="primary"
                                  size="lg"
                                  class="btn btn-success"
                                  onClick={this.handleBack}
                                  hidden={this.state.activeStep === 0}
                                >
                                  <span className="btn-inner--icon mr-1">
                                    <i className="fa fa-arrow-circle-left" />
                                  </span>
                                  <span>BACK</span>
                                </Button>
                              }

                            </Col>

                            <Col lg="6" style={{ alignSelf: "center" }}>

                              <Button
                                type="button" color="danger"
                                size="lg"
                                class={this.state.activeStep !== this.state.steps.length - 1 ? 'btn btn-success' : 'btn btn-danger'}
                                style={{ float: 'right' }}
                                onClick={this.handleNext}
                              >
                                {this.state.isEditMode ? 'Save ' : this.state.activeStep === this.state.steps.length - 1 ? 'Agree & Create my CV  ' : 'NEXT  '}
                                <span className="btn-inner--icon mr-1">
                                  <i className={this.state.isEditMode ? 'fa fa-check-square' : this.state.activeStep !== this.state.steps.length - 1 ? 'fa fa-arrow-circle-right' : 'fa fa-paper-plane'} />
                                </span>
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </main>
            }
          </section>
        </div>
        {/* <CardsFooter /> */}
        <CVCookieConsent />
      </>

    );
  }
}

export default Register;


