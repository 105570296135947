import React from 'react'
import Button from '@material-ui/core/Button';
//import Add from '@material-ui/icons/AddCircleRounded';
import Delete from '@material-ui/icons/DeleteRounded';

const CertificateDetails = (props) => {
    return (
        props.certificateList.map((val, idx) => {

            return (
                <tr key={val.index} className="">
                    <td className="">
                        <input type="text"
                            name="name"
                            placeholder='Ex.: Certified Java Developer'
                            className="form-control box"
                            onChange={(e) => { props.onCertificateDetailsChange(e.target.name, e.target.value, idx) }}
                            value={props.certificateList[idx].name}
                        />
                    </td>
                    
                    <td className="mr10">
                        <div className="mr10">
                            {props.certificateList.length > 0 && <Button className="mr20" color="secondary"
                                onClick={(() => props.deleteCertificate(val))}><Delete /></Button>}
                        </div>
                    </td>
                </tr >
            )

        })
    )
}
export default CertificateDetails
