import React from "react";
import { Button, Container, Row, Col } from "reactstrap";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ImgWithFallback from "components/Others/ImgWithFallback";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
    partialVisibilityGutter: 40
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
    partialVisibilityGutter: 30
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
    partialVisibilityGutter: 30
  }
};

class CVTemplateCarousel extends React.Component {
  render() {
    return (
      <>
        <section className="section section-shaped">
          <div className="shape shape-style-1 shape-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="py-md">
            <Row className="justify-content-between align-items-center">
              <Col className="mb-5 mb-lg-0" lg="7">
                <h1 className="text-white font-weight-light">
                  Templates
                </h1>
                <p className="lead text-white mt-4">
                  We have templates for all job types and skill levels
                </p>
                <Button
                  className="btn-white mt-4"
                  color="default"
                  href="/templates"
                >
                  Explore
                </Button>
              </Col>
              <Col className="mb-lg-auto" lg="4">
                <div className="rounded shadow-lg overflow-hidden transform-perspective-right" >
                  {/* <UncontrolledCarousel items={items} /> */}
                  <Carousel

                    showDots={false}
                    responsive={responsive}
                    arrows={false}
                    renderButtonGroupOutside={true}
                    autoPlay={true}
                    infinite={true}>
                    <ImgWithFallback
                      type="image/webp"
                      src={require("assets/img/templates/template7-carousel.webp")}
                      fallback={require("assets/img/templates/template7-carousel.png")}
                      style={{ width: "100%", objectFit: "contain" }}
                      alt="Template 7"
                    >
                    </ImgWithFallback>
                    <ImgWithFallback
                      type="image/webp"
                      src={require("assets/img/templates/template6-carousel.webp")}
                      fallback={require("assets/img/templates/template6-carousel.png")}
                      style={{ width: "100%", objectFit: "contain" }}
                      alt="Template 6"
                    >
                    </ImgWithFallback>
                    <ImgWithFallback
                      type="image/webp"
                      src={require("assets/img/templates/template5-carousel.webp")}
                      fallback={require("assets/img/templates/template5-carousel.png")}
                      style={{ width: "100%", objectFit: "contain" }}
                      alt="Template 5"
                    >
                    </ImgWithFallback>
                    <ImgWithFallback
                      type="image/webp"
                      src={require("assets/img/templates/template4-carousel.webp")}
                      fallback={require("assets/img/templates/template4-carousel.png")}
                      style={{ width: "100%", objectFit: "contain" }}
                      alt="Template 4"
                    >
                    </ImgWithFallback>
                    <ImgWithFallback
                      type="image/webp"
                      src={require("assets/img/templates/template3-carousel.webp")}
                      fallback={require("assets/img/templates/template3-carousel.png")}
                      style={{ width: "100%", objectFit: "contain" }}
                      alt="Template 3"
                    >
                    </ImgWithFallback>
                    <ImgWithFallback
                      type="image/webp"
                      src={require("assets/img/templates/template2-carousel.webp")}
                      fallback={require("assets/img/templates/template2-carousel.png")}
                      style={{ width: "100%", objectFit: "contain" }}
                      alt="Template 2"
                    >
                    </ImgWithFallback>
                    <ImgWithFallback
                      type="image/webp"
                      src={require("assets/img/templates/template1-carousel.webp")}
                      fallback={require("assets/img/templates/template1-carousel.png")}
                      style={{ width: "100%", objectFit: "contain" }}
                      alt="Template 1"
                    >
                    </ImgWithFallback>
                  </Carousel>
                </div>
              </Col>
            </Row>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
      </>
    );
  }
}

export default CVTemplateCarousel;
