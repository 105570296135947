
import React from "react";
import { connect } from 'react-redux';
import MainNavbar from "components/Navbars/MainNavbar.js";
//import CVCookieConsent from "../../../components/Footers/Cookie-Consent";
import CardsFooter from "../../../components/Footers/CardsFooter";
import { Auth } from 'aws-amplify';
import { getBaseUrl } from "functions/util";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
//import PropTypes from 'prop-types';
import Checkbox from '../../../components/customcontrol/checkbox';
import Spinner from 'react-bootstrap/Spinner';

// reactstrap components
import {
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Button,
    Row,
    Col
} from "reactstrap";

import { initSession } from '../../../actions/session';



const mapStateToProps = state => {
    return { session: state.session }
}

function mapDispatchToProps(dispatch) {
    return {
        initSession: session => dispatch(initSession(session))
    }
}

class SetAvailability extends React.Component {

    state = {
        dateRadioValue: 'indefinite',
        isForEverEnabled: true,
        showDate: false,
        checkedItems: new Map(),
        isLoaded: true,
        weekday: [],
        session: {},
        startDate: "",
        endDate: "",
    };

    componentDidMount() {

        Auth.currentAuthenticatedUser()
            .then(user => {
                document.tidioIdentify = {
                    email: user.signInUserSession.idToken.payload.email
                };
                const signInSession = user.signInUserSession;

                //console.log(user);
                const session = {
                    credentials: {
                        accessToken: signInSession.accessToken.jwtToken
                    },
                    user: {
                        userName: signInSession.idToken.payload['cognito:username'],
                        email: signInSession.idToken.payload.email
                    }
                }


                this.setState({ isAuthenticated: true, session: session, isLoaded: false });

                return user;
            })
            .catch(ex => {
                this.setState({ isAuthenticated: false }, () => {
                    this.setState({ isLoaded: false });
                });

            });


    }

    getLatestCustomerData = (accessToken) => {
        //console.log("getLAtest Customer Data")

        // fetch("https://e5280f3e-0974-41fc-97e8-0d21df072c7d.mock.pstmn.io/weektime")
        fetch("https://api-dev.onlinecv.app/schedule", {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    //console.log(result);
                    // this.setState({
                    //     items: result
                    // });

                    // console.log(this.state.items.days);
                    // this.setState({
                    //     days: result.days,
                    //     isForEverEnabled: result.isForEverEnabled,

                    // },()=>{
                    //     console.log(this.state.days);
                    // })

                },

                (error) => {
                    this.setState({

                        error
                    });
                }
            )


    }


    handleReactDatetimeChange = (who, date) => {


        if (
            this.state.startDate &&
            who === "endDate" &&
            new Date(this.state.startDate._d + "") > new Date(date._d + "")
        ) {
            this.setState({
                startDate: date,
                endDate: date
            });

        } else if (
            this.state.endDate &&
            who === "startDate" &&
            new Date(this.state.endDate._d + "") < new Date(date._d + "")
        ) {
            this.setState({
                startDate: date,
                endDate: date
            });

        } else {
            this.setState({
                [who]: date
            }, () => {
                // console.log("StartDate=" + this.state.startDate);
                // console.log("EndDate=" + this.state.endDate);
                var startDate = new Date(this.state.startDate);
                var sday = startDate.getDate();
                var smonth = startDate.getMonth() + 1;
                var syear = startDate.getFullYear();
                var start = sday + "-" + smonth + "-" + syear;

                var endDate = new Date(this.state.endDate);
                var eday = endDate.getDate();
                var emonth = endDate.getMonth() + 1;
                var eyear = endDate.getFullYear();
                var end = eday + "-" + emonth + "-" + eyear;

                this.setState({
                    start: start,
                    end: end,
                }, () => {
                    // console.log("StartDate=" + this.state.start);
                    // console.log("EndDate=" + this.state.end);
                })
            });

        }



    };


    // this function adds on the day tag of the date picker
    // middle-date className which means that this day will have no border radius
    // start-date className which means that this day will only have left border radius
    // end-date className which means that this day will only have right border radius
    // this way, the selected dates will look nice and will only be rounded at the ends
    getClassNameReactDatetimeDays = date => {
        if (this.state.startDate && this.state.endDate) {
        }
        if (
            this.state.startDate &&
            this.state.endDate &&
            this.state.startDate._d + "" !== this.state.endDate._d + ""
        ) {
            if (
                new Date(this.state.endDate._d + "") > new Date(date._d + "") &&
                new Date(this.state.startDate._d + "") < new Date(date._d + "")
            ) {
                return " middle-date";
            }
            if (this.state.endDate._d + "" === date._d + "") {
                return " end-date";
            }
            if (this.state.startDate._d + "" === date._d + "") {
                return " start-date";
            }
        }
        return "";
    };



    handleRadioButton = e => {
        const { value } = e.target;

        if (value === "daterange") {
            this.setState({
                showDate: true,
                isForEverEnabled: false,
            });
        } else {
            this.setState({
                showDate: false,
                isForEverEnabled: true,
            });
        }

        this.setState({
            dateRadioValue: value,
        });



    }


    handleWeekdayChange = e => {
        const isChecked = e.target.checked;
        const target = e.target;
        const value = e.target.value;

        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(value, isChecked) }));


        if (target.checked) {
            this.state.weekday[value] = value;

        } else {
            this.state.weekday.splice(value, 1);
        }

    }

    handleSubmit = () => {
        //console.log("HANDLE SUBMIT");

        let accessToken = this.state.session.credentials.accessToken;

        let baseUrl = getBaseUrl();

        //console.log(accessToken);

        var week = this.state.weekday

        var weekday = Object.values(week);

        let authHeader = {
            'Authorization': 'Bearer ' + accessToken
        };

        //console.log(weekday);


        // fetch('https://jsonplaceholder.typicode.com/posts', {
    Promise.all([
        fetch(baseUrl + '/schedule', {
            method: 'POST',
            headers: authHeader,
            body: JSON.stringify({
                startdate: this.state.start,
                endDate: this.state.end,
                isForEverEnabled: this.state.isForEverEnabled,
                selectedDays: weekday
            }),
        })
    ]).then(responses => {
        return Promise.all(responses.map((response) => {
          if (response === 200) {
            return response.json();
          } else {
            this.setState({ isAuthenticated: false });
            return response.json();
          }
        }));
      })
        .then(data => {
         //console.log(data)
        })
        .catch(function (error) {
         console.log(error)
        });

    }

    capitalizeFirstLetter(string) {
        return string[0].toUpperCase() + string.slice(1);
    }



    render() {
        const { isLoaded } = this.state

        if (isLoaded) {
            return (
                <>
                    <MainNavbar />
                    <main className="profile" ref="main">
                        <section className="section section-hero-profile section-shaped">
                            {/* Circles background */}
                            <div className="shape shape-style-1 shape-default alpha-4">
                                <span className="span-150" />
                                <span className="span-50" />
                                <span className="span-50" />
                                <span className="span-75" />
                                <span className="span-100" />
                                <span className="span-75" />
                                <span className="span-50" />
                                <span className="span-100" />
                                <span className="span-50" />
                                <span className="span-100" />
                            </div>
                            {/* SVG separator */}
                            <div className="separator separator-bottom separator-skew">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    preserveAspectRatio="none"
                                    version="1.1"
                                    viewBox="0 0 2560 100"
                                    x="0"
                                    y="0"
                                >
                                    <polygon
                                        className="fill-white"
                                        points="2560 0 2560 100 0 100"
                                    />
                                </svg>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Spinner animation="grow" variant="primary" />
                                <Spinner animation="grow" variant="primary" />
                                <Spinner animation="grow" variant="primary" />
                            </div>
                            <span style={{ textAlign: "center", display: "grid", color: "white" }}>Checking your session...</span>

                        </section>
                    </main>
                </>
            );
        } else {

            const { dateRadioValue } = this.state


            // var daysobj = [{ value: this.capitalizeFirstLetter(days[0]+"day")}, { value: this.capitalizeFirstLetter(days[1]+"sday") }, { value: this.capitalizeFirstLetter(days[2]+"nesday") }, { value: this.capitalizeFirstLetter(days[3]+"rsday") }, { value: this.capitalizeFirstLetter(days[4]+"day")}];
            var daysobj = [{ value: "Monday" }, { value: "Tuesday" }, { value: "Wednesday" }, { value: "Thursday" }, { value: "Friday" }];
            



            return (
                <>
                    <MainNavbar />
                    {/* <main className="profile" ref="main"> */}
                    <section className="section section-hero-profile section-shaped">
                        {/* Circles background */}
                        <div className="shape shape-style-1 shape-default alpha-4">
                            <span className="span-150" />
                            <span className="span-50" />
                            <span className="span-50" />
                            <span className="span-75" />
                            <span className="span-100" />
                            <span className="span-75" />
                            <span className="span-50" />
                            <span className="span-100" />
                            <span className="span-50" />
                            <span className="span-100" />
                        </div>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="fill-white"
                                    points="2560 0 2560 100 0 100"
                                />
                            </svg>
                        </div>
                        <div className="text-center mt-5">
                            <h3>
                                Set Date & Time Availability
                        </h3>

                        </div>
                    </section>


                    <section className="section">
                        <label className="h4 text-success font-weight-bold mt-1 mb-4 pl-3" style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginTop: -50 }}>
                            <h5>Date Range</h5>
                        </label>


                        <div className="mt-0 py-1 pl-5 pr-3">
                            <Row className="justify-content-start align-items-center">
                                <Col sm={12} xs={12} md={9} lg={9}>

                                    <div className="custom-control custom-radio mb-3">
                                        <input
                                            className="custom-control-input"
                                            type="radio"
                                            value="indefinite"
                                            id="dateRangeRadio1"
                                            name="date-radio-1"
                                            checked={dateRadioValue === 'indefinite'}
                                            onChange={this.handleRadioButton}
                                        />
                                        <label className="custom-control-label" htmlFor="dateRangeRadio1">
                                            <span>Indefinitely into the future</span>

                                        </label>
                                    </div>

                                    <Row className="justify-content-start align-items-center">
                                        <Col sm={12} xs={12} md={4}>
                                            <div className="custom-control custom-radio mb-3">
                                                <input
                                                    className="custom-control-input"
                                                    type="radio"
                                                    value="daterange"
                                                    id="dateRangeRadio2"
                                                    name="date-radio-1"
                                                    checked={dateRadioValue === 'daterange'}
                                                    onChange={this.handleRadioButton}

                                                />
                                                <label className="custom-control-label" htmlFor="dateRangeRadio2">
                                                    <span>Within a date range</span>
                                                </label>
                                            </div>
                                        </Col>



                                        {!this.state.isForEverEnabled &&
                                            <Col sm={6} xs={6} md={4}>
                                                <FormGroup>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="ni ni-calendar-grid-58" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <ReactDatetime
                                                            inputProps={{
                                                                placeholder: "Start Date"
                                                            }}
                                                            value={this.state.startDate}
                                                            timeFormat={false}
                                                            dateFormat={true}
                                                            onChange={e =>
                                                                this.handleReactDatetimeChange("startDate", e)
                                                            }
                                                            // onChange={e =>
                                                            //     this.handleStartDate(e)
                                                            // }
                                                            renderDay={(props, currentDate, selectedDate) => {
                                                                let classes = props.className;
                                                                classes += this.getClassNameReactDatetimeDays(
                                                                    currentDate
                                                                );
                                                                return (
                                                                    <td {...props} className={classes}>
                                                                        {currentDate.date()}
                                                                    </td>
                                                                );
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        }
                                        {!this.state.isForEverEnabled &&
                                            <Col sm={6} xs={6} md={4}>
                                                <FormGroup>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="ni ni-calendar-grid-58" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <ReactDatetime
                                                            inputProps={{
                                                                placeholder: "End Date"
                                                            }}
                                                            className="rdtPickerOnRight"
                                                            value={this.state.endDate}
                                                            timeFormat={false}
                                                            dateFormat={true}
                                                            onChange={e =>
                                                                this.handleReactDatetimeChange("endDate", e)
                                                            }
                                                            // onChange={e =>
                                                            //     this.handleEndDate(e)
                                                            // }
                                                            renderDay={(props, currentDate, selectedDate) => {

                                                                let classes = props.className;
                                                                classes += this.getClassNameReactDatetimeDays(
                                                                    currentDate
                                                                );
                                                                return (
                                                                    <td {...props} className={classes}>
                                                                        {currentDate.date()}
                                                                    </td>
                                                                );
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        }
                                    </Row>

                                    {/* <Row>
                                    <div className="input-daterange datepicker row align-items-center">
                                        <div className="col">
                                            <div className="form-group">
                                                <div className="input-group input-group-alternative">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text"><i className="ni ni-calendar-grid-58"></i></span>
                                                    </div>
                                                    <input className="form-control" placeholder="Start date" type="text" value="06/18/2020" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <div className="input-group input-group-alternative">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text"><i className="ni ni-calendar-grid-58"></i></span>
                                                    </div>
                                                    <input className="form-control" placeholder="End date" type="text" value="06/22/2020" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Row> */}

                                </Col>
                            </Row>
                        </div>
                        <div className="mt-0 py-4 pl-3">
                            <Row className="justify-content-start align-items-center ">
                                <Col md={6} sm={12} xs={12}>
                                    <label className="h4 text-success font-weight-bold mt-1 mb-1" style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                        <h5>Time Range</h5>
                                    </label>

                                </Col>
                            </Row>
                            <Row className="justify-content-start align-items-center pl-4">
                                <Col md={6} sm={12} xs={12}>
                                    <label className="h6 font-weight-base mt-1 mb-2" style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                        Select week days
                                </label>
                                </Col>
                            </Row>

                            <div className="mt-0 py-1 pl-5">
                                <React.Fragment>
                                    {
                                        daysobj.map(item => (

                                            <Row key={item.value}>
                                                <Col md={12} sm={12} xs={12}>
                                                    <div className="custom-control custom-checkbox mb-3">
                                                        <Checkbox className={"custom-control-input"} value={item.value} id={item.value} onChange={this.handleWeekdayChange} />

                                                        <label className="custom-control-label mt-1 mb-1 d-flex flex-row justify-content-between" style={{ width: 230 }} htmlFor={item.value}>
                                                            <div className="h6 font-weight-base ">{item.value}</div>
                                                            <div className="h6 font-weight-base pl-2">9:00am - 6:00pm</div>
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>


                                        ))
                                    }
                                </React.Fragment>
                            </div>

                            <div>
                                <Row className="justify-content-start align-items-center pl-3 py-4">
                                    <Col md={6} sm={12} xs={12}>
                                        <Button
                                            className="btn-icon btn-2 ml-1"
                                            color="primary"
                                            type="button"
                                            onClick={this.handleSubmit}
                                        >
                                            <span className="btn-inner--icon">
                                                <i className="ni ni-bag-17" />
                                            Submit
                                        </span>
                                        </Button>
                                    </Col>
                                </Row>
                            </div>

                           
                        </div>
                    </section>

                    <section>

                    </section>

                    <CardsFooter />





                    {/* </main> */}
                </>
            );
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SetAvailability);


