import React from "react";

const ImgWithFallback = (props) => {
    const src = props.src;
    const fallback = props.fallback;
    const type = props.type;
    const alt = props.alt;

    return (
        <picture>
            <source srcSet={src} type={type} /> 
            <img src={fallback} alt={alt} {...props}/>
        </picture>
        );
};

export default ImgWithFallback;