import React from "react";

// Cookie consent
import CookieConsent from "react-cookie-consent";

const CVCookieConsent = () => {
    return <CookieConsent
        style={{ background: "#137AD1", textAlign: "center" }}
        buttonStyle={{ color: "#4e503b", fontSize: "16px", borderRadius: 2 }}>
        This site uses cookies. By clicking ‘I understand’ you consent to the site
  using all essential and non-essential cookies <a style={{ color: 'white', textDecorationLine: 'underline' }} href="cookie">Learn more</a>
    </CookieConsent>
};

export default CVCookieConsent;