
import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

import TextTransition, { presets } from "react-text-transition";
import Spinner from 'react-bootstrap/Spinner';

//Auth
import { Auth } from 'aws-amplify';

import ImgWithFallback from "../components/Others/ImgWithFallback";

class Hero2 extends React.Component {
  state = {
    isAuthenticated: false,
    loading: true
  }

  componentDidMount() {
    Auth.currentSession()
      .then(data => {
        this.setState({ isAuthenticated: true, loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ isAuthenticated: false, loading: false });
      });
  }

  handleGoogle = async (event) => {
    event.preventDefault();

    this.setState({ loading: true });

    try {
      Auth.federatedSignIn({ provider: "Google" }, async () => {
        const newUser = await Auth.signUp({
          username: this.state.email,
          password: this.state.password,
          attributes: {
            email: this.state.email,
            "custom:groupName": "cv-group"
          }
        });
        this.setState({
          newUser: newUser,
          loading: false
        })
      })
    } catch (e) {
      console.log(e);
      this.setState({
        isSignUpError: true,
        signUpErrorMessage: e.message,
        loading: false
      });
    }
  }

  render() {
    return (
      <>
        <div className="position-relative">
          <section className="section section-hero-landing section-shaped" style={{ height: '85px' }}>
            <div className="shape shape-style-1 shape-default" style={{ height: '85px' }}>
              <span className="span-150" />

            </div>
          </section>
          {this.state.loading &&
            <div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Spinner animation="grow" variant="primary" />
                <Spinner animation="grow" variant="primary" />
                <Spinner animation="grow" variant="primary" />
              </div>
              <span style={{ textAlign: "center", display: "grid", color: "white" }}>Loading...</span>
            </div>
          }
          <Container className="shape-container d-flex align-items-center">
            <div className="col px-0">
              <Row className="align-items-center justify-content-center">
              
                {!this.state.isAuthenticated && !this.state.loading &&
                  <Col className="text-center" lg="4" style={{ height: 250 }}>
                    <h3 className="text-center">
                      <TextTransition text={"Land in your dream job with an outstanding CV"}
                        delay={10}
                        direction={'down'}
                        springConfig={presets.wobbly}>
                      </TextTransition>
                    </h3>

                    <span style={{ fontSize: 12 }}>Signup with</span>

                    <div className="btn-wrapper mt-2">
                      <Button
                        className="btn-neutral btn-icon ml-1"
                        color="default"

                        onClick={this.handleGoogle}
                      >
                        <span className="btn-inner--icon mr-1">
                          <img
                            alt="..."
                            src={require("assets/img/icons/common/google.svg")}
                          />
                        </span>
                        <span className="btn-inner--text">Google</span>


                      </Button>
                      <span style={{ margin: 10 }}>OR</span>
                      <a style={{ textDecoration: 'underline', fontSize: 14 }} href="/signup">with your email</a>

                    </div>
                    <div className="mt-9">
                    </div>
                  </Col>
                }
                {this.state.isAuthenticated && !this.state.loading &&
                  <Col className="text-center" lg="4" style={{ height: 250 }}>
                    <h5 className="text-center">
                      <TextTransition text={"You are already in good hands! Start sharing your CV"}
                        delay={10}
                        direction={'down'}
                        springConfig={presets.wobbly}>
                      </TextTransition>
                    </h5>

                    <div className="btn-wrapper mt-3">
                      <Button
                        id="home"
                        className="btn-default btn-icon mb-3 mb-sm-0"
                        href="/home"
                        size="md"
                      >
                        <span className="btn-inner--text">Dashboard</span>
                        <span className="btn-inner--icon mr-1">
                          <i className="fa fa-rocket" />
                        </span>
                      </Button>
                    </div>
                    <div className="mt-9">
                    </div>
                  </Col>
                }
                <Col className="text-left" lg="8">
                  <ImgWithFallback
                    type="image/webp"
                    src={require("assets/img/theme/landing_hero3.webp")}
                    fallback={require("assets/img/theme/landing_hero3.png")}
                    style={{ width: '100%', objectFit: "contain" }}
                    alt="landong-hero"
                  >
                  </ImgWithFallback>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Hero2;
