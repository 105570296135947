
import React from "react";

// reactstrap components
import {
  Row,
  Button
} from "reactstrap";

// core components
import MainNavbar from "../components/Navbars/MainNavbar";

// index page sections
import Hero2 from "../components/Hero2.js";
import CardsFooter from "../components/Footers/CardsFooter";

// Cookie consent
import CVCookieConsent from "../components/Footers/Cookie-Consent";
import CVTemplateCarousel from "components/CVTemplateCarousel";
import HowItWorks from "components/HowItWorks";
import WhyUs from "components/WhyUs";

class Index extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <MainNavbar />
        <main ref="main">
          <Hero2 />
          <section className="section section-lg pt-lg-0 mt-200">
            <HowItWorks></HowItWorks>
            <Row style={{ marginBottom: 50 }}></Row>
            <CVTemplateCarousel></CVTemplateCarousel>
            <WhyUs></WhyUs>
            <Row className="justify-content-center" style={{ marginTop: 90 }}>
            <h1 className="font-weight-light text-center" style={{ marginTop: 15, paddingRight: 15 }}>
                  More questions?
                </h1>
                <Button
                  className="btn-default mt-4"
                  color="success"
                  href="/faq"
                  style={{ height: 45}}
                >
                  Checkout our FAQ
                </Button>
            </Row>
          </section>
        </main>
        <CardsFooter />
        <CVCookieConsent />
      </>
    );
  }
}

export default Index;
