import React from 'react'
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactQuill from 'react-quill';
import MultiLineText from 'components/Others/MultiLineText';
import {
    Row,
    Col,
    UncontrolledTooltip,
    InputGroupAddon,
    InputGroupText,
    InputGroup
} from "reactstrap";

import ReactDatetime from "react-datetime";

class WorkExperienceDetails extends React.Component {
    render() {
        WorkExperienceDetails.modules = {
            toolbar: [
                [],
                [{ size: [] }],
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                [{ 'align': [] }]
            ]
        };

        WorkExperienceDetails.formats = [
            'header', 'font', 'size',
            'bold', 'italic', 'underline',
            'list', 'bullet', 'indent',
            'align'
        ]

        let pWorkExpList = this.props.workExpList;

        return (<div>
            {pWorkExpList.map((val, idx) => (

                <div key={val.indexW}>
                    <form className="border-check shadow">
                        {pWorkExpList[idx].isFieldErrors &&
                            <div className="alert alert-warning text-center" role="alert">
                                <MultiLineText text={pWorkExpList[idx].errorMessage}></MultiLineText>
                            </div>
                        }
                        {/* <p>{this.props.forms1} </p> */}
                        <Box justifyContent="center">
                            <Box borderColor="grey.500"   >
                                <Row lg="12">
                                    <Col lg="6">
                                        <div style={{ display: 'flex' }}>
                                            <label htmlFor='name' className="h6">Title/Position*</label>
                                            <Button
                                                className="btn-tooltip"
                                                color="info1"
                                                id={("tooltip1" + pWorkExpList[idx].indexW).replace(".", "")}
                                                size="sm"
                                            >
                                                <div className="icon-shape rounded-circle" style={{ padding: 0 }}>
                                                    <i className="fa fa-info-circle" />
                                                </div>
                                            </Button>
                                            <UncontrolledTooltip
                                                delay={0}
                                                placement="top"
                                                target={("tooltip1" + pWorkExpList[idx].indexW).replace(".", "")}
                                                trigger="hover focus"
                                            >
                                                The official title you held while you were working at this company/organisation.
                                        </UncontrolledTooltip>
                                        </div>
                                        <input type="text"
                                            name="jobTitle"
                                            placeholder='Ex.: Sales Manager'
                                            className="form-control"
                                            onChange={(e) => { this.props.onExpDetailsChange(e.target.name, e.target.value, idx) }}
                                            value={pWorkExpList[idx].jobTitle}
                                        />
                                    </Col>

                                    <Col lg="6">
                                        <div style={{ display: 'flex' }}>
                                            <label htmlFor='name' className="h6">City/Town*</label>
                                            <Button
                                                className="btn-tooltip"
                                                color="info1"
                                                id={("tooltip2" + pWorkExpList[idx].indexW).replace(".", "")}
                                                size="sm"
                                            >
                                                <div className="icon-shape rounded-circle" style={{ padding: 0 }}>
                                                    <i className="fa fa-info-circle" />
                                                </div>
                                            </Button>
                                            <UncontrolledTooltip
                                                delay={0}
                                                placement="top"
                                                target={("tooltip2" + pWorkExpList[idx].indexW).replace(".", "")}
                                                trigger="hover focus"
                                            >
                                                The official location/city where you were positioned to complete this job.
                                        </UncontrolledTooltip>
                                        </div>
                                        <input type="text"
                                            name="city"
                                            className="form-control"
                                            placeholder='Ex.: Cambridge'
                                            onChange={(e) => { this.props.onExpDetailsChange(e.target.name, e.target.value, idx) }}
                                            value={pWorkExpList[idx].city}
                                        />
                                    </Col>
                                </Row>

                                <div className="form-control1">
                                    <div style={{ display: 'flex' }}>
                                        <label htmlFor='name' className="h6">Company*</label>
                                        <Button
                                            className="btn-tooltip"
                                            color="info1"
                                            id={("tooltip3" + pWorkExpList[idx].indexW).replace(".", "")}
                                            size="sm"
                                        >
                                            <div className="icon-shape rounded-circle" style={{ padding: 0 }}>
                                                <i className="fa fa-info-circle" />
                                            </div>
                                        </Button>
                                        <UncontrolledTooltip
                                            delay={0}
                                            placement="top"
                                            target={("tooltip3" + pWorkExpList[idx].indexW).replace(".", "")}
                                            trigger="hover focus"
                                        >
                                            The full name of the company/organisation which employed you.
                                        </UncontrolledTooltip>
                                    </div>
                                    <input type="text"
                                        name="employer"
                                        className="form-control"
                                        placeholder='Ex.: Rocket Science Software LTD.'
                                        onChange={(e) => { this.props.onExpDetailsChange(e.target.name, e.target.value, idx) }}
                                        value={pWorkExpList[idx].employer}
                                    />
                                </div>

                                <Row lg="12">
                                    <Col lg="6">
                                        <div style={{ display: 'flex' }}>
                                            <label htmlFor='name' className="h6">Start Date*</label>
                                            <Button
                                                className="btn-tooltip"
                                                color="info1"
                                                id={("tooltip4" + pWorkExpList[idx].indexW).replace(".", "")}
                                                size="sm"
                                            >
                                                <div className="icon-shape rounded-circle" style={{ padding: 0 }}>
                                                    <i className="fa fa-info-circle" />
                                                </div>
                                            </Button>
                                            <UncontrolledTooltip
                                                delay={0}
                                                placement="top"
                                                target={("tooltip4" + pWorkExpList[idx].indexW).replace(".", "")}
                                                trigger="hover focus"
                                            >
                                                The month and year when you started working for this company/organisation.
                                        </UncontrolledTooltip>
                                        </div>
                                        {/* <input
                                            name="startDate"
                                            id="startDate"
                                            type="month"
                                            onChange={(e) => { this.props.onExpDetailsChange(e.target.name, e.target.value, idx) }}
                                            value={pWorkExpList[idx].startDate}
                                        /> */}
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-calendar-grid-58" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <ReactDatetime
                                                inputProps={{
                                                    placeholder: "MMM YYYY"
                                                }}
                                                name="startDate"
                                                onChange={(e) => {
                                                    this.props.onExpDetailsChange("startDate", new Date(e._d).toLocaleString('en-us', { year: 'numeric', month: '2-digit' }).replace(/(\d+)\/(\d+)/, '$2-$1'), idx);
                                                }}
                                                value={pWorkExpList[idx].startDate}
                                                dateFormat="MMM YYYY"
                                                timeFormat={false}
                                            />
                                        </InputGroup>
                                    </Col>

                                    <Col lg="6">
                                        <div style={{ display: 'flex' }}>
                                            <label htmlFor='name' className="h6">End Date</label>
                                            <Button
                                                className="btn-tooltip"
                                                color="info1"
                                                id={("tooltip5" + pWorkExpList[idx].indexW).replace(".", "")}
                                                size="sm"
                                            >
                                                <div className="icon-shape rounded-circle" style={{ padding: 0 }}>
                                                    <i className="fa fa-info-circle" />
                                                </div>
                                            </Button>
                                            <UncontrolledTooltip
                                                delay={0}
                                                placement="top"
                                                target={("tooltip5" + pWorkExpList[idx].indexW).replace(".", "")}
                                                trigger="hover focus"
                                            >
                                                The month and year when you finished working for this company/organisation. If you're still working here, then do not set any month or year.
                                        </UncontrolledTooltip>
                                        </div>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-calendar-grid-58" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <ReactDatetime
                                                inputProps={{
                                                    placeholder: "MMM YYYY"
                                                }}
                                                name="endDate"
                                                onChange={(e) => {
                                                    this.props.onExpDetailsChange("endDate", new Date(e._d).toLocaleString('en-us', { year: 'numeric', month: '2-digit' }).replace(/(\d+)\/(\d+)/, '$2-$1'), idx);
                                                }}
                                                value={pWorkExpList[idx].endDate}
                                                dateFormat="MMM YYYY"
                                                timeFormat={false}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>

                                <div className="form-control1" style={{ marginBottom: 0 }}>
                                    <div style={{ display: 'flex' }}>
                                        <label htmlFor='name' className="h6">Description</label>
                                        <Button
                                            className="btn-tooltip"
                                            color="info1"
                                            id={("tooltip6" + pWorkExpList[idx].indexW).replace(".", "")}
                                            size="sm"
                                        >
                                            <div className="icon-shape rounded-circle" style={{ padding: 0 }}>
                                                <i className="fa fa-info-circle" />
                                            </div>
                                        </Button>
                                        <UncontrolledTooltip
                                            delay={0}
                                            placement="top"
                                            target={("tooltip6" + pWorkExpList[idx].indexW).replace(".", "")}
                                            trigger="hover focus"
                                        >
                                            The activities you were performing while at this role on a day to day basis.
                                        </UncontrolledTooltip>
                                    </div>
                                    <div>
                                        <ReactQuill style={{ background: 'white' }}
                                            id='wk-summary'
                                            className='ql-toolbar ql-container ql-editorql-blank'
                                            modules={WorkExperienceDetails.modules}
                                            formats={WorkExperienceDetails.formats}
                                            placeholder="Write here..."
                                            onChange={(newValue) => { this.props.onJobDescriptionChange(newValue, idx) }}
                                            initialValue={''}
                                            value={pWorkExpList[idx].jobDescription || ''}
                                            theme={"snow"}
                                        />
                                    </div>
                                </div>

                                <div style={{ height: '25px' }}>
                                </div>

                                <div className="actionbuttons" style={{ textAlign: "right" }}>
                                    {this.props.workExpList.length !== 0 && <Button className="mr20" color="secondary" onClick={(() => this.props.delete(val))}
                                    ><DeleteIcon /></Button>}
                                </div>
                            </Box>
                        </Box>
                    </form>
                    <div className="padding-gap">
                    </div>
                </div>

            ))}
        </div>
        )
    }
}

export default WorkExperienceDetails;
