import React from 'react'
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import DeleteIcon from '@material-ui/icons/Delete';
import MultiLineText from 'components/Others/MultiLineText';
import ReactDatetime from "react-datetime";

import {
    Row,
    Col,
    UncontrolledTooltip,
    InputGroupAddon,
    InputGroupText,
    InputGroup
} from "reactstrap";

class EducationDetails extends React.Component {
    render() {
        return (<div>
            {this.props.eduDetailsList.map((val, idx) => (
                <div key={val.indexE}>
                    <form className="border-check shadow">
                        {this.props.eduDetailsList[idx].isFieldErrors &&
                            <div className="alert alert-warning text-center" role="alert">
                                <MultiLineText text={this.props.eduDetailsList[idx].errorMessage}></MultiLineText>
                            </div>
                        }
                        <Box justifyContent="center">
                            <Box borderColor="grey.500" >
                                <Row lg="12">
                                    <Col lg="6">
                                        <div style={{ display: 'flex' }}>
                                            <label htmlFor='name' className="h6">Course/Degree/Certificate*</label>
                                            <Button
                                                className="btn-tooltip"
                                                color="info1"
                                                id={("tooltip7" + this.props.eduDetailsList[idx].indexE).replace(".", "")}
                                                size="sm"
                                            >
                                                <div className="icon-shape rounded-circle" style={{ padding: 0 }}>
                                                    <i className="fa fa-info-circle" />
                                                </div>
                                            </Button>
                                            <UncontrolledTooltip
                                                delay={0}
                                                placement="top"
                                                target={("tooltip7" + this.props.eduDetailsList[idx].indexE).replace(".", "")}
                                                trigger="hover focus"
                                            >
                                                The degree or certificate name you received.
                                        </UncontrolledTooltip>
                                        </div>
                                        <input type="text"
                                            name="course"
                                            placeholder='Ex.: Bachelor of Engineering'
                                            className="form-control"
                                            onChange={(e) => { this.props.onEduDetailsChange(e.target.name, e.target.value, idx) }}
                                            value={this.props.eduDetailsList[idx].course || ''}
                                        />
                                    </Col>

                                    <Col lg="6">
                                        <div style={{ display: 'flex' }}>
                                            <label htmlFor='name' className="h6">Location*</label>
                                            <Button
                                                className="btn-tooltip"
                                                color="info1"
                                                id={("tooltip91" + this.props.eduDetailsList[idx].indexE).replace(".", "")}
                                                size="sm"
                                            >
                                                <div className="icon-shape rounded-circle" style={{ padding: 0 }}>
                                                    <i className="fa fa-info-circle" />
                                                </div>
                                            </Button>
                                            <UncontrolledTooltip
                                                delay={0}
                                                placement="top"
                                                target={("tooltip91" + this.props.eduDetailsList[idx].indexE).replace(".", "")}
                                                trigger="hover focus"
                                            >
                                                The city/location where this Institution is located.
                                        </UncontrolledTooltip>
                                        </div>
                                        <input type="text"
                                            name="city"
                                            className="form-control"
                                            placeholder='Ex.: Cambridge'
                                            onChange={(e) => { this.props.onEduDetailsChange(e.target.name, e.target.value, idx) }}
                                            value={this.props.eduDetailsList[idx].city || ''}
                                        />
                                    </Col>
                                </Row>

                                <div className="form-control1">
                                    <div style={{ display: 'flex' }}>
                                        <label htmlFor='name' className="h6">Institution/Centre*</label>
                                        <Button
                                            className="btn-tooltip"
                                            color="info1"
                                            id={("tooltip8" + this.props.eduDetailsList[idx].indexE).replace(".", "")}
                                            size="sm"
                                        >
                                            <div className="icon-shape rounded-circle" style={{ padding: 0 }}>
                                                <i className="fa fa-info-circle" />
                                            </div>
                                        </Button>
                                        <UncontrolledTooltip
                                            delay={0}
                                            placement="top"
                                            target={("tooltip8" + this.props.eduDetailsList[idx].indexE).replace(".", "")}
                                            trigger="hover focus"
                                        >
                                            The Institution or Centre where you attended this course.
                                        </UncontrolledTooltip>
                                    </div>
                                    <input type="text"
                                        name="university"
                                        className="form-control"
                                        placeholder='Ex.: University of Oxford.'
                                        onChange={(e) => { this.props.onEduDetailsChange(e.target.name, e.target.value, idx) }}
                                        value={this.props.eduDetailsList[idx].university || ''}
                                    />
                                </div>

                                <Row lg="12">
                                    <Col lg="6">
                                        <div style={{ display: 'flex' }}>
                                            <label htmlFor='name' className="h6">Start Date*</label>
                                            <Button
                                                className="btn-tooltip"
                                                color="info1"
                                                id={("tooltip9" + this.props.eduDetailsList[idx].indexE).replace(".", "")}
                                                size="sm"
                                            >
                                                <div className="icon-shape rounded-circle" style={{ padding: 0 }}>
                                                    <i className="fa fa-info-circle" />
                                                </div>
                                            </Button>
                                            <UncontrolledTooltip
                                                delay={0}
                                                placement="top"
                                                target={("tooltip9" + this.props.eduDetailsList[idx].indexE).replace(".", "")}
                                                trigger="hover focus"
                                            >
                                                The month and the year from when you started attending this course.
                                        </UncontrolledTooltip>
                                        </div>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-calendar-grid-58" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <ReactDatetime
                                                inputProps={{
                                                    placeholder: "MMM YYYY"
                                                }}
                                                name="startDate"
                                                onChange={(e) => {
                                                    this.props.onEduDetailsChange("startDate", new Date(e._d).toLocaleString('en-us', { year: 'numeric', month: '2-digit' }).replace(/(\d+)\/(\d+)/, '$2-$1'), idx);
                                                }}
                                                value={this.props.eduDetailsList[idx].startDate}
                                                dateFormat="MMM YYYY"
                                                timeFormat={false}
                                            />
                                        </InputGroup>
                                    </Col>

                                    <Col lg="6">
                                        <div style={{ display: 'flex' }}>
                                            <label htmlFor='name' className="h6">End Date</label>
                                            <Button
                                                className="btn-tooltip"
                                                color="info1"
                                                id={("tooltip10" + this.props.eduDetailsList[idx].indexE).replace(".", "")}
                                                size="sm"
                                            >
                                                <div className="icon-shape rounded-circle" style={{ padding: 0 }}>
                                                    <i className="fa fa-info-circle" />
                                                </div>
                                            </Button>
                                            <UncontrolledTooltip
                                                delay={0}
                                                placement="top"
                                                target={("tooltip10" + this.props.eduDetailsList[idx].indexE).replace(".", "")}
                                                trigger="hover focus"
                                            >
                                                The month and the year when you finished this course. If you're still attending this course, do not set month and year.
                                        </UncontrolledTooltip>
                                        </div>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-calendar-grid-58" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <ReactDatetime
                                                inputProps={{
                                                    placeholder: "MMM YYYY"
                                                }}
                                                name="endDate"
                                                onChange={(e) => {
                                                    this.props.onEduDetailsChange("endDate", new Date(e._d).toLocaleString('en-us', { year: 'numeric', month: '2-digit' }).replace(/(\d+)\/(\d+)/, '$2-$1'), idx);
                                                }}
                                                value={this.props.eduDetailsList[idx].endDate}
                                                dateFormat="MMM YYYY"
                                                timeFormat={false}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <div style={{ height: '25px' }}>
                                </div>
                                <div className="actionbuttons" style={{ textAlign: "right" }}>
                                    {this.props.eduDetailsList.length !== 1 && <Button className="mr20" color="secondary" onClick={(() => this.props.delete(val))}
                                    ><DeleteIcon /></Button>}
                                </div>

                            </Box>
                        </Box>
                    </form>
                    <div className="padding-gap">
                    </div>
                </div>
            ))}
        </div>
        )
    }
}

export default EducationDetails;
