
import React from "react";
import { Redirect } from 'react-router-dom';

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";

// core components
import MainNavbar from "components/Navbars/MainNavbar.js";
import CVCookieConsent from "../../../components/Footers/Cookie-Consent";

import { Auth } from 'aws-amplify';
import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';
import { initSession } from '../../../actions/session';
import CardsFooter from "../../../components/Footers/CardsFooter";

import { getBaseUrl } from "functions/util";

import { Chart, LineAdvance } from 'bizcharts';

const mapStateToProps = state => {
  return { session: state.session }
}

function mapDispatchToProps(dispatch) {
  return {
    initSession: session => dispatch(initSession(session))
  }
}

class Home extends React.Component {
  state = {
    loading: true,
    isPublic: false,
    firstName: '',
    cvUrl: '',
    qrCode: '',
    isCopySuccess: false,
    isCopyFailed: false,
    isUserRegistrationCompleted: false,
    statistics: []
  }

  async componentDidMount() {
    document.title = "Home";
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    if (this.props.session.isLoggedIn) {
      this.setState({ isAuthenticated: true });
      this.fetchCVData(this.props.session.credentials.accessToken);
    } else {
      await Auth.currentAuthenticatedUser()
        .then(user => {
          document.tidioIdentify = {
						email: user.signInUserSession.idToken.payload.email
					};
          const signInSession = user.signInUserSession;

          const session = {
            credentials: {
              accessToken: signInSession.accessToken.jwtToken,
              idToken: signInSession.idToken.jwtToken,
              refreshToken: signInSession.refreshToken.token
            },
            user: {
              userName: signInSession.idToken.payload['cognito:username'],
              email: signInSession.idToken.payload.email
            }
          }

          this.props.initSession(session);

          this.setState({ isAuthenticated: true });

          this.fetchCVData(signInSession.accessToken.jwtToken);

          return user;
        })
        .catch(ex => {
          this.setState({ isAuthenticated: false }, () => {
            this.setState({ loading: false });
          });
        });
    }

  }

  fetchCVData = (accessToken) => {
    let baseUrl = getBaseUrl();

    Promise.all([
      fetch(baseUrl + '/cv', {
        headers: {
          'Authorization': 'Bearer ' + accessToken
        }
      }),
      fetch(baseUrl + '/signup', {
        headers: {
          'Authorization': 'Bearer ' + accessToken
        }
      }),
      fetch(baseUrl + '/statistics', {
        headers: {
          'Authorization': 'Bearer ' + accessToken
        }
      })
    ]).then(responses => {
      return Promise.all(responses.map((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          this.setState({ isAuthenticated: false });
          return response.json();
        }

      }));
    })
      .then(data => {
        //console.log(data);

        var customer = data[0];
        var stage = data[1];
        var statistics = data[2];

        document.tidioIdentify = {
          name: customer.firstName
        };

        this.setState({
          firstName: customer.firstName,
          cvUrl: customer.url,
          qrCode: customer.qrCode,
          isPublic: customer.isPublic,
          isUserRegistrationCompleted: stage.stage > 2,
          statistics: statistics,
          loading: false
        });
      })
      .catch(function (error) {
        this.setState({
          loading: false
        });
      });
  }

  render() {
    const { loading, isAuthenticated, isUserRegistrationCompleted } = this.state;

    if (loading) {
      return (
        <>
          <MainNavbar />
          <main className="profile" ref="main">
            <section className="section section-hero-profile section-shaped">
              {/* Circles background */}
              <div className="shape shape-style-1 shape-default alpha-4">
                <span className="span-150" />
                <span className="span-50" />
                <span className="span-50" />
                <span className="span-75" />
                <span className="span-100" />
                <span className="span-75" />
                <span className="span-50" />
                <span className="span-100" />
                <span className="span-50" />
                <span className="span-100" />
              </div>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Spinner animation="grow" variant="primary" />
                <Spinner animation="grow" variant="primary" />
                <Spinner animation="grow" variant="primary" />
              </div>
              <span style={{ textAlign: "center", display: "grid", color: "white" }}>Checking your session...</span>

            </section>
          </main>
        </>
      );
    } else {
      if (isAuthenticated) {
        if (isUserRegistrationCompleted) {
          return (
            <>
              <MainNavbar />
              <main className="profile" ref="main">
                <section className="section section-hero-profile section-shaped">
                  {/* Circles background */}
                  <div className="shape shape-style-1 shape-default alpha-4">
                    <span className="span-150" />
                    <span className="span-50" />
                    <span className="span-50" />
                    <span className="span-75" />
                    <span className="span-100" />
                    <span className="span-75" />
                    <span className="span-50" />
                    <span className="span-100" />
                    <span className="span-50" />
                    <span className="span-100" />
                  </div>
                  {/* SVG separator */}
                  <div className="separator separator-bottom separator-skew">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      preserveAspectRatio="none"
                      version="1.1"
                      viewBox="0 0 2560 100"
                      x="0"
                      y="0"
                    >
                      <polygon
                        className="fill-white"
                        points="2560 0 2560 100 0 100"
                      />
                    </svg>
                  </div>
                  <div className="text-center mt-5">
                    <h3>
                      Welcome {this.state.firstName}
                    </h3>

                  </div>
                </section>

                {loading ?
                  <div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Spinner animation="grow" variant="primary" />
                      <Spinner animation="grow" variant="primary" />
                      <Spinner animation="grow" variant="primary" />
                    </div>
                    <span style={{ textAlign: "center", display: "grid", color: "white" }}>Getting your CV details...</span>
                  </div>
                  :
                  <section className="section">
                    <Container>
                      <div className="px-0">
                        <div className="mt-0 py-5 text-center">
                          <Row className="justify-content-center">
                            <Col lg="6">
                            <div style={{marginBottom: 20}}>Number of people who viewed your CV</div>
                              <Chart padding={[10, 50, 50, 50]} autoFit height={300} data={this.state.statistics}>
                                <LineAdvance
                                  shape="smooth"
                                  point
                                  area
                                  position="sdate*visits"
                                  color="visits"
                                />
                              </Chart>
                            </Col>
                            {this.state.isPublic &&
                              <Col lg="6" style={{ marginTop: 50 }}>
                                {this.state.isCopySuccess &&
                                  <div className="alert alert-success" role="alert">
                                    Copied your cv url into clipboard!
                                </div>
                                }
                                {this.state.isCopyFailed &&
                                  <div className="alert alert-danger" role="alert">
                                    Cannot copy url, please select &amp; copy manually
                                  <div className="mt-1">
                                      <a href={this.state.cvUrl}>{this.state.cvUrl}</a>
                                    </div>
                                  </div>
                                }

                                <div>Your CV QR Code</div>
                                <img style={{ height: 120 }} src={this.state.qrCode} alt="cv qr code"></img>



                                <div style={{ marginTop: 25, display: 'flex', justifyContent: 'center' }}>
                                  <div className="text-center text-blue mb-2" style={{ width: 250, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    <small >{this.state.cvUrl}</small>
                                  </div>

                                  <Button
                                    id="home"
                                    className="btn-default btn-icon mb-3 mb-sm-0"
                                    href={this.state.cvUrl}
                                    target="_blank"
                                    size="sm"
                                  >
                                    <i className="fa fa-external-link" aria-hidden="true"></i>
                                  </Button>

                                  <Button
                                    id="home"
                                    className="btn-default btn-icon mb-3 mb-sm-0"
                                    size="sm"
                                    onClick={() => {
                                      navigator.clipboard.writeText(this.state.cvUrl).then(() => {
  
                                        this.setState({ isCopySuccess: true });
  
                                        setTimeout(() => { this.setState({ isCopySuccess: false }); }, 3000);
                                      }, (err) => {
                                        console.error('Async: Could not copy text: ', err);
                                        this.setState({ isCopyFailed: true });
  
                                        setTimeout(() => { this.setState({ isCopyFailed: false }); }, 5000);
                                      });
                                    }}
                                  >
                                    <i className="fa fa-clipboard" aria-hidden="true"></i>
                                  </Button>

                                  <Button
                                    id="home"
                                    className="btn-default btn-icon mb-3 mb-sm-0"
                                    size="sm"
                                    href={ this.state.cvUrl + ".pdf" }
                                    target="_blank"
                                  >
                                    <i class="fa fa-cloud-download" aria-hidden="true"></i>
                                  </Button>

                                  {/* <Button className="btn-default btn-icon mb-3 mb-sm-0" onClick={() => {
                                    navigator.clipboard.writeText(this.state.cvUrl).then(() => {

                                      this.setState({ isCopySuccess: true });

                                      setTimeout(() => { this.setState({ isCopySuccess: false }); }, 3000);
                                    }, (err) => {
                                      console.error('Async: Could not copy text: ', err);
                                      this.setState({ isCopyFailed: true });

                                      setTimeout(() => { this.setState({ isCopyFailed: false }); }, 5000);
                                    });
                                  }}><i className="fa fa-clipboard" aria-hidden="true"></i></Button> */}

                                </div>

                              </Col>
                            }
                            {!this.state.isPublic &&
                              <Col lg="6">
                                Your CV is not public. <br /><br />
                              Please visit Settings and make it public.
                            </Col>
                            }
                          </Row>
                        </div>
                      </div>
                    </Container>
                  </section>
                }

              </main>
              <CVCookieConsent />
              <CardsFooter />
            </>
          );
        } else {
          return (
            <Redirect
              to="/register"
            />
          )
        }
      } else {
        return (
          <Redirect
            to={{
              pathname: "/signin",
              state: {isAuthenticated: false}
            }}
          />
        )
      }

    }

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
