
import React from "react";

// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import MainNavbar from "../../components/Navbars/MainNavbar";

// Cookie consent
import CVCookieConsent from "../../components/Footers/Cookie-Consent";

class Faq extends React.Component {
    componentDidMount() {
        document.title = "Cookie";
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }
    render() {
        return (
            <>
                <MainNavbar />
                <main className="profile" ref="main">
                    <section className="section section-hero-profile section-shaped" style={{ overflow: "hidden" }}>
                        {/* Circles background */}
                        <div className="shape shape-style-1 shape-default alpha-4">
                            <span className="span-150" />
                            <span className="span-50" />
                            <span className="span-50" />
                            <span className="span-75" />
                            <span className="span-100" />
                            <span className="span-75" />
                            <span className="span-50" />
                            <span className="span-100" />
                            <span className="span-50" />
                            <span className="span-100" />
                        </div>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="fill-white"
                                    points="2560 0 2560 100 0 100"
                                />
                            </svg>
                        </div>
                        <div className="text-center mt-5">
                            <h1 style={{ textAlign: 'center' }}>Frequently asked questions</h1>

                        </div>
                    </section>
                    <section className="section">
                        <Container>
                            <Row className="align-items-center" sm="12">
                                <Col sm="12">
                                    <p className="lead text-success">
                                    How can I share my CV?
                                        </p>
                                </Col>
                            </Row>
                            <Row className="align-items-center" sm="12">
                                <Col sm="12">
                                    <p>
                                    You can share the QR code which you will find in the Dashboard or the URL which 
                                    you can get it by using the "COPY MY CV URL" button in the Dashboard.
                                    <br/>
                                    Or You can download the CV in PDF format and share as a file.
                                        </p>
                                </Col>
                            </Row>

                            <Row className="align-items-center" sm="12">
                                <Col sm="12">
                                    <p className="lead text-success">
                                        Does it cost money to use OnlineCV.app?
                                        </p>
                                </Col>
                            </Row>
                            <Row className="align-items-center" sm="12">
                                <Col sm="12">
                                    <p>
                                    It is FREE to create and host CV in our platform.
                                        </p>
                                </Col>
                            </Row>

                            <Row className="align-items-center" sm="12">
                                <Col sm="12">
                                    <p className="lead text-success">
                                    Can I get a CV template from OnlineCV?
                                        </p>
                                </Col>
                            </Row>
                            <Row className="align-items-center" sm="12">
                                <Col sm="12">
                                    <p>
                                    No. We do not provide a CV template.<br />
                                    But, You can build your CV using one of our templates and we will host it in our site.
                                        </p>
                                </Col>
                            </Row>

                            <Row className="align-items-center" sm="12">
                                <Col sm="12">
                                    <p className="lead text-success">
                                    Can I add a photo to my CV?
                                        </p>
                                </Col>
                            </Row>
                            <Row className="align-items-center" sm="12">
                                <Col sm="12">
                                    <p>
                                    No. We do not encourage adding a photo into your CV as its uncommon in many countries.
                                        </p>
                                </Col>
                            </Row>

                            <Row className="align-items-center" sm="12">
                                <Col sm="12">
                                    <p className="lead text-success">
                                    Is my CV available to download in Word or PDF?
                                        </p>
                                </Col>
                            </Row>
                            <Row className="align-items-center" sm="12">
                                <Col sm="12">
                                    <p>
                                        You can download your CV in PDF format only.<br/>
                                    But, OnlineCV is more than just a CV creator. We are a platform where recruiter 
                                    and employers can connect with potential candidates. So, the longer you stay 
                                    within our platform we can help you to get hired.
                                        </p>
                                </Col>
                            </Row>



                            <Row className="align-items-center" sm="12">
                                <Col sm="12">
                                    <p className="lead text-success">
                                        Can I edit my CV?
                                        </p>
                                </Col>
                            </Row>
                            <Row className="align-items-center" sm="12">
                                <Col sm="12">
                                    <p>
                                    Once after you logged in, head to the navigation menu and click 'Profile' 
                                    which will let you edit all the sections of your CV.
                                        </p>
                                </Col>
                            </Row>

                            <Row className="align-items-center" sm="12">
                                <Col sm="12">
                                    <p className="lead text-success">
                                    How can I change my CV template?
                                        </p>
                                </Col>
                            </Row>
                            <Row className="align-items-center" sm="12">
                                <Col sm="12">
                                    <p>
                                    Once after you logged in, head to the 
                                    navigation menu and click 'Profile' which will let you edit all 
                                    the sections of your CV including the template.
                                        </p>
                                </Col>
                            </Row>

                            <Row className="align-items-center" sm="12">
                                <Col sm="12">
                                    <p className="lead text-success">
                                    Is my CV visible to all by default?
                                        </p>
                                </Col>
                            </Row>
                            <Row className="align-items-center" sm="12">
                                <Col sm="12">
                                    <p>
                                    Yes. Anyone who has your CV URL can access your CV.
                                        </p>
                                </Col>
                            </Row>

                            <Row className="align-items-center" sm="12">
                                <Col sm="12">
                                    <p className="lead text-success">
                                    How can I hide my CV from the public?
                                        </p>
                                </Col>
                            </Row>
                            <Row className="align-items-center" sm="12">
                                <Col sm="12">
                                    <p>
                                    Once after you logged in, head to the navigation 
                                    menu and click 'Settings' which will let you hide the CV from the public.
                                        </p>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                </main>
                <CVCookieConsent />
            </>
        );
    }
}

export default Faq;
